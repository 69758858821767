// Essentials
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
//import axios from 'axios'; // connect to api
import { Link } from 'react-router-dom';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import {
    MDBCard
} from 'mdb-react-ui-kit';

const PurchaseHostGameFinish = ({
    ...props
}) => {

    // Styles
    const outerClasses = classNames(
        'section'
    );

    const useStyles = makeStyles(() => ({
        root: {
            "& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
            marginBottom: '70px'
        },
        divider: {
            background: 'white'
        },
    }));

    const btnStyle = {
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: "10px",
        width: '25%',
        height: '35px',
    };

    const theme = createTheme({
        palette: {
            success: {
                main: '#f6c94e',
                contrastText: '#fff',
            },
            secondary: {
                main: '#fff',
        contrastText: '#000000',
            },
        },
    });

    const [hostGame, setHostGame] = useState([]);
    const [hostGameExist, setHostGameExist] = useState('');
    useEffect(() => {
        if (sessionStorage.getItem('hostGame')) {
            setHostGame(JSON.parse(sessionStorage.getItem('hostGame')));
            sessionStorage.removeItem("hostGame");
        }
        else {
            setHostGameExist('none');
        }
    }, []);

    return (
        <section
            {...props}
            className={classNames(outerClasses, '')}>
            <div className='container-sm'> 
                {hostGameExist == 'none' ?
                    <p className='center-content' style={{ marginTop: '80px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
                        No booking is made.
                    </p>
                    :
                    <><h1 className='pb-32 center-content'>{hostGame.gameName}</h1>
                        <p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
                            Booking has successfully submitted. <br />We will contact you soon.
                        </p><br /></>
                }
                <ThemeProvider theme={theme}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                        <Button
                            sx={btnStyle}
                            type='submit'
                            style={{ marginBottom: '15px' }}
                            variant="contained"
                            color='success'
                            size='small'
                            Link component={Link} to={`/`}
                        >
                            Back to Home
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                    </Box>
                </ThemeProvider>
            </div>
        </section >
    );

}

export default PurchaseHostGameFinish;
