
// material-ui
import { Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';
import classNames from 'classnames';
import axios from 'axios';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
// project import
import AnimateButton from 'src/components/elements/AnimateButton';
import React, { useState } from "react";

import { toast } from 'react-toastify';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material';

const url = process.env.REACT_APP_API_URL;

// ============================|| FIREBASE - REGISTER ||============================ //

const theme = createTheme({
  palette: {
    success: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#000',
    },
  },
});


const AuthForgotPassword = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const [isActive, setIsActive] = useState(true);
  const [success, setSuccess] = useState(false);

  const history = useHistory();
  return (
    <section
      {...props}
      className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses} style={{ height: "90vh" }}>
          <div className="hero-content">
            <h1 className="mt-5 mb-16 reveal-from-left" data-reveal-delay="200">
              Forgot your password?
            </h1>
            <div className="auth-wrapper" style={{ marginTop: "5px", paddingTop: "20px" }}>
              <div className="auth-background-holder"></div>
              <div className="auth-background-mask"></div>

              <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                <div className="d-flex flex-column align-content-end">
                  {success ? (
                    <>
                      <div className="center-content" style={{ marginTop: '-10px', textAlign: 'center' }}>
                        <p>Password reset link is being sent to your email! <br></br> It might take a few minutes.</p>
                      </div>
                      <div className="text-center">
                        <button style={{ color: 'black', backgroundColor: 'white' }}
                          className="button button-primary w-100 theme-btn mx-auto"
                        >
                          <Link to='/login'>Back to Login</Link>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="auth-p mx-auto">
                        <p>We'll email you instructions on how to reset it.</p>
                      </div>
                      <div className="auth-body mx-auto">
                        <div className="auth-form-container text-start">
                          <Formik
                            initialValues={{
                              email: '',
                              submit: null
                            }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                              try {
                                setStatus({ success: false });
                                setSubmitting(false);
                                axios.patch(`${url}/user/sendForgetPasswordVerificationEmail`, //changed2
                                  {
                                    EmailToReset: values.email
                                  }
                                ).then(response => {
                                  console.log(`Response - ${response.status} - ${response.data}`)

                                  response.status === 200 ? console.log("Password reset email sent. If you do not see the email in a few minutes, check your Junk folder or Spam folder.") : console.log("Password reset email failed to send!")

                                  if (response.status === 200) {
                                    //const dataToSend = { email: values.email  };
                                    //const queryString = Object.keys(dataToSend).map(key => `${key}=${dataToSend[key]}`).join("&");
                                    //res.redirect(`/otpverification?${queryString}`);
                                    //console.log(`email ${values.email}`)

                                    const indicator1 = JSON.stringify(response.data);
                                    const indicator2 = indicator1.substring(indicator1.indexOf('first3":"') + 9, indicator1.indexOf('first3":"') + 12);
                                    history.push('/otpverification', { state: { email: values.email, indicator3: indicator2 } });
                                    setSuccess(true);
                                    toast.success("Password reset email sent", {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                  }
                                }).catch((error) => {
                                  if (error.response) {
                                    console.log(error.response.data);
                                    console.log(error.response.status);
                                    console.log(error.response.headers);
                                    if (error.response.status === 400) {
                                      toast.error(`Email does not exist! Please enter a registered email`, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 4000,
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                        style: {
                                          fontSize: '13px',
                                          height: '85px',
                                          width: '100%',
                                          display: 'flex',
                                        },
                                        zIndex: 9999
                                      });
                                    }
                                    if (error.response.status === 500) {
                                      toast.error(`An Exception have occurred, please try again later.`, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 4000,
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                        style: {
                                          fontSize: '13px',
                                          height: '85px',
                                          width: '100%',
                                          display: 'flex',
                                        },
                                        zIndex: 9999
                                      });
                                    }
                                  } else {
                                    // Something happened in setting up the request that triggered an Error
                                    toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                      // style: { 
                                      // fontSize: '13px',
                                      // height: '85px',
                                      // width: '100%',
                                      // display: 'flex',
                                      // },      
                                      // zIndex: 9999 
                                    });
                                    // console.log('Error', error.message);
                                  }
                                });

                              } catch (err) {
                                console.error(err);
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                              }
                            }}
                          >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                              <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Stack spacing={1}>
                                      <div className="input-group">
                                        <label for="email-signup" style={{ display: 'block', fontSize: '100%' }}>
                                          Email
                                          <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                          fullWidth
                                          error={Boolean(touched.email && errors.email)}
                                          id="email-login"
                                          type="email"
                                          value={values.email}
                                          name="email"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="demo@company.com"
                                          className='form-control'
                                          style={{ width: '100%', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                          inputProps={{}}
                                        />
                                      </div>
                                      {touched.email && errors.email && (
                                        <FormHelperText error id="helper-text-email-signup">
                                          {errors.email}
                                        </FormHelperText>
                                      )}
                                    </Stack>
                                  </Grid>
                                  {errors.submit && (
                                    <Grid item xs={12}>
                                      <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Grid>
                                  )}


                                  <Grid item xs={12}>
                                    <div className='Fgtbtnstyle'>
                                      <ThemeProvider theme={theme}>
                                        <AnimateButton>
                                          <Button createTheme="fff" style={{ color: "black", backgroundColor: "white", width: '100%' }}
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                          >
                                            Send Email
                                          </Button>
                                        </AnimateButton>
                                      </ThemeProvider>
                                    </div>
                                  </Grid>
                                </Grid>
                              </form>
                            )}
                          </Formik>
                          <div className="auth-option text-center pt-2" style={{ marginTop: '10px' }}>
                            <Link className="text-link" to="/login">
                              Back to Login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthForgotPassword;
