import React from "react";

import moment from 'moment'; // format datetime
import Card from "react-bootstrap/Card";
import 'mdb-react-ui-kit/dist/css/mdb.min.css' // https://mdbootstrap.com/docs/react/

function BasicCard({ item }) {
  return (
    <Card
      style={{
        width: 300,
        height: 400,
        borderRadius: '10px',
        backgroundColor: "white",
        color: "black",
      }}
    >
      {/* <Card.Img variant="top" src={item?.image} /> */}
      <Card.Body>
      <div className="tiles-item-inner">
                        <div className="testimonial-item-content">
                          <p className="text-sm mb-0">
                            — {item.comment}
                          </p>
                        </div>
                        <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider avatar">
                          <div className='avatar-details'>
                           {/* <span className="testimonial-item-name text-color-high" style={{
        color: "black",
      }}>{item.username}</span>
    <span className="text-color-low"> - </span>*/}
                            <span className="testimonial-item-link" >{moment(item.date).format('ll')}</span>
                          </div>
                        </div>
                      </div>
      </Card.Body>
      
    </Card>
    
  );
}

export default BasicCard;