// Essentials
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios'; // connect to api
import queryString from 'query-string'; // parse query string
// Form and validations
import { Formik, Form } from "formik";
import * as Yup from 'yup';

// Display http message
import { toast } from 'react-toastify';
//import ReactifyToast from 'reactify-toast';
import 'react-toastify/dist/ReactToastify.min.css';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';
import { selectCurrentUser } from '../store/user/user.selector';

//UI path
import "../../Purchase.css"

const url = process.env.REACT_APP_API_URL;

const Purchase = ({ ...properties }) => {
	const { staticContext, ...props } = properties;
	const root = this;
	// Styles
	const outerClasses = classNames('section');
	// override the light theme that the default steplabel was using
	const LightStepLabel = styled(StepLabel)(() => ({
		[`& .${stepLabelClasses.label}`]: {
			[`&.${stepLabelClasses.completed}`]: {
				color: 'rgba(255, 255, 255, 1)',
			},
			[`&.${stepLabelClasses.active}`]: {
				color: 'rgba(255, 255, 255, 1)',
			},
			color: 'rgba(255, 255, 255, 0.3)',
		},
	}));

	const classes = makeStyles(() => ({
		root: {
			"& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
			marginBottom: '70px'
		},
		divider: {
			background: 'white'
		},
	}))();

	const theme = createTheme({
		palette: {
			success: {
				main: '#fff',
				contrastText: '#000',
			},
			secondary: {
				main: '#fff',
				contrastText: '#000',
			},
			yellow: {
				main: '#f6c94e',
				contrastText: '#000',
			
			},
			red: {
				main: '#ad2518',
				contrastText: '#fff',
			},
		},
	});

	const btnStyle = {
		fontWeight: 'bold',
		fontSize: 14,
		borderRadius: "10px",
		width: '25%',
		height: '35px',
	};

	const styles = {
		applyBtn: {
			width: '70px',
			height: '37px',
			fontSize:'15px',
		  '@media (max-width: 600px)': {
			width: '1px',
			height: '20px',
			fontSize:'10px',
			marginLeft:'-8px'
		  },
		},
		removeBtn: {
			width: '80px',
			height: '37px',
			fontSize:'15px',
		  '@media (max-width: 600px)': {
			width: '1px',
			height: '20px',
			fontSize:'10px',
			marginLeft:'-8px'
		  },
		},
		div: {
			display: 'flex', 
			flexDirection: 'row',
			'@media (max-width: 600px)': {
				flexDirection: 'column',
			},
		},
		
	  };

	// yup validation for the form
	const validationSchema = [ // validation in an array, currentValidationSchema[0] refers to validation on the first page and so on
		// validation on the first page (step 0 in programming terms)
		Yup.object().shape({
			quantity: Yup.string().required('Please select a quantity'),
			newQuantity: Yup.number().when('quantity', {
				is: (quantity) => quantity == 'Option length',
				then: Yup.number().required('Please specify an amount')
					.min(10, 'Please select a quantity above 10')
			})
		}),
		// validation on the second page (step 1 in programming terms)
		Yup.object().shape({
			dateTime: Yup.date().required('Please select a date'),
		})
	];


	// states for game data called by getGamesData (used in fcompute_price_total function)
	// different steps used in stepper component (form)
	const steps = ['Select quantity', 'Select date and time', 'Review'];

	const [meta, setMeta] = useState({}); // state for meta data
	// states for game data called by getGamesData (used in fcompute_price_total function)
	const [game, setGame] = useState({});
	// Validate promo code entered
	const [promoCode, setUsePromo] = useState(''); // state to store the promo code entered by users
	const [promoFeedback, setPromoFeedback] = useState(''); // state to store feedback on the promo code entered
	// Form values used outside formik states
	const [netTotal, setNetTotal] = useState();
	const [netTotalbeforeGST, setNetTotalbeforeGST] = useState();

	const [grossTotal, setGrossTotal] = useState(0);
	const [discount, setDiscAmt] = useState('');
	const [gst, setGST] = useState();
	const [GSTDEFAULT, setGSTDEFAULT] = useState();
	const [submitErr, setsubmitErr] = useState(true);

	// state for stepper component - i.e activeStep = 0 means the stepper is at the first step or step 0 in programming terms
	const [activeStep, setActiveStep] = useState(0);
	const isLastStep = function () { return activeStep === (steps.length - 1); }

	const Location = useLocation();
	const params = useParams();
	const queries = queryString.parse(Location.search);
	const session_id = queries.session_id;
	const auth = useSelector(selectCurrentUser);
	const history = useHistory();

	//loading screen
	const [isLoading, setIsLoading] = useState(false);

	//const roomId     = params.id;
	const [gameId, setgameID] = useState(JSON.parse(sessionStorage.getItem('selectedGame')));

	async function api_game_data_retrieve() {
		const response = await axios.post(`${url}/games/gameData`, { gameID: gameId.gameId });
		//console.log("API: Game data => ", response.data);
		const [data] = response.data.data.gameData;
		// console.log(data[0])
		// setGame(data[0]);
		setGame(data);
		setGSTDEFAULT(response.data.data.GST);
		//localStorage.setItem('gameID', gameId.gameId);

	}

	// remove promo code
	async function api_promo_removal() {
		setDiscAmt('')
		setUsePromo('')
		setPromoFeedback('Promo removed')
	}

	// function called when user enter and apply the promo code
	async function api_promo_validate() {
		try {
			const response = await axios.post(`${url}/games/validatePromo`, {
				promoCode: promoCode,
				grossTotal: grossTotal,
				gameID: gameId.gameId
			});

			if (response.status == 200) {
				setPromoFeedback(response.data.data.response)

				// if (response.data.data.response != 'Promo code applied') {
				// 	setDiscAmt('');
				// }
				if (response.data.data.discType == 'P') {
				  let disc = (netTotalbeforeGST * (response.data.data.discAmt / 100)).toFixed(2)
				  setDiscAmt(disc)
				  let gross = netTotalbeforeGST - disc
				  let gst = (gross * (GSTDEFAULT/100)).toFixed(2)
				  setGST(gst)
				  let final = (parseFloat(gross) + parseFloat(gst)).toFixed(2)
				  setNetTotal(final)
				} else if (response.data.data.discType == 'F') {
					let disc = response.data.data.discAmt
					setDiscAmt(disc)
					let gross = netTotalbeforeGST - disc
					let gst = (gross * (GSTDEFAULT / 100)).toFixed(2)
					setGST(gst)
					let final = (parseFloat(gross) + parseFloat(gst)).toFixed(2)
					setNetTotal(final)
					console.log(final)
				} else if (response.data.data.discType == '') {
					setDiscAmt('0')
					let gst = (netTotalbeforeGST * (GSTDEFAULT / 100)).toFixed(2)
					setGST(gst)
					let final = (parseFloat(netTotalbeforeGST) + parseFloat(gst)).toFixed(2)
					setNetTotal(final)
				}
			}
		} catch (error) {
			console.error('error', error);
			console.log('error.response', error.response)
			console.error("Failed to validate promo code");
			if (error.response) {
				if (error.response.status == 409) {
					setPromoFeedback(error.response.data.data)
					setUsePromo('')
					setDiscAmt('')
				}
			}
			else {
				setPromoFeedback('Error. Please contact administrator.')
				setUsePromo('')
			}
		}
	};

	async function api_payment_generate(data) {
		console.log("Payment: Generating payment session..."); 
		// console.log('game.decimalPlc',game.decimalPlc)
		try {
			const response = await axios.post(`${url}/payment/checkout`, {
				meta: {
					booking: {
						//userID    : auth.email,
						userID: auth.userID,
						gameID: gameId.gameId,
						datetime: data.dateTime,
						quantity: data.quantity,
						netTotal: data.netTotal,
						grossTotal: data.grossTotal,
						promoCode: data.promoCode,
						email: auth.email
					}
				},
				gameName: game.gameName,
				gameDesc: game.gameDesc,
				gameURL: game.gameURL,
				currency: game.currency,
				currencyDePlc: game.decimalPlc
			});

			// console.log(meta.booking)
			//console.log("Payment: URL => ", JSON.stringify(response));
			//console.log("Payment: URL => ", response.data.data.redirect);

			// Store gameID in session storage
			//sessionStorage.setItem('gameID', meta.booking.gameID);

			//  Redirect to payment page
			// window.location.href = response.data.data.redirect;
			// console.log(response.status)
			// console.log(response.data.data.redirect)
			return [response.status, response.data.data.redirect];
		}
		catch (error) {
			console.error(error);
			console.error("Payment: Failed to generate payment session");
			throw error;
		}
	}

	async function api_payment_cancel(session_id) {
		// console.log("Payment: Cancelling -> ", session_id);
		try {
			const response = await axios.get(`${url}/payment/cancel`, { session_id });
			// Retrieve the game ID from session storage
			//const gameID = sessionStorage.getItem('gameId');
			//console.log(gameID)

			//   sessionStorage.setItem('redirectToGameID', gameID);
			//   window.location.href = '/games';

			// Redirect to the games page with the game ID
			//window.location.href = `/games/${gameID}`;
		}
		catch (error) {
			console.error(error);
			console.error("Payment: Failed to generate payment session");
			throw error;
		}
	}

	// function to calculate total price based on the quantity selected (discounted price for >= 10pax, normal price for < 10 pax)
	// function fcompute_price_total(qty, qty_new) {
	// 	setNetTotal(((qty <= game.discCondition) ? qty : qty_new) * game.normalPrice);
	// 	return netTotal;
	// }

	function fcompute_price_total(quantity, newQuantity) {
		if (quantity < game.discCondition) {
			setNetTotalbeforeGST((quantity * game.normalPrice).toFixed(2));
			return netTotalbeforeGST;
		} else {
			setNetTotalbeforeGST((newQuantity * game.discPrice).toFixed(2));
			return netTotalbeforeGST;
		}
	}

	// function to calculate GST before promo entered
	function calculateGST(quantity, newQuantity) {
		if (quantity < game.discCondition) {
			setGST((quantity * game.normalPrice) * (GSTDEFAULT / 100)).toFixed(2);
			return gst
		}
		else {
			setGST((newQuantity * game.discPrice) * (GSTDEFAULT / 100)).toFixed(2);
			return gst
		}
	};

	// function to calculate total price with gst before promo code
	function calculateGSTTotalPrice(quantity, newQuantity) {
		if (quantity < game.discCondition) {
			let gst = ((quantity * game.normalPrice) * (GSTDEFAULT / 100)).toFixed(2);
			setGST(gst)
			let Total = quantity * game.normalPrice
			setNetTotal((parseFloat(Total) + parseFloat(gst)).toFixed(2))
			setGrossTotal(netTotal)
			// console.log(grossTotal)
			return grossTotal;
		}
		else {
			let gst = ((newQuantity * game.discPrice) * (GSTDEFAULT / 100)).toFixed(2);
			setGST(gst)
			let Total = newQuantity * game.discPrice
			setNetTotal((parseFloat(Total) + parseFloat(gst)).toFixed(2))
			setGrossTotal(netTotal)
			console.log(grossTotal)
			return grossTotal;
		}
	};

	function fcompute_price(quantity, newQuantity, price_normal) {
		if (quantity < 10) {
			setNetTotal(quantity * price_normal);
		}
		else {
			setNetTotal(newQuantity * price_normal);
		}
		return netTotal;
	}

	/**
	 * @returns {string} DateTime of today without seconds
	 */
	function fcompute_date_today() {
		return (new Date()).toISOString().slice(0, 16);
	}

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	};

	/** Step Form : Back */
	function form_step_back() {
		if (promoFeedback != 'Promo code applied') {
			setPromoFeedback('');
		}
		setActiveStep(activeStep - 1);
		setUsePromo('')
		setDiscAmt('')
	}
	/** Step Form : Next */
	function form_step_next() {
		setActiveStep((step) => step + 1);
	}

	/**
	 * when proceeding to the next step, check if its at the last step.
	 */
	function form_step_submit(values, actions) {
		if (isLastStep()) {
			form_submit(values, actions);

		}
		else {
			form_step_next();
			actions.setTouched({});
			actions.setSubmitting(false);
		}
	}


	async function form_submit(values, actions) {
		setIsLoading(true);

		// Perform your loading logic here, such as making API calls or loading data

		// Simulate loading time
		setTimeout(() => {
			setIsLoading(false);
		}, 6000); // 2000 milliseconds = 2 seconds

		await sleep(1000);
		const bookingData = {
			userID: auth.userID,
			quantity: (values.quantity === 'Option length') ? values.newQuantity : values.quantity,
			dateTime: values.dateTime,
			netTotal: netTotal,
			grossTotal: grossTotal,
			promoCode: promoCode,
			gameID: gameId.gameId,
			email: auth.email,
			gameName: game.gameName,
			gameURL: game.gameURL
		};

		// console.log(bookingData)



		try {
			const validationResult = await axios.post(`${url}/games/validateBooking`, bookingData)
			// console.log(validationResult)
			if ([201, 200].includes(validationResult.status)) {
				const [status, redirectURL] = await api_payment_generate(bookingData);
				if (status !== 200) {
					//console.log(response);
					actions.setSubmitting(false);
					setActiveStep(activeStep - 2);
				} else {
					setActiveStep(activeStep + 1);
					window.location.href = redirectURL;
				}
			} else {
				// handle error
				// console.log(validationResult.data.data)
				toast.error(`Error: ${validationResult.data.data}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 10000,
					// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
					// style: {
					// 	fontSize: '13px',
					// 	whiteSpace: 'pre-wrap',
					// 	wordWrap: 'break-word',
					// 	width: '100%',
					// 	display: 'flex',
					// },
					// zIndex: 9999
				});
				actions.setSubmitting(false);
				setActiveStep(activeStep - 2);
			}
		} catch (error) {
			// handle error
			console.log('error.response', error);
			toast.error(`Error: ${error.response.data.data}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 10000,
				// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
				// style: {
				// 	fontSize: '13px',
				// 	whiteSpace: 'pre-wrap',
				// 	wordWrap: 'break-word',
				// 	width: '100%',
				// 	display: 'flex',
				// },
				// zIndex: 9999
			});

			actions.setSubmitting(false);
			setActiveStep(activeStep - 2);
		}
	}





	// const response = await axios.post(`${url}/games/purchase`, bookingData, {
	// 	headers: {
	// 		"Authorization": localStorage.getItem('token'),
	// 		"Content-Type" : "application/json"
	// 	}
	// });

	// if (response.status === 200) {
	// 	// booking is valid, proceed with booking
	// 	setsubmitErr(response.data.data[0]);
	// 	actions.setSubmitting(false);
	// 	setActiveStep(activeStep + 1);
	// }
	//---------------------------------------------
	//  Configuration
	axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
	axios.defaults.headers.common["X-Api-Key"] = localStorage.getItem("apiKey");


	useEffect(() => {
		setgameID(JSON.parse(sessionStorage.getItem('selectedGame')));
	}, []);
	useEffect(api_game_data_retrieve, []);
	// ????
	// useEffect(() => {
	// 	// Clear error message after 5 seconds
	// 	const timer = setTimeout(() => { setPromoFeedback(''); }, 30000); return () => { clearTimeout(timer); };
	// }, [promoFeedback]);

	//  If the user press back from stripe payment page, cancel the payment
	//  When place order is pressed, backend api will create new session
	if (session_id) {
		api_payment_cancel(session_id);
	}
	//---------------------------------------------
	return (
		<section
			{...props}
			className={classNames(outerClasses)}>

			<div className='container-sm'>
				<h1 className='pb-32 center-content'>{game.gameName}</h1>
				<p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
					{game.currency} {game.normalPrice} per pax
				</p> <br />
				{game.discPrice == '-1.00' && game.discCondition == '-1' ? <></> :
					<p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '18px', marginBottom: '30px' }}>
						Ticket priced at {game.currency} {game.discPrice}/pax for booking of {game.discCondition} and above
					</p>}

				{/* 
			<Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
			{steps.map((label) => (
				<Step key={label}>
				<LightStepLabel>{label}</LightStepLabel>
				</Step>
			))}
			</Stepper> */}

				{/* {activeStep === steps.length ? (
			<>
				<div className='center-content'>
				<Typography sx={{ mt: 2, mb: 1 }}>
					Thank you for your purchase! Kindly check your email for the game voucher.
				</Typography>
				</div>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 15 }}>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					variant='contained'>
					<Link to='/'>Home</Link>
				</Button>
				</Box>
				
			</>
			) : ( */}

				<Formik
					initialValues={{ quantity: '', newQuantity: '10', dateTime: '' }}
					validationSchema={validationSchema[activeStep]}
					onSubmit={form_step_submit}
				>
					{({ isSubmitting, values, errors, touched, handleChange }) => (
						<Form>
							{activeStep === 0 ? (
								<>
									<Stepper alternativeLabel activeStep={activeStep} className={classes.root} >
										{steps.map((label) => (
											<Step key={label}>
												<LightStepLabel>{label}</LightStepLabel>
											</Step>
										))}
									</Stepper>
									<div className='formContainer'>
										<label style={{ color: 'white', fontSize: '20px' }}>Select number of tickets<span style={{ color: "red", padding: '5px' }}>*</span></label>
										<select
											name='quantity'
											value={values.quantity}
											onChange={handleChange}
											className='the-form'
											style={{ color: "gray", backgroundColor: '#151719', height: "50px", fontSize: '18px', width: '100%' }}
										>
											<option value=''>
												-- Please select an option --
											</option>
											<option value='2'> 2 </option>
											<option value='3'> 3 </option>
											<option value='4'> 4 </option>
											<option value='5'> 5 </option>
											<option value='6'> 6 </option>
											<option value='7'> 7 </option>
											<option value='8'> 8 </option>
											<option value='9'> 9 </option>
											<option value='Option length'>
												10 and above
											</option>
										</select>
										{errors.quantity && touched.quantity ? (
											<div className='error-message'>{errors.quantity} </div>
										) : null}
									</div>

									<div className='formContainer'>
										{values.quantity === 'Option length' ? (
											<input
												type="number"
												name="newQuantity"
												value={values.newQuantity}
												onChange={handleChange}
												className='form-control'
												placeholder="Please specify amount"
												// style={{ color: "gray", backgroundColor: '#151719' }}
												style={{ color: "gray", backgroundColor: '#151719', height: "50px", width: '100%' }}
												autoComplete='off'
												min="10"
											/>
										) : (
											null
										)}
										{errors.newQuantity && touched.newQuantity ? (
											<div className='error-message'>{errors.newQuantity} </div>
										) : null}
									</div>

									<ThemeProvider theme={theme}>

										{isLoading && (
											<Box
												sx={{
													position: 'fixed',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													background: 'rgba(0, 0, 0, 0.5)',
													zIndex: 9999,
												}}
											>
												<CircularProgress color="inherit" />
											</Box>
										)}

										<Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>

											<Button
												onClick={form_step_back}
												disabled={activeStep == 0}

											>

											</Button>

											<Box sx={{ flex: '1 1 auto' }} />

											<Button
												sx={btnStyle}
												disabled={isSubmitting}
												type='submit'

												style={{ marginBottom: '15px' }}
												variant="contained"
												color='secondary'
												size='small'
											>
												{isLastStep() ? 'Proceed to Payment' : 'Next'}
											</Button>
										</Box>

									</ThemeProvider>
								</>
							) : activeStep === 1 ? (
								<>

									<Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
										{steps.map((label) => (
											<Step key={label}>
												<LightStepLabel>{label}</LightStepLabel>
											</Step>
										))}
									</Stepper>

									<div className='formContainer'>
										<label style={{ color: 'white', fontSize: '20px' }}>Select date and time<span style={{ color: "red", padding: '5px' }}>*</span></label>
										<input
											type='datetime-local'
											value={values.dateTime}
											name='dateTime'
											min={fcompute_date_today()}
											onChange={handleChange}
											className='form-control'
											// style={{ color: "grey", backgroundColor: '#151719' }}
											style={{ color: "gray", backgroundColor: '#151719', height: "50px", fontSize: '18px' }}
										/>
										{errors.dateTime && touched.dateTime ? (
											<div className='error-message'>{errors.dateTime}</div>
										) : null}

									</div>

									<div className='container-xs'>
										<MDBCard className='checkoutCard'>
											<h4 className='center-content'>You have selected</h4>
											<Divider classes={{ root: classes.divider }} />
											<div className='checkout-content'>
												<div className='col-lg-8'>
													<span style={{ color: 'white' }}>{game.gameName}'s Tickets</span><br /><br />
													<span style={{ color: 'white' }}>{values.date} {values.time}</span>
													{/* <span style={{ color: 'white' }}>{values.dateTime.replace("T", " ")}</span> */}
													{/* {console.log('values.dateTime',values.dateTime)} */}
													{values.dateTime ?
														<span style={{ color: 'white' }}>{new Date(values.dateTime).toDateString()} {new Date(values.dateTime).toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' })}</span>
														:
														<span style={{ color: 'white' }}> </span>
													}

													<p style={{ fontSize: '20px', color: 'white' }}>Quantity: {values.quantity === "Option length" ? values.newQuantity : values.quantity}</p>
													{/* <span className='price'>$ {fcompute_price(values.quantity,values.newQuantity )}</span> */}
												</div>
												<div className='col-lg-4' align='right'>
													{/* <span className='price'>${values.quantity != "" ? fcompute_price_total(values.quantity, values.newQuantity) : null}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {fcompute_price_total(values.quantity, values.newQuantity)}</span>
												</div>
											</div>
											<Divider classes={{ root: classes.divider }} />


											<div className='checkout-content pb-16'>
												<div className='col-lg-8'>
													<span className='total'>Total</span>
												</div>
												<div className='col-lg-4' align='right'>
													{/*<span className='price'>$ {discount == '' ? netTotal  : grossTotal}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {fcompute_price_total(values.quantity, values.newQuantity)}</span>
												</div>
											</div>
										</MDBCard>
									</div>

									<ThemeProvider theme={theme}>
										{isLoading && (
											<Box
												sx={{
													position: 'fixed',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													background: 'rgba(0, 0, 0, 0.5)',
													zIndex: 9999,
												}}
											>
												<CircularProgress color="inherit" />
											</Box>
										)}

										<Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
											<Button
												onClick={form_step_back}
												disabled={activeStep == 0}
												sx={btnStyle}
												type='submit'

												style={{ marginBottom: '15px' }}
												variant="contained"
												color='yellow'
												size='small'>
												Back
											</Button>
											<Box sx={{ flex: '1 1 auto' }} />

											<Button
												sx={btnStyle}
												disabled={isSubmitting}
												type='submit'

												style={{ marginBottom: '15px' }}
												variant="contained"
												color='secondary'
												size='small'
											>
												{isLastStep() ? 'Proceed to Payment' : 'Next'}
											</Button>
										</Box>
									</ThemeProvider>
								</>
							) : activeStep === steps.length ? (
								<>

									<div className='center-content'>
										<Typography sx={{ mt: 2, mb: 1 }}>
											Thank you for your purchase! Kindly check your email for the game voucher.
										</Typography>
									</div>
									<br></br>
									<div className='container-xs'>
										<MDBCard className='checkoutCard'>
											<h4 className='center-content'>Invoice</h4>
											<Divider classes={{ root: classes.divider }} />
											<div className='checkout-content'>
												<div className='col-lg-8'>
													{/* <span>JNR Virtal Escape Room Tickets</span><br /> */}
													<span style={{ color: 'white' }}>{game.gameName}'s Tickets</span><br /><br />
													<span style={{ color: 'white' }}>{values.date} {values.time}</span>
													<span style={{ color: 'white' }}>{values.dateTime.replace("T", " ")}</span>

													<p style={{ fontSize: '20px', color: 'white' }}>Quantity: {values.quantity === "Option length" ? values.newQuantity : values.quantity}</p>
												</div>
												<div className='col-lg-4' align='right'>
													{/* <span className='price'>${values.quantity != "" ? fcompute_price_total(values.quantity, values.newQuantity) : null}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {fcompute_price_total(values.quantity, values.newQuantity)}</span>
												</div>
											</div>
											{/* <Divider classes={{ root: classes.divider }} />
							<div className='discountCode'>
							<input
								type='text'
								placeholder='Discount Code'
								className='input'
								value={promoCode}
								onChange={(e) => setUsePromo(e.target.value)}
								disabled={promoFeedback == 'Promo code applied'}
							/>
							<ThemeProvider theme={theme}>
								<Button variant='contained' color='success' onClick={api_promo_validate} className="button button-primary button-wide-mobile button-sm">Apply</Button><br />
							</ThemeProvider>
							{promoFeedback == 'Promo code applied' ? <span className='success-message'>{promoFeedback}</span> : <span className='error-message '>{promoFeedback}</span>}
							</div> */}
											<Divider classes={{ root: classes.divider }} />
											<div className='checkout-content' style={{ color: 'white' }}>
												<div>
													Discount
												</div>
												<div>
													{discount == '' ? 'No Promo Applied' : `${game.currency} ${discount}`}
												</div>
											</div>

											{GSTDEFAULT !== undefined && GSTDEFAULT !== parseInt(0) && (
												<div className='checkout-content pb-16'>
													<div>
														<span className='total'>GST (incl.)</span>
													</div>
													<div>
														<span style={{ color: 'white' }} className='price'>{game.currency} {gst}</span>
													</div>
												</div>
											)}

											<div className='checkout-content pb-16'>
												<div>
													<span className='total'>Total</span>
												</div>
												<div>
													{/* <span className='price'>$ {discount ? grossTotal : netTotal}</span> */}
													{/* <span style={{ color: 'white' }} className='price'>{game.currency} {fcompute_price_total(values.quantity, values.newQuantity)}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {discount == '' ? calculateGSTTotalPrice(values.quantity, values.newQuantity) : netTotal}</span>
												</div>
											</div>
										</MDBCard>
									</div>
									{/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 15 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button
							variant='contained'>
							<Link to='/'>Home</Link>
						</Button>
						</Box> */}
								</>
							) : (
								<>

									<Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
										{steps.map((label) => (
											<Step key={label}>
												<LightStepLabel>{label}</LightStepLabel>
											</Step>
										))}
									</Stepper>
									<div className='container-xs'>
										<MDBCard className='checkoutCard'>
											<h4 className='center-content'>Review</h4>
											<Divider classes={{ root: classes.divider }} />
											<div className='checkout-content'>
												<div className='col-lg-8'>
													{/* <span>JNR Virtal Escape Room Tickets</span><br /> */}
													<span style={{ color: 'white' }}>{game.gameName}'s Tickets</span><br /><br />
													<span style={{ color: 'white' }}>{values.date} {values.time}</span>
													{/* <span style={{ color: 'white' }}>{values.dateTime.replace("T", " ")}</span> */}
													{values.dateTime ?
														<span style={{ color: 'white' }}>{new Date(values.dateTime).toDateString()} {new Date(values.dateTime).toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' })}</span>
														:
														<span style={{ color: 'white' }}> </span>
													}
													<p style={{ fontSize: '20px', color: 'white' }}>Quantity: {values.quantity === "Option length" ? values.newQuantity : values.quantity}</p>
												</div>
												<div className='col-lg-4' align='right'>
													{/* <span className='price'>${values.quantity != "" ? fcompute_price_total(values.quantity, values.newQuantity) : null}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {fcompute_price_total(values.quantity, values.newQuantity)}</span>
												</div>
											</div>
											<Divider classes={{ root: classes.divider }} />
											<div className='discountCode'>
												<input
													type='text'
													placeholder='Discount Code'
													className='input'
													value={promoCode}
													onChange={(e) => setUsePromo(e.target.value)}
												//disabled={promoFeedback == 'Promo code applied'}
												/>
												<ThemeProvider theme={theme}>
												<div sx={styles.div}>
												{!discount || discount === '0' ? (
													<Button variant='contained' color='success' onClick={api_promo_validate} className="button button-primary" sx={styles.applyBtn}>Apply</Button>
												) : (
													<Button variant='contained' color='red' onClick={api_promo_removal} className="button button-remove" sx={styles.removeBtn}>Remove</Button>
												)}
												</div>
												</ThemeProvider>
											</div>

											{promoFeedback && (
												<>
													{promoFeedback == 'Promo code applied' ? <span className='success-message'>{promoFeedback}</span> : <span className='error-message '>{promoFeedback}</span>}
													<br />
												</>
											)}

											<Divider classes={{ root: classes.divider }} />
											<div className='checkout-content' style={{ color: 'white' }}>
												<div>
													Discount
												</div>

												<div>
													{discount == '' ? <span className='nopromo-message'>No Promo Applied</span> : `${game.currency} ${discount}`}

												</div>
											</div>

											{GSTDEFAULT !== undefined && GSTDEFAULT !== parseInt(0) && (
												<div className='checkout-content pb-16'>
													<div>
														<span className='total'>GST (incl.)</span>
													</div>
													<div>
														<span style={{ color: 'white' }} className='price'>{game.currency} {gst}</span>
													</div>
												</div>
											)}

											<div className='checkout-content pb-16'>
												<div>
													<span className='total' style={{ color: 'white' }}>Total</span>
												</div>
												<div>
													{/* <span className='price'>{game.currency} {discount == '' ? netTotal : grossTotal}</span> */}
													<span style={{ color: 'white' }} className='price'>{game.currency} {discount == '' ? calculateGSTTotalPrice(values.quantity, values.newQuantity) : netTotal}</span>
													{/*   <span style={{ color: 'white' }} className='price'>$ {calculating(values.quantity, values.newQuantity, game.normalPrice)}</span>*/}
												</div>
											</div>
										</MDBCard>
									</div>
									<ThemeProvider theme={theme}>

										{isLoading && (
											<Box
												sx={{
													position: 'fixed',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													background: 'rgba(0, 0, 0, 0.5)',
													zIndex: 9999,
												}}
											>
												<CircularProgress color="inherit" />
											</Box>
										)}

										<Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
											<Button
												onClick={form_step_back}
												disabled={activeStep == 0}
												sx={btnStyle}
												type='submit'

												style={{ marginBottom: '15px' }}
												variant="contained"
												color='yellow'
												size='small'>
												Back
											</Button>
											<Box sx={{ flex: '1 1 auto' }} />

											<Button
												// sx={btnStyle}
												sx={{
													...btnStyle,
													width: { xs: '70%', md: 'auto' }, // Set the width to 100% for xs breakpoint (mobile view)
												}}
												disabled={isSubmitting}
												type='submit'
												style={{ marginBottom: '15px' }}
												variant="contained"
												color='secondary'
												size='small'
											>
												{isLastStep() ? 'Proceed to Payment' : 'Next'}
											</Button>
										</Box>
									</ThemeProvider>
								</>
							)}
						</Form>
					)}
				</Formik>
			</div>
		</section>
	);
}
export default Purchase;