import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import axios from 'axios';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//UI path
import "../../forgotpass.css"

const theme = createTheme({
  palette: {
    success: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#000',
    },
  },
});

const Forgot = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const [success, setSuccess] = useState(false);

  function handleSubmit(values) {
    axios.post("/auth/forgot", {
      email: values.email
    })
    .then((response)=>{
      if (response.statusText == 'OK') {
        setSuccess(true);
      }
    }, (err) => {
      toast.error(err.response.data, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  };

  const [isActive, setIsActive] = useState(true);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
    .email('Invalid email address!')
    .required('Email is required.'),
  })

  return (
    <section
      {...props}
      className = {outerClasses}>
    <div className="container-sm">
        <div className={innerClasses} style={{height:"90vh"}}>
          <div className="hero-content">
            <h1 className="mt-5 mb-16 reveal-from-left" data-reveal-delay="200">
              Forgot your password?
            </h1>
    <div className="auth-wrapper" style={{marginTop:"5px", paddingTop:"20px"}}>
        <div className="auth-background-holder"></div>
        <div className="auth-background-mask"></div>

      <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
        <div className="d-flex flex-column align-content-end">
        {success ? (
          <>
          <div className="center-content" style={{marginTop:'-10px', textAlign:'center'}}>
            <p>Password reset link is being sent to your email! <br></br> It might take a few minutes.</p>
          </div>
          <div className="text-center">
            <button 
              className="button button-primary w-100 theme-btn mx-auto"
              ><ThemeProvider theme={theme}>
              <Link to='/login'>Back to Login</Link>
              </ThemeProvider>
            </button>
          </div>
          </>
        ) : (
        <>
        <div className="auth-p mx-auto">
          <p>We'll email you instructions on how to reset it.</p>
        </div>
          <div className="auth-body mx-auto">
            <div className="auth-form-container text-start">
            <Formik
              initialValues={{ 
                email: '',
              }}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}>
              {({isSubmitting, values, errors, handleChange, handleBlur}) => (
                <Form>
                  
                  <div className="input-group">
                  <label for="email">
                    Email 
                    <span style={{color:"red"}}>*</span>
                  </label>
                 
                  
                 
                  <input
                    id="email"
                    placeholder="demo@gmail.com"
                    type="text"
                    autoComplete='off'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-control'
                    style={{width: '450px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white'}}
                  />
                  {errors.email && <div className="input-feedback">{errors.email}</div>}
                  

              
                
                  
                    
                  <button type="submit" 
                  style={{marginLeft: '-3px', width: '405px', height: '50px', marginTop: '30px', textAlign: 'center', fontSize: '0.875rem', backgroundColor: '#fff', color: 'black'}}
                  onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
                    Submit
                  </button>
                  </div>
                    
                </Form>
              )}
            </Formik>

              <div className="auth-option text-center pt-2" style={{marginTop: '10px'}}>
                <Link className="text-link" to="/login">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
          </>
        )}
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </section>
  );
};

export default Forgot;


// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import Form from "../../utils/Forms";
// import classNames from 'classnames';
// import axios from 'axios';

// const Forgot = ({
//   topDivider,
//   bottomDivider,
//   ...props
// }) => {

//   const innerClasses = classNames(
//     'hero-inner section-inner',
//     topDivider && 'has-top-divider',
//     bottomDivider && 'has-bottom-divider'
//   );

//   const outerClasses = classNames(
//     'hero section center-content',
//   );

//   const [email, setEmail] = useState("");
//   const [validate, setValidate] = useState({});
//   const [success, setSuccess] = useState(false);

//   const handleSubmit = (e) => {
//     axios.post("/auth/forgot", {
//       email: email
//     })
//     .then((response)=>{
//       console.log(response.data)
//     })
//   };

//   // useEffect(() =>{
//   //   handleSubmit()
//   // },[])

//   const validateforgotPassword = () => {
//     let isValid = true;

//     let validator = Form.validator({
//       email: {
//         value: email,
//         isRequired: true,
//         isEmail: true,
//       },
//     });

//     if (validator !== null) {
//       setValidate({
//         validate: validator.errors,
//       });

//       isValid = false;
//     }
//     return isValid;
//   };

//   const forgotPassword = (e) => {
//     e.preventDefault();

//     const validate = validateforgotPassword();

//     if (validate) {
//       // alert("Reset password link is sent to " + email);
//       setValidate({});
//       handleSubmit();
//       setEmail("");
//       setSuccess(true);
//     }
//   };

//   const [isActive, setIsActive] = useState(true);

//   const handleClick = () => {
//     setIsActive(current => !current);
//   };

//   return (
//     <section
//       {...props}
//       className = {outerClasses}>
//     <div className="container-sm">
//         <div className={innerClasses} style={{height:"100vh"}}>
//           <div className="hero-content">
//             <h1 className="mt-5 mb-16 reveal-from-left" data-reveal-delay="200">
//               Forgot your password?
//             </h1>
//     <div className="auth-wrapper" style={{marginTop:"5px", paddingTop:"20px"}}>
//       {/* <div className="auth-background-col"> */}
//         <div className="auth-background-holder"></div>
//         <div className="auth-background-mask"></div>
//       {/* </div> */}

//       <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
//         <div className="d-flex flex-column align-content-end">
//         {success ? (
//           <>
//           <div className="center-content" style={{marginTop:'-10px', textAlign:'center'}}>
//             <p>Password reset link is being sent to your email! <br></br> It might take a few minutes.</p>
//           </div>
//           <div className="text-center">
//             <button 
//               className="button button-primary w-100 theme-btn mx-auto"
//               >
//               <Link to='/login'>Back to Login</Link>
//             </button>
//           </div>
//           </>
//         ) : (
//         <>
//         <div className="auth-p mx-auto">
//           <p>We'll email you instructions on how to reset it.</p>
//         </div>
//           <div className="auth-body mx-auto">
//             <div className="auth-form-container text-start">
//               <form
//                 className="auth-form"
//                 // method="POST"
//                 onSubmit={forgotPassword}
//                 autoComplete={"off"}
//               >
//                 <label>Email <span style={{color:"red"}}>*</span></label>
//                 <div className="email mb-3" style={{backgroundColor: isActive ? '#151719' : '#151719'}}>
//                   <input
//                     type="email"
//                     className={`form-control ${
//                       validate.validate && validate.validate.email
//                         ? "is-invalid "
//                         : ""
//                     }`}
//                     id="email"
//                     name="email"
//                     value={email}
//                     placeholder="test@gmail.com"
//                     onChange={(e) => setEmail(e.target.value)}
//                     style={{color: "gray", backgroundColor: isActive ? '#151719' : '#151719'}} 
//                   />

//                   <div
//                     className={`invalid-feedback text-start ${
//                       validate.validate && validate.validate.email
//                         ? "d-block"
//                         : "d-none"
//                     }`}
//                   >
//                     {validate.validate && validate.validate.email
//                       ? validate.validate.email[0]
//                       : ""}
//                   </div>
//                 </div>

//                 <div className="text-center">
//                   <button
//                     onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}
//                     type="submit"
//                     className="button button-primary w-100 theme-btn mx-auto"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </form>

//               <hr />
//               <div className="auth-option text-center pt-2">
//                 <Link className="text-link" to="/login">
//                   Back to Login
//                 </Link>
//               </div>
//             </div>
//           </div>
//           </>
//         )}
//         </div>
//       </div>
//     </div>
//     </div>
//     </div>
//     </div>
//     </section>
//   );
// };

// export default Forgot;