// Essentials
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { StickyContainer, Sticky } from 'react-sticky';
import Fab from '@mui/material/Fab';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import {
  BrowserRouter as Router,
  useHistory
} from 'react-router-dom';


// Material UI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import moment from 'moment'; // format datetime
// Display http message
import 'react-toastify/dist/ReactToastify.min.css';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Typography from '@mui/material/Typography';
//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css' // https://mdbootstrap.com/docs/react/
import Image from '../../components/elements/Image';
// MDB 5
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardImage,
  MDBCarousel,
  MDBCarouselItem
} from 'mdb-react-ui-kit';
import { alignProperty } from '@mui/material/styles/cssUtils';


import "../../games.css"

const url = process.env.REACT_APP_API_URL;

const Product = ({
  imageFill,
  ...props
}) => {

  // Styles
  const innerClasses = classNames(
    'section',
  );

  const outerClasses = classNames(
    'section center-content',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
  );

  const outerTiles = classNames(
    'review-tiles-outer',
  );

  const splitWrap = classNames(
    'split-wrap'
  );

  // styling for difficuty level stars
  const useStyles = makeStyles(() => ({
    emptyStar: {
      color: "white"
    }
  }));

  const classes = useStyles();

  const scrollDown = () => {
    window.scrollTo({
      top: infoSection.current.offsetTop,
      behavior: 'smooth'
    });
  };

  // to overide material ui button default colour themes 
  const theme = createTheme({
    palette: {
      success: {
        main: '#fff',
        contrastText: '#000',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000',
      },
    },
  });

  // Called Apis
  //const gameID = 1

  //   // Called Apis
  // let url_params = window.location.href
  // let url_string = url_params.split('/')
  // let roomID = url_string[4]
  const [gameId, setgameID] = useState(JSON.parse(sessionStorage.getItem('selectedDetailedGame')));

  const [rooms, setAllRooms] = useState([]);

  const getAllRooms = async () => {
    await axios.post(`${url}/admin/room`, {
      gameID: gameId.gameId
    }).then((response) => {

      setAllRooms(response.data.data[0])

    })
  };

  const infoSection = useRef(null);

  // useEffect(() => {
  //   getAllRooms()
  // }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // styling for buttons 
  const btnStyle = {
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: "10px",
    width: '10%',
    height: '35px',
    margin: '10px',
    color: 'customColor.primary'
  };

  // const stickBtnStyle = {
  //   fontWeight: 'bold',
  //   fontSize: 14,
  //   width: '100px',
  //   height: '45px',
  //   marginRight: '17%',
  //   marginTop: '70px',
  //   bottom: '15px',
  //   position: 'absolute',
  //   color: 'customColor.primary'

  // };


  const history = useHistory();

  const bookNow = () => {
    history.push('/purchase');
  };



  // Copy to clipboard function (for promotion code)
  function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    const handleCopyClick = () => {
      copyTextToClipboard(copyText)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div>
        <span className='cpnCode'>{copyText}</span>
        <button onClick={handleCopyClick} className='cpnBtn'>
          <span>{isCopied ? "COPIED!" : "COPY CODE"}</span>
        </button>
      </div>
    )
  };


  // states for game data called using getGamesData api 
  const [games, setGames] = useState([]);

  const getGamesData = async () => {
    //console.log(gameId.gameId)
    await axios.post(`${url}/games/gameDataP`, { // api only inlcudes the routes parameter as proxy was used to specify the server: localhost:5000 (stated in the last line of codes at package.json)
      gameID: gameId.gameId
    }).then((response) => {
      // console.log('first' + JSON.stringify(response.data.data[0]))
      setGames(response.data.data)

    })
  };

  // useEffect(() => {
  //   console.log(sessionStorage.getItem('selectedDetailedGame'));
  //   setgameID(JSON.parse(sessionStorage.getItem('selectedDetailedGame')));
  //   getGamesData()
  // }, []);

  // states for user reviews called from getAllReviews api
  const [reviews, setAllReviews] = useState([]);

  const getAllReviews = async () => {
    await axios.post(`${url}/games/reviews`, {
      gameID: gameId.gameId
    }).then((response) => {
      setAllReviews(response.data[0])
      //console.log(response.data)
    })
  };

  // states fpr promotions called from getAllPromo api
  const [promos, setAllPromos] = useState([]);

  const getAllPromos = async () => {
    await axios.post(`${url}/games/promotions`, {
      gameID: gameId.gameId
    }).then((response) => {

      //console.log(response.data.data[0])
      setAllPromos(response.data.data[0])
    })
  };

  const handleClick = (gameURL) => {
    window.location.replace(gameURL);
  }

  const navigateSelectedGame = (gameType, gameID) => {
    sessionStorage.setItem('selectedGame', JSON.stringify({ gameId: gameID }));
    // console.log('gameType - ' + gameType);
    //console.log(sessionStorage.getItem('selectedGame'));

    if (gameType == 'TYPE1') {
      history.push('/purchaseRoom')

    }
    else {
      history.push('/purchaseHost')
    }
    
  }

  useEffect(() => {
    //getGamesData()
    //getAllReviews() 
    //console.log(sessionStorage.getItem('selectedDetailedGame'));
    setgameID(JSON.parse(sessionStorage.getItem('selectedDetailedGame')));
    getGamesData()

    getAllPromos()
  }, []);


  return (
    <section
      {...props}
      className={classNames(outerClasses)}> 
      {games.map((games) => (
        <div>



          {/* start of Difficulty level/Timelimit/paxCost */}

          <div className="container-sm ta-l" style={{ marginTop: '-120px' }}>
            <div className='container-sm ta-l'>
              <div >

                <div id="parent">
                  {/* <span id="left"> */}
                  {/* <h1 className="h1Games"> */}
                  <h1>
                    {games.gameName}
                  </h1>
                  {/* </span> */}

                  {/* <span id="right">
                    <div className='btnContainer'>
                      <ThemeProvider theme={theme}>
                        <Button onClick={() => navigateSelectedGame(games.gameType, games.gameID)} style={{ color: 'black' }}>
                          Book
                        </Button>

                      </ThemeProvider>
                    </div>
                  </span> */}
                </div>

                <ul className="listSettings" >

                  <div className='container-sm'>



                    <div className='infoContainer'>

                      <li>
                        <div>
                          <Typography variant="subtitle1" style={{ color: '#f6c94e' }}>
                            Difficulty Level
                          </Typography>
                          <Rating
                            name="difficultyLevel"
                            value={parseInt(games.difficultyLvl)}
                            precision={1}
                            icon={<VpnKeyIcon fontSize="inherit" />}
                            readOnly
                            emptyIcon={
                              // <StarBorderIcon fontSize="inherit" className={classes.emptyStar} />
                              <VpnKeyOutlinedIcon fontSize="inherit" className={classes.emptyStar} />
                            }
                          />
                        </div>
                      </li>

                      <li>
                        <div>
                          <Typography variant="subtitle1" style={{ color: '#f6c94e' }}>
                            Time Limit
                          </Typography>
                          <Typography>
                            <b>{games.duration}</b> Minutes
                          </Typography>
                        </div>
                      </li>

                      <li>
                        <div>
                          <Typography variant="subtitle1" style={{ color: '#f6c94e' }}>
                            Ticket Price
                          </Typography>
                          <Typography>
                            {games.currency} {games.normalPrice} per pax
                          </Typography>
                        </div>
                      </li>

                      <li>

                        <div className="tikPrice">
                          {games.discPrice == '-1.00' && games.discCondition == '-1' ? <></> :
                            <Typography>
                              <p>
                                <span style={{ fontWeight: 'bold' }}>Ticket priced at {games.currency} {games.discPrice}/pax</span><br></br>
                                <span>for booking of {games.discCondition} and above</span>
                              </p>
                            </Typography>}
                        </div>
                      </li>


                    </div>
                  </div>
                  {/* <br></br> */}
                </ul>


                <div id="parent-sub">
                  <span id="left">
                    &nbsp;
                  </span>
                  <span id="left">
                    &nbsp;
                  </span>
                  <span id="right">
                    <div className='btnContainerPlay'>
                      <ThemeProvider theme={theme}>
                        {games.gameURL ?
                          <Button
                            style={{ color: 'white' }}
                            onClick={() => handleClick(games.gameURL)}>Play Online Game
                          </Button> :
                          (<></>)
                        }

                      </ThemeProvider>
                    </div>
                  </span>
                  &nbsp;
                  <span id="right">
                    <div className='btnContainersub'> 
                      <ThemeProvider theme={theme}>
                        <Button onClick={() => navigateSelectedGame(games.gameTypeShort, games.gameID)} style={{ color: 'black' }}>
                          Book
                          {/* <Link to={`/purchase/${games.gameID}`}>Book</Link> */}
                        </Button>

                      </ThemeProvider>
                    </div>
                  </span>
                </div>


                {console.log('games.gamePic - ' + games.images[0])}
                {/* <Image src={require(`./../../assets/dbImage/rooms/${games.gamePic}`)} style={{ height: '220px', width: "100%", height: "auto" }} position='top' alt='...' /> */}
                <Image src={games.images[0]} style={{ height: '220px', width: "100%", height: "auto" }} position='top' alt='...' />
                <p className="= fw-500">
                  {games.gameDesc}
                </p>
              </div>





              {/* <div className="container-xl" style={{ paddingBottom: '15px' }}>
            <div className={classNames(tilesClasses)}>
              <MDBCard className='pricingCard'>
                <MDBCardBody className='cardBody'>
                  <h4 className="mt-0 text-sm ">
                    Affordable Pricing @
                  </h4>
                  <h2 className="m-0 text-xl text-color-primary">
                    ${rooms.normalPrice} / Pax
                  </h2><br />
                  <p className='text-sm'>Ticket priced at ${rooms.discPrice}/pax</p> 
                  <p className='text-sm2'>for booking of {rooms.discCondition} and above</p>
                    
                
                </MDBCardBody>
                
              </MDBCard>
              
            </div>
          </div> */}

              <h3 class="center-content">How does a virtual escape room work</h3>
              <div className={splitWrap}>
                <div className="split-item ta-l">
                  <div className="split-item-content center-content-mobile " data-reveal-container=".split-item" style={{ order: '1' }}>
                    <div className="gameText01">
                      01
                    </div>
                    <h3 className="gameText">
                      GATHER <br></br>YOUR TEAM
                    </h3>
                    <p className="m-1">
                      Invite and build your group of escapers. Decide on a good day and timing that works.
                    </p>
                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile ',
                      imageFill && 'split-item-image-fill'
                    )}
                    data-reveal-container=".split-item">
                    <Image
                      src={require('./../../assets/images/01_gatherteam_copy.jpg')}
                      alt="Features split 01"
                      width={528}
                      height={396} />
                  </div>
                </div>

                <div className="split-item ta-l">
                  <div className="split-item-content center-content-mobile " data-reveal-container=".split-item" style={{ order: '1' }}>
                    <div className="gameText01">
                      02
                    </div>
                    <h3 className="gameText">
                      SELECT <br></br>A THEME
                    </h3>
                    <p className="m-1">
                      Pick a room that is most enticing to you from ranges of our exciting options.
                    </p>
                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile ',
                      imageFill && 'split-item-image-fill'
                    )}
                    data-reveal-container=".split-item">
                    <Image
                      src={require('./../../assets/images/02_selecttheme_copy.jpg')}
                      alt="Features split 01"
                      width={528}
                      height={396} />
                  </div>
                </div>

                <div className="split-item ta-l">
                  <div className="split-item-content center-content-mobile " data-reveal-container=".split-item" style={{ order: '1' }}>
                    <div className="gameText01">
                      03
                    </div>
                    <h3 className="gameText">
                      SUBMIT & RECIEVE
                    </h3>
                    <p className="m-1">
                      Send over the information and wait for an official quotation to be sent out shortly.
                    </p>
                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile ',
                      imageFill && 'split-item-image-fill'
                    )}
                    data-reveal-container=".split-item">
                    <Image
                      src={require('./../../assets/images/03_submitreceive_copy.jpg')}
                      alt="Features split 01"
                      width={528}
                      height={396} />
                  </div>
                </div>


                <div className="split-item ta-l">
                  <div className="split-item-content center-content-mobile " data-reveal-container=".split-item" style={{ order: '1' }}>
                    <div className="gameText01" style={{ margintop: '30px' }}>
                      04

                    </div>
                    <h3 className="gameText">
                      GET READY TO ESCAPE
                    </h3>
                    <p className="m-1">
                      Instructions and details will be delivered, better prepare yourself for the best virtual game experience with your team!
                    </p>
                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile ',
                      imageFill && 'split-item-image-fill'
                    )}
                    data-reveal-container=".split-item">
                    <Image
                      src={require('./../../assets/images/04_getready_copy.jpg')}
                      alt="Features split 01"
                      width={528}
                      height={396}
                    />
                  </div>
                </div>
              </div>
            </div><br /><br />

            <div id="parent-sub">
              <span id="left">
                &nbsp;
              </span>
              <span id="left">
                &nbsp;
              </span>
              <span id="right">
                <div className='btnContainerPlay'>
                  <ThemeProvider theme={theme}>
                    {games.gameURL ?
                      <Button
                        style={{ color: 'white' }}
                        onClick={() => handleClick(games.gameURL)}>Play Online Game
                      </Button> :
                      (<></>)
                    }

                  </ThemeProvider>
                </div>
              </span>
              &nbsp;
              <span id="right">
                <div className='btnContainersub'>
                  <ThemeProvider theme={theme}>
                    <Button onClick={() => navigateSelectedGame(games.gameTypeShort, games.gameID)} style={{ color: 'black' }}>
                      Book
                      {/* <Link to={`/purchase/${games.gameID}`}>Book</Link> */}
                    </Button>

                  </ThemeProvider>
                </div>
              </span>
            </div>

            {/* <div className='btnContainer'>
              <ThemeProvider theme={theme}>
                <Button onClick={() => navigateSelectedGame(games.gameType, games.gameID)} style={{ color: 'black' }}>
                  Book
                </Button>

              </ThemeProvider>
            </div> */}


            {/* edited here */}
            <div className='container-sm' ref={infoSection}>

              <h3 className='container-xl center-content'>Current Promotions</h3><br />
              <div className='couponContainer'>
                <div className={tilesClasses}>
                  {promos.length > 0 ?
                    (promos.map((promo, index) => {
                      return (
                        <MDBCard className='couponCard' key={index}>
                          <div className='couponCard-inner'>
                            <h4>{promo.description}</h4>
                            <p>Min Spend. {games.currency} {promo.minSpend}</p>
                            <div className='couponCard-row'>
                              <ClipboardCopy copyText={promo.code} />
                            </div>
                            <p className='text-sm'>Valid till <b>{moment.utc(promo.validEnd).format('D MMMM YYYY h:mm A')}</b></p>
                            <div className='circle1'></div>
                            <div className='circle2'></div>
                          </div>
                        </MDBCard>


                      )
                    })


                    ) :

                    (
                      <p className='pt-16'>No promotion at the moment</p>
                    )
                  }
                </div>
              </div>

            </div>



          </div>

        </div>
      ))}

    </section>
  );
}

export default Product;