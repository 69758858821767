
// material-ui
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import
import AnimateButton from 'src/components/elements/AnimateButton';

import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_URL;

// ============================|| FIREBASE - REGISTER ||============================ //

const AuthOTPVerification = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  // to overide material ui button default colour themes 
  const theme = createTheme({
    palette: {
      success: {
        main: '#f6c94e',
        contrastText: '#000000',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000000',
      },
      subSecondary: {
        main: '#70140c',
        contrastText: '#fff',
      },
    },
  });

  const [isActive, setIsActive] = useState(true);

  const location = useLocation();
  console.log(JSON.stringify(location.state));
  const userEmail = location.state.state.email
  const indicator = location.state.state.indicator3;
  //console.log(`otp email ${userEmail}`)
  const history = useHistory();
  // Axios.get(`${url}/user/OTPDisplay`).then(resp => {
  //     console.log('try' + resp.data);
  //     let Indicator = document.getElementById('indicator');
  //     Indicator.innerHTML = resp.data;
  //     });
  //-----------------------------------------------------------
  const [counter, setCounter] = React.useState(60); //60
  const [showText, setShowText] = React.useState(false);
  React.useEffect(() => {
    document.getElementById("indicator").innerHTML = indicator;
  }, []);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  React.useEffect(() => {
    const timer2 = setTimeout(() => {
      setShowText(true);
    }, 60000);//60000
    return () => clearTimeout(timer2);
  }, []);
  /* function reloadtimer () {
      const timer2 = setTimeout(() => {
      setShowText(true);
      }, 60000);//60000
       return () => clearTimeout(timer2);}     */
  function handleClick() {
    Axios.patch(`${url}/user/sendForgetPasswordVerificationEmail`, //changed2
      {
        EmailToReset: userEmail
      }).then(response => {
        const indicator1 = JSON.stringify(response.data);
        const indicator2 = indicator1.substring(indicator1.indexOf('first3":"') + 9, indicator1.indexOf('first3":"') + 12);

        history.push('/otpverification', { state: { email: userEmail, indicator3: indicator2 } });
        toast.success("Password reset email sent", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
          style: {
            fontSize: '13px',
            height: '85px',
            width: '100%',
            display: 'flex',
          },
          zIndex: 9999
        });

        //console.log("this is indicator2", indicator2);
      });
    setShowText(false);
    setCounter(60); //60
    //reloadtimer();
    // history.push('/otpverification', { state:{ email: userEmail } });
    // toast.success(`Password reset email sent`,{
    //     position: toast.POSITION.TOP_CENTER, 
    //     autoClose: 4000, 
    //     backgroundColor: '#f44336', 
    //     color: '#fff', 
    //     borderRadius: '10px', 
    //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
    //     style: { 
    //         fontSize: '13px',
    //         width: '390px',
    //         height: '85px',
    //         display: 'flex',
    //         right:'10%'
    //     },      
    //     zIndex: 9999 
    // })
    // window.location.reload();
    setTimeout(() => {
      window.location.reload(false);
    }, 3000);
  }
  //----------------------------------------timer
  /*     document.getElementById("attachmentName").onClick = function () {
      Axios.patch(`${url_open}/user/sendForgetPasswordVerificationEmail`, //changed2
      {
          EmailToReset: userEmail
      })
     }; */
  return (
    <>
      <section
        {...props}
        className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses} style={{ height: "90vh" }}>
            <div className="hero-content">
              <h1 className="mt-5 mb-16 reveal-from-left" data-reveal-delay="200">
                Forgot your password?
              </h1>
              <div className="auth-wrapper" style={{ marginTop: "5px", paddingTop: "20px" }}>
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>

                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                  <div className="d-flex flex-column align-content-end">
                    <div className="auth-p mx-auto">
                      <p>An email with verification code was sent</p>
                    </div>
                    <div className="auth-body mx-auto">
                      <div className="auth-form-container text-start">
                        <Formik
                          initialValues={{
                            otp: '',
                            submit: null
                          }}
                          validationSchema={Yup.object().shape({
                            otp: Yup.string().max(9).required('Verification code is required')
                          })}
                          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                              setStatus({ success: false });
                              setSubmitting(false);
                              /* Axios.get(`${url_open}/user/OTPDisplay`).then(resp => {
                              console.log('try' + resp.data);
                              let Indicator = document.getElementById('indicator');
                              Indicator.innerHTML = resp.data;
                              }); */
                              //------------------------------------------------------------
                              let Indicator = document.getElementById('indicator');
                              Axios.patch(`${url}/user/OTPVerification`, //changed2
                                {
                                  OTP: values.otp,
                                  indicator: Indicator.innerHTML
                                }
                              ).then(response => {
                                console.log(`Response - ${response.status} - ${response.data}`)

                                response.status === 200 ? console.log("Password reset email sent!") : console.log("Password reset email failed to send!")

                                if (response.status === 200) {
                                  //const dataReceived = req.query;
                                  //const newDataToSend = { ...dataReceived };
                                  //const newQueryString = Object.keys(newDataToSend).map(key => `${key}=${newDataToSend[key]}`).join("&");
                                  //res.redirect(`/changenewpassword?${newQueryString}`);
                                  //console.log('inter pg' + newDataToSend);
                                  toast.success(`Verification code correct!`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                    style: {
                                      fontSize: '13px',
                                      height: '85px',
                                      width: '100%',
                                      display: 'flex',
                                    },
                                    zIndex: 9999
                                  });

                                  // toast.success(`Verification code correct!`,{
                                  //   position: toast.POSITION.TOP_CENTER, 
                                  //   autoClose: 4000, 
                                  //   backgroundColor: '#f44336', 
                                  //   color: '#fff', 
                                  //   borderRadius: '10px', 
                                  //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                  //   style: { 
                                  //     fontSize: '13px',
                                  //     width: '390px',
                                  //     height: '85px',
                                  //     display: 'flex',
                                  //     right:'10%'
                                  //   },      
                                  //   zIndex: 9999 
                                  // });
                                  history.push('/changenewpassword', { state: { email1: userEmail, OTP: values.otp, threeindicator: Indicator.innerHTML } });
                                }
                              }).catch((error) => {
                                if (error.response) {
                                  console.log(error.response.data);
                                  console.log(error.response.status);
                                  console.log(error.response.headers);
                                  if (error.response.status === 400) {
                                    toast.error(`Verification code does not exist!`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                    // console.log("Verification code does not exist!");
                                  }
                                  if (error.response.status === 401) {
                                    toast.error(`Verification code expired!`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                    // console.log("Verification code expired!");
                                  }
                                  if (error.response.status === 500) {
                                    toast.error(`OTP does not exist, please try again.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                    // console.log("OTP does not exist, please try again.");
                                  }
                                } else {
                                  // Something happened in setting up the request that triggered an Error
                                  toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    // style: { 
                                    // fontSize: '13px',
                                    // height: '85px',
                                    // width: '100%',
                                    // display: 'flex',
                                    // },      
                                    // zIndex: 9999 
                                  });
                                  // console.log('Error', error.message);
                                }
                              });

                            } catch (err) {
                              console.error(err);
                              setStatus({ success: false });
                              setErrors({ submit: err.message });
                              setSubmitting(false);
                            }
                          }}
                        >
                          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Stack spacing={1} >
                                    <div className="input-group">
                                      <label for="otp" style={{ display: 'block', marginTop: '20px', fontSize: '20px', marginBottom: '20px' }}>
                                        Verification Code
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                                        <Typography variant="h6" id="indicator"> </Typography>
                                        <Typography>-</Typography>
                                        <input
                                          id="otp"
                                          type="text"
                                          value={values.otp}
                                          name="otp"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          //placeholder = ""
                                          className='form-control'
                                          style={{ width: '320px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                          inputProps={{}}
                                        />
                                      </Stack>
                                    </div>

                                    {/* <InputLabel htmlFor="otp" sx={{ color:'white', fontSize:'20px'}}>Verification Code*</InputLabel>
                                    <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                                        <Typography variant="h6" id = "indicator"> </Typography>       
                                        <Typography>-</Typography>                            
                                        <OutlinedInput
                                            fullWidth
                                            //error={Boolean(touched.email && errors.email)}
                                            id="otp"
                                            type="text"
                                            value={values.otp}
                                            name="otp"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            //placeholder="demo@company.com"
                                            className='form-control'
                                            style={{ width: '400px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}          
                                            inputProps={{}}
                                        />
                                        <br/>
                                    </Stack> */}
                                    {/* {touched.email && errors.email && (
                                            <FormHelperText error id="helper-text-email-signup">
                                                {errors.email}
                                            </FormHelperText>
                                        )} */}
                                  </Stack>
                                </Grid>
                                {errors.submit && (
                                  <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                  </Grid>
                                )}
                                <Grid item xs={12} >
                                  {showText ? (
                                    //<Typography style={{textDecoration: 'none', color: "primary", variant: "h6"  }} href="/otpverification" onClick={handleClick}>Didn't receive the code? Click here to resend</Typography>
                                    <Typography variant="body1" sx={{ textDecoration: 'none', fontSize: '20px' }} color="primary" onClick={handleClick}>Didn't receive the code? Click here to resend</Typography>
                                  ) : (
                                    <Typography variant="h6" color="primary" sx={{ fontSize: '20px', color: 'white' }}> Resend code in {counter}s </Typography>
                                  )}
                                  {/*  <Typography variant="h6">Countdown: {counter}</Typography> */}
                                </Grid>
                                <ThemeProvider theme={theme}>
                                  <Grid item xs={12}>
                                    <AnimateButton>
                                      <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        style={{ color: 'black', backgroundColor: 'white' }}
                                      >
                                        Next
                                      </Button>
                                    </AnimateButton>
                                  </Grid>
                                </ThemeProvider>

                              </Grid>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/><br/>
      </section>
    </>
  );
};


export default AuthOTPVerification;
