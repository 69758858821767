import React, { useState }from 'react';
import classNames from 'classnames';

const TermsOfService = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

    return (
      <section
      {...props}
      className = {outerClasses}>

      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
              Terms of Service 
            </h1>
            <br></br>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}>
              JNR TERMS OF SERVICE </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}>
              BY ACCEPTING THESE TERMS, BY  
              (1) CLICKING A BOX OR BUTTON INDICATING ACCEPTANCE, 
              (2) EXECUTING AN ORDER FORM THAT REFERENCES THESE TERMS, OR 
              (3) USING A FREE PRODUCT, YOU AGREE TO THESE TERMS. IF YOU REGISTER FOR JNR’S FREE PRODUCT, THE APPLICABLE PROVISIONS OF THESE TERMS WILL ALSO GOVERN THAT FREE PRODUCT.  IF THE INDIVIDUAL ACCEPTING THESE TERMS IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE THE TERM “YOU” REFERS TO SUCH ENTITY AND ITS AFFILIATES. IF THE INDIVIDUAL ACCEPTING THESE TERMS DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS, SUCH INDIVIDUAL MUST NOT ACCEPT THESE TERMS AND MAY NOT USE THE PLATFORM. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 1. PLATFORM. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> Subject to the terms and conditions of these Terms, JNR grants you during the Term (defined below) a limited, non-exclusive, non-transferable, non-sublicenseable right to access and use the JNR website, software development platform, engineering workforce and module library for the purpose of managing, hosting, developing, launching, and analyzing your proprietary software applications. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 2. PROFESSIONAL SERVICES. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> Subject to the terms and conditions of these Terms, Crowdbotics will perform the professional engineering, design, or product management services set forth on an Order Form or as otherwise requested through the Platform, which may include managed use of the Platform by Crowdbotics on your behalf (“Services”).  The manner and means by which JNR chooses to complete the Services are in Crowdbotics’ sole discretion and control. Crowdbotics’ obligations to provide the Services are conditioned upon receiving such information and cooperation from you as may be reasonably necessary to perform the Services. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 3. RESTRICTIONS. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> The rights granted to you in these Terms are subject to the following restrictions: (a) you may not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Platform, whether in whole or in part, or any content displayed on the Platform; (b) you may not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Platform; and (c) except as expressly stated herein, no part of the Platform may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise indicated, any future release, update, or other addition to functionality of the Platform is subject to these Terms.  Unless otherwise specified on the Platform, all copyright and other proprietary notices on the Platform (or on any content displayed on the Platform) must be retained on all copies thereof. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 4. YOUR RESPONSIBILITIES. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> You will (a) be responsible for your authorized users’ (“Users”) compliance with these Terms and Order Forms, (b) be responsible for the accuracy, quality and legality of User Content (defined below), (c) be responsible for your use of your Apps (including any Invention and any Prior Invention or Reusable Module (each defined below) incorporated into an Invention) and the interoperation of your App with any third party application, (d) use commercially reasonable efforts to prevent unauthorized access to or use of Platform, and notify Crowdbotics promptly of any such unauthorized access or use, and (e) use the Platform and Services only in accordance with these Terms and applicable laws and government regulations. Any use of the Platform in breach of the foregoing by you or Users that in Crowdbotics’ judgment threatens the security, integrity or availability of Crowdbotics’ services, may result in Crowdbotics’ immediate suspension of the Platform, however Crowdbotics will use commercially reasonable efforts under the circumstances to provide you with notice and an opportunity to remedy such violation or threat prior to any such suspension. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 5. ACCOUNTS. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> <span style={{fontWeight:"bold"}}>a. Registration.</span> In order to use certain features of the Platform, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form.  You represent and warrant that: (i) all required registration information you submit is truthful and accurate; (ii) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Platform.  Crowdbotics may suspend or terminate your Account in accordance with Section 9.
              <br></br> <br></br> <span style={{fontWeight:"bold"}}>b. Responsibilities.</span> You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account.  You agree to immediately notify Crowdbotics of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security.  Crowdbotics cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 6. USER CONTENT. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> “User Content” means any and all information and content that you submit to the Platform (e.g., Materials (defined below) that you submit to the Platform).  You are solely responsible for your User Content.  You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party.  You represent and warrant that your User Content does not violate Crowdbotics’ Acceptable Use Policy (set forth below).  You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by Crowdbotics.  Because you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 7. OWNERSHIP. </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> <span style={{fontWeight:"bold"}}>a. Platform.</span> Crowdbotics retains all rights in the Platform, except as expressly set forth in these Terms.
              <br></br> <br></br> <span style={{fontWeight:"bold"}}>b. Apps.</span> You own your Apps, subject to the terms and conditions of these Terms.
              <br></br> <br></br> <span style={{fontWeight:"bold"}}>c. Inventions.</span> All rights in and to any copyrightable software, algorithm, code, material, notes, records, drawings, designs, inventions, improvements, developments, discoveries and trade secrets (“Materials”) conceived, discovered, authored, invented, developed or reduced to practice by Crowdbotics, solely or in collaboration with others, during the Term and arising out of, or in connection with, performing the Services under these Terms and any copyrights, patents, trade secrets, mask work rights or other intellectual property rights relating to the foregoing (collectively, but excluding Prior Inventions and Reusable Modules “Inventions”), are your sole property. Crowdbotics will promptly disclose any Invention to you in writing and will deliver and assign (or cause to be assigned) and hereby assigns fully to you all right, title and interest in and to the Inventions. Crowdbotics will reasonably assist you to further evidence, record and perfect such assignments, and to perfect, obtain, maintain, enforce and defend any rights assigned.  Notwithstanding the foregoing, Crowdbotics reserves the right to use and re-use any generalized knowledge it gains arising from the performance of Services in its business without liability to you. </p>
          </div>
        </div>
      </div>
      </section>
    )
}
  
export default TermsOfService;