// Essentials
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import axios from 'axios'; // connect to api
import {
    useHistory
} from 'react-router-dom';

// Form and validations
import { Formik, Form } from "formik";
import * as Yup from 'yup';

// Display http message
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { selectCurrentUser } from 'src/views/store/user/user.selector';
import { useSelector } from 'react-redux';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import {
    MDBCard
} from 'mdb-react-ui-kit';

const url = process.env.REACT_APP_API_URL;



const PurchaseHostGame = ({
    ...props
}) => {

    // Styles
    const outerClasses = classNames(
        'section'
    );

    const useStyles = makeStyles(() => ({
        root: {
            "& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
            marginBottom: '70px'
        },
        divider: {
            background: 'white'
        },
    }));

    const classes = useStyles();

    const [gameId, setgameID] = useState(JSON.parse(sessionStorage.getItem('selectedGame')));

    const [games, setGames] = useState([{}]);
    const getGamesData = async () => {console.log('gameId.gameId ', gameId.gameId)
        await axios.post(`${url}/games/gameData`, { // api only inlcudes the routes parameter as proxy was used to specify the server: localhost:5000 (stated in the last line of codes at package.json)
            gameID: gameId.gameId
        }).then((response) => {
            const [data] = response.data.data.gameData;
            // setGames(data[0])
            setGames(data)
            //console.log(response)
        })
    };

    useEffect(() => {
        //console.log('gameID', JSON.parse(sessionStorage.getItem('selectedGame')));
        setgameID(JSON.parse(sessionStorage.getItem('selectedGame')));
        getGamesData();
    }, []);

    // disallow the selection of past date
    const disablePastDate = () => {
        const today = new Date();
        return today.toISOString().slice(0, 16)
    };

    // yup validation for the form
    const currentValidationSchema = [ // validation in an array, currentValidationSchema[0] refers to validation on the first page and so on
        // validation on the first page (step 0 in programming terms)
        Yup.object().shape({
            quantity: Yup.string().required('Please select a quantity').min(1, 'Please select a quantity above 2'),
            // newQuantity: Yup.number().when('quantity', {
            //     is: (quantity) => quantity == 'Option length',
            //     then: Yup.number().required('Please specify an amount')
            //         .min(10, 'Please select a quantity above 10')
            // }),
            dateTime: Yup.date().required('Please select a date'),
            // email: Yup.string().required('Please specify email').email('Must be a valid email').max(45, 'Email must be at most 45 characters'),
        })
    ];

    const btnStyle = {
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: "10px",
        width: '25%',
        height: '35px',
    };

    const theme = createTheme({
        palette: {
            success: {
                main: '#f6c94e',
                contrastText: '#000000',
            },
            secondary: {
                main: '#fff',
                contrastText: '#000000',
            },
        },
    });

    const history = useHistory();

    const userData = useSelector(selectCurrentUser)

    function handleSubmit(values, actions) {
        submitForm(values, actions);
    };

    async function submitForm(values, actions) {
        await axios.post(`${url}/games/purchaseHostGame`, { // api only inlcudes the routes parameter as proxy was used to specify the server: localhost:5000 (stated in the last line of codes at package.json)
            // email: values.email,
            userID: userData.userID,
            quantity: values.quantity,
            dateTime: values.dateTime,
            gameID: gameId.gameId,
            email: userData.email,
            gameName: games.gameName,
            gameType: games.gameType
        }).then((response) => {
            sessionStorage.setItem('hostGame', JSON.stringify({ gameName: games.gameName, quantity: values.quantity, dateTime: values.dateTime }));
            history.push('/finish');

            // //console.log(JSON.stringify(response.data.data[0]))
            // actions.setSubmitting(false);
            // actions.resetForm();
            // toast.success(`Booking has successfully submitted. JNR Entertainment Pte Ltd will contact you soon.`, {
            //     position: toast.POSITION.TOP_CENTER, // position of the toast message on the screen
            //     autoClose: 4000, // time in milliseconds the toast message will be displayed
            //     backgroundColor: '#f44336', // background color of the toast message
            //     color: '#fff', // color of the text in the toast message
            //     borderRadius: '10px', // border radius of the toast message
            //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', // box shadow of the toast message
            //     style: {
            //         fontSize: '13px',
            //         width: '800px',
            //         height: '85px',
            //         display: 'flex',
            //         right: '70%',
            //     },
            //     zIndex: 9999 // z-index of the toast message

            // });

        }, (err) => {
            // if (err.response.status == 409) {
            toast.error(`Error: ${err.response.data.data}`, {
                position: toast.POSITION.TOP_CENTER, // position of the toast message on the screen
                autoClose: 4000, // time in milliseconds the toast message will be displayed
                backgroundColor: '#f44336', // background color of the toast message
                color: '#fff', // color of the text in the toast message
                borderRadius: '10px', // border radius of the toast message
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', // box shadow of the toast message
                style: {
                    fontSize: '13px',
                    width: '800px',
                    height: '85px',
                    display: 'flex',
                    right: '70%',
                },
                zIndex: 9999 // z-index of the toast message
            });
            actions.setSubmitting(false);
            // }
        })
    };

    return (
        <section
            {...props}
            className={classNames(outerClasses)}>

            <div className='container-sm'>
                <h1 className='pb-32 center-content'>{games.gameName}</h1>
                <p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
                    {games.currency} {games.normalPrice} per pax
                </p> <br />
                {games.discPrice == '-1.00' && games.discCondition == '-1' ? <></> :
                    <p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '18px', marginBottom: '30px' }}>
                        Ticket priced at {games.currency} {games.discPrice}/pax for booking of {games.discCondition} and above
                    </p>}
            </div>

            <Formik
                initialValues={{
                    quantity: '2',
                    dateTime: '',
                    email: ''
                }}
                validationSchema={currentValidationSchema[0]}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, errors, touched, handleChange }) => (
                    <Form>
                        {/* <div className='formContainer'>
                            <label style={{ color: 'white', fontSize: '20px' }}>Email<span style={{ color: "red", padding: '5px' }}>*</span></label>
                            <input
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                className='form-control'
                                placeholder="Please specify email"
                                // style={{ color: "gray", backgroundColor: '#151719' }}
                                style={{ color: "gray", backgroundColor: '#151719', height: "50px" }}
                                autoComplete='off'
                            />
                            {errors.email && touched.email ? (
                                <div className='error-message'>{errors.email} </div>
                            ) : null}
                        </div> */}
                        <div className='formContainer'>
                            <label style={{ color: 'white', fontSize: '20px' }}>Select number of tickets<span style={{ color: "red", padding: '5px' }}>*</span></label>
                            <input
                                type="number"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleChange}
                                className='form-control'
                                placeholder="Please specify number"
                                // style={{ color: "gray", backgroundColor: '#151719' }}
                                style={{ color: "gray", backgroundColor: '#151719', height: "50px" }}
                                autoComplete='off'
                                min="2"
                            />
                            {errors.quantity && touched.quantity ? (
                                <div className='error-message'>{errors.quantity} </div>
                            ) : null}
                        </div>

                        <div className='formContainer'>
                            <label style={{ color: 'white', fontSize: '20px' }}>Select date and time<span style={{ color: "red", padding: '5px' }}>*</span></label>
                            <input
                                type='datetime-local'
                                value={values.dateTime}
                                name='dateTime'
                                min={disablePastDate()}
                                onChange={handleChange}
                                className='form-control'
                                // style={{ color: "grey", backgroundColor: '#151719' }}
                                style={{ color: "gray", backgroundColor: '#151719', height: "50px", fontSize: '18px' }}
                            />
                            {errors.dateTime && touched.dateTime ? (
                                <div className='error-message'>{errors.dateTime}</div>
                            ) : null}

                        </div>

                        <ThemeProvider theme={theme}>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                                <Button
                                    sx={btnStyle}
                                    disabled={isSubmitting}
                                    type='submit'
                                    // color='success'
                                    style={{ marginBottom: '15px' }}
                                    variant="contained"
                                    color='secondary'
                                    size='small'
                                >
                                    Submit
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                            </Box>
                        </ThemeProvider>
                    </Form>
                )}
            </Formik>

        </section>
    );
}

export default PurchaseHostGame;

