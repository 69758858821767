import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation} from 'react-router-dom';
import { clearUserState } from 'src/views/store/user/user.action';


// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';

// project import
import Transitions from 'src/components/elements/Transitions';
import MainCard from 'src/components/elements/MainCard';
import ProfileTab from './ProfileTab';
import { logoutUser } from 'src/views/store/user/user.action';
//import { clearUserState } from 'store/user/user.action';

// assets
import avatar1 from 'src/assets/images/fast.png';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { selectCurrentUser } from 'src/views/store/user/user.selector';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector(selectCurrentUser)
    

    // useEffect(() => {
    //     dispatch(clearUserState());
    //   }, []);

    // const handleLogout = () => {
    //     // logout
    //     try {

    //         //dispatch(logoutUser());
    //         dispatch(clearUserState());
    //         history.push('/');
    //     } catch (error) {
    //         console.log(`error with logout - ${error}`);
    //     }
    // };
    
    const location = useLocation();

    //console.log(location.pathname)

    useEffect(() => {
        if (!userData && location.pathname === '/login') {
            dispatch(clearUserState());
            history.push('/login');
        }
    }, [dispatch, history, userData]);
    

    const handleLogout = () => {
        try {
        dispatch(clearUserState());
        history.push('/')
        // if (location.pathname === '/') {
        //     history.push('/login');
        // } else {
        //     history.push('/');
        // }
        } catch (error) {
        console.log(`error with logout - ${error}`);
        }
    };

    const showLogin = () => { //console.log('userData - ', JSON.stringify(userData))
        if (userData) {
            //console.log(userData)
            return (
                <ButtonBase
                sx={{
                    p: 0.25,
                    borderRadius: 1,
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <UserOutlined />
                    <Typography variant="subtitle1" sx={{ fontSize: '16px', color: 'white' }}>{userData.username}</Typography>

                </Stack>
            </ButtonBase>


            )
        } else {
            return (
                <li>
                    <Link
                        to="/login"
                        className="button button-primary button-wide-mobile button-sm"
                        //onClick={closeMenu}
                      >Login
                    </Link>
                </li>
            );
        }
    };


    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    

    return (
        <Box >
            {showLogin()}
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
                style={{ zIndex: theme.zIndex.modal + 1 }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    width: 50,
                                    minWidth: 150  ,
                                    maxWidth: 290,
                                    //width: 130,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        {open && (
                                            <>
                                                
                                                <TabPanel value={value} index={0} dir={theme.direction} sx={{ borderTop: 1, borderColor: 'divider', width:'100%' }}>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                </TabPanel> 
                                                
                                            </>
                                        )}
                                       </MainCard>

                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Profile;
