import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  BrowserRouter as Router,
  Link,
  useHistory
} from 'react-router-dom';
import LogoHeader from './partials/LogoHeader';

// import { useDispatch } from 'react-redux';
// import { loginUser } from 'src/views/store/user/user.action';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import Profile from './partials/Profile';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);

      
  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => { 
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  const history = useHistory();

  const allPromotions = () => {
    history.push('/allPromo');
  };

  const allRooms = () => {
    history.push('/allRooms');
  };

  const allClients = () => {
    history.push('/allClients');
  };
  const allCertifications = () => {
    history.push('/allCertifications');
  };

  // const auth = localStorage.getItem('user');
  // const history = useHistory();
  // const logout = () => {
  //   localStorage.clear();
  //   history.push('/login')
  // }

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container-xl">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <LogoHeader />
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner" style={{color:'white'}}>
                  <ul className="list-reset header-nav"
                  // className={
                  //   classNames(
                  //     'list-reset text-sm',
                  //     navPosition 
                  //     && `header-nav-${navPosition}`
                  //   )} 
                    >
                    <li>
                      <Link to="/" onClick={closeMenu}>Home</Link>
                    </li>
                   {/* <li>
                      <Link to="/about" onClick={closeMenu}>About</Link>
                    </li>
                    <li>
                      <Link to="/support" onClick={closeMenu}>Support</Link>
                    </li>
                    <li>
                      <Link to="/mainPage" onClick={closeMenu}>Main</Link>
                    </li>
                    <li>
                      <Link to="/test" onClick={closeMenu}>Test</Link>
                    </li>
                    <li>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <>
                            <Link {...bindTrigger(popupState)}>
                              Admin Functions
                            </Link>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem onClick={allPromotions}>All Promotions</MenuItem>
                              <MenuItem onClick={allRooms}>All Rooms</MenuItem>
                              <MenuItem onClick={allClients}>All Clients</MenuItem>
                              <MenuItem onClick={allCertifications}>All Certifications</MenuItem>
                            </Menu>
                           
                          </>
                        )}
                      </PopupState>
                        </li>*/}
                  </ul>
                </div>
              </nav>

              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  {!hideSignin &&
                    <ul
                      className="list-reset header-nav-right"
                    >
                      <li>
                        <Profile/>
                      </li>
                    </ul>}
                </div>
              </nav>

            </>
          }
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
