import React, { useState }from 'react';
import classNames from 'classnames';

const PrivacyPolicy = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

    return (
      <section
      {...props}
      className = {outerClasses}>

      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
              Privacy Policy 
            </h1>
            <br></br>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}>
              JNR PRIVACY POLICY </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}>
              This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 1. What personal information do we collect from the people that visit our blog, website or app? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, credit card information or other details to help you with your experience. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 2. When do we collect information? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> We collect information from you when you register on our site, place an order, fill out a form or enter information on our site. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 3. How do we use your information? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
              <br></br> <span style={{fontWeight:"bold"}}>a.</span> To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.
              <br></br> <span style={{fontWeight:"bold"}}>b.</span> To improve our website in order to better serve you.
              <br></br> <span style={{fontWeight:"bold"}}>c.</span> To allow us to better service you in responding to your customer service requests.
              <br></br> <span style={{fontWeight:"bold"}}>d.</span> To quickly process your transactions.
              <br></br> <span style={{fontWeight:"bold"}}>e.</span> To send periodic emails regarding your order or other products and services. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 4. How do we protect your information? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 5. Do we use 'cookies'? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 6. Third-party disclosure </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety. </p>
              <h5 className="m-0 mb-32" style={{textAlign: 'left'}}> 7. Does our site allow third-party behavioral tracking? </h5>
              <p className="m-0 mb-32" style={{textAlign: 'left'}}> It's also important to note that we allow third-party behavioral tracking. </p>
          </div>
        </div>
      </div>
      </section>
    )
}
  
export default PrivacyPolicy;