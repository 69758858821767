import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Facebook from '../../components/login/Facebook';
import Google from '../../components/login/Google';
import { useHistory } from "react-router-dom"
import classNames from 'classnames';
import axios from 'axios';
import '../../assets/scss/core/elements/auth.scss';
import Box from '@mui/material/Box';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { useDispatch } from 'react-redux';
import { loginUser } from '../store/user/user.action';
import { handleAuthRes } from '../../helper/handleAuthRes';

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  CircularProgress
} from '@mui/material';

const url = process.env.REACT_APP_API_URL;

const Login = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const dispatch = useDispatch();

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const history = useHistory()

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [userName, setUserName] = useState('');

  //loading screen
  const [isLoading, setIsLoading] = useState(false);

  const url = process.env.REACT_APP_API_URL;
  const [csrfToken, setCsrfToken] = useState('');

  //function handleSubmit(values) {
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);

      // Simulate loading time
      setTimeout(() => {
        setIsLoading(false);
      }, 4000); // 2000 milliseconds = 2 seconds

      // console.log("handle submit values.csrfToken", values.csrf);
      let { payload } = await dispatch(loginUser(values));
      // console.log("payload", payload)

      //let { payload } = await loginUser(values);
      handleAuthRes(payload[1], payload[0]);

      if (payload[1] === 200) {
        history.push('/');
        setIsAuthenticated(true);
        //console.log(isAuthenticated);

        //props.onLogin(true)
      }
    } catch (err) {
      // console.log("err", err)
      toast.error(`Opps. An unexpected error have occurred. Please try again later or contact system administrator for more information - ${err}`, {
        position: toast.POSITION.TOP_CENTER,
        // autoClose: 4000, 
        // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
        // style: { 
        // fontSize: '13px',
        // height: '85px',
        // width: '100%',
        // display: 'flex',
        // },      
        // zIndex: 9999 
      });
    }
  };

  useEffect(() => {
    // console.log("isAuthenticated changed:", isAuthenticated);

    // Fetch the CSRF token from your server
    try {
      axios.get(`${url}/gettoken`).then((response) => {
        // console.log('response', response.data.data['csrfToken'])
        setCsrfToken(response.data.data['csrfToken']);
      });
    } catch (err) {
      toast.error(`Opps. An unexpected error have occurred. Please try again later or contact system administrator for more information - ${err}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

  }, [isAuthenticated]);

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const [isActive, setIsActive] = useState(true);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address!')
      .required('Email is required.'),
    password: Yup.string()
      .required("Password is required."),
  })

  return (
    <section
      {...props}
      className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
              Please login to experience the world of an escapist.
            </h1>
            <div className="auth-wrapper" style={{ marginTop: "25px", paddingTop: "40px" }}>
              <div className="auth-background-holder"></div>
              <div className="auth-background-mask"></div>
              <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                <div className="d-flex flex-column align-content-end">
                  {/*<div className="auth-body mx-auto">*/}
                  <div>
                    {/*<Facebook />
                    <br></br>
                    <p>Or, login with your email</p>*/}
                    <div className="auth-form-container text-start">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: '',
                          password: '',
                          csrf: csrfToken,
                        }}
                        validationSchema={formValidationSchema}
                        onSubmit={handleSubmit}>
                        {({ isSubmitting, values, errors, handleChange, handleBlur }) => (
                          <Form>
                            <input type="hidden" id="csrf" value={values.csrf} onChange={handleChange} />
                            {/*<div className="input-group">*/}
                            <div>
                              <label for="email" style={{ display: 'block' }}>
                                Email
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="email"
                                placeholder="abc@outlook.com"
                                type="text"
                                autoComplete='off'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form-control'
                                name="email"
                                //style={{ width: '400px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                style={{ height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                              />
                              {errors.email && <div className="input-feedback">{errors.email}</div>}
                            </div>

                            {/*<div className="input-group">*/}
                            <div>
                              <label for="password" style={{ display: 'block', marginTop: '20px' }}>
                                Password
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                id="password"
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                autoComplete={false}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form-control'
                                name="password"
                                //style={{ width: '359px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                style={{ height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                              />
                              {/*<button
                                type="button"
                                className="btn1 btn1-outline-primary btn1-sm"
                                onClick={(e) => togglePassword(e)}
                                style={{ height: '44px' }}
                              >
                                <i
                                  className={
                                    showPassword ? "far fa-eye" : "far fa-eye-slash"
                                  }
                                ></i>{" "}
                                </button>*/}
                              {errors.password && <div className="input-feedback">{errors.password}</div>}
                            </div>
                            <div className="extra mt-3 row justify-content-between">
                              <div className="col-4">
                                {/*<div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember"
                                    checked={remember}
                                    onChange={(e) => setRemember(e.currentTarget.checked)}
                                  />
                                  <label className="form-check-label" htmlFor="remember">
                                    Remember me
                                  </label>
                              </div>*/}
                              </div>
                              <div className="col-8">
                                <div className="forgot-password text-end">
                                  <Link to="/forgot-password">Forgot password?</Link>
                                </div>
                              </div>
                            </div>
                            <button type="submit"
                              //style={{ marginLeft: '-3px', width: '403px', height: '50px', marginTop: '20px', textAlign: 'center', fontSize: '0.875rem', backgroundColor: '#6163ff', color: 'white' }}
                              style={{ width: '100%', height: '50px', marginTop: '20px', textAlign: 'center', fontSize: '0.875rem', backgroundColor: '#fff', color: 'black' }}
                            >
                              Login
                            </button>
                            <div style={{ marginTop: '20px', width: '100%' }}>
                              <Link>
                                <Google />
                              </Link>
                              {isLoading && (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    zIndex: 9999,
                                  }}
                                >
                                  <CircularProgress color="inherit" />
                                </Box>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                      <div className="auth-option text-center pt-2" style={{ marginTop: '10px' }}>
                        Don't have an account?{" "}
                        <Link className="text-link" to="/register">
                          Register{" "}
                        </Link>
                      </div>
                      {isLoading && (
                        <Box
                          sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 9999,
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};


export default Login;