import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string'; // parse query string
import { selectCurrentUser } from '../store/user/user.selector';
import axios from 'axios'; // connect to api
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

//css
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';



const url = process.env.REACT_APP_API_URL;


const Payment = ({ ...properties }) => {
	const { staticContext, ...props } = properties;
	const root = this;
	// Styles
	const outerClasses = classNames('section');

	const btnStyle = {
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: "10px",
        width: '25%',
        height: '35px',
    };
	
	const theme = createTheme({
        palette: {
            success: {
                main: '#f6c94e',
                contrastText: '#fff',
            },
            secondary: {
                main: '#fff',
        contrastText: '#000000',
            },
        },
    });

	const classes = makeStyles(() => ({
		root: {
			"& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
			marginBottom: '70px'
		},
		divider: {
			background: 'white'
		},
	}))();

	const [invoiceId, setInvoiceId] = useState("");
	//const [voucherCode, setvoucherCode]     = useState("");
	const [bookingDate, setBookingDate] = useState(new Date(0));
	const [gameurl, setGameURL] = useState("");

	const Location = useLocation();
	const params = useParams();
	const queries = queryString.parse(Location.search);
	const session_id = queries.session_id;
	const auth = useSelector(selectCurrentUser);

	async function api_payment_status(session_id) {
		// console.log("Payment: Status of ", session_id);
		try {
			const response = await axios.get(`${url}/payment/status?session_id=${session_id}`);
			// console.log(response);
			// console.log("id_invoice", response.data.data.id_invoice)
			const meta = response.data.data.meta;
			setGameURL(response.data.data.meta.gameURL)
			// console.log('meta payment', meta)
			const invoiceid = response.data.data.id_invoice;
			setInvoiceId(invoiceid);
			// console.log(invoiceId)
			setBookingDate(new Date(meta.datetime));
			return await api_game_booking(meta, response.data.data.id_invoice);
		}
		catch (error) {
			console.error("Payment: Failed to get payment status", error);
			toast.error(`Error: ${error.message}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 4000,
				boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
				style: {
					fontSize: '13px',
					height: '85px',
					width: '100%',
					display: 'flex',
				},
				zIndex: 9999
			});
		}
	}

	async function api_game_booking(meta, id_invoice) {
		// console.log("Booking: Creating booking", meta);
		// console.log("Invoice ID:", id_invoice)
		try {
			// const validateInvoice = await axios.post(`${url}/games/validateInvoice`, { ...meta, id_invoice });
			const validateInvoice = await axios.post(`${url}/games/validateInvoice`, { ...meta, id_invoice });
			// console.log(validateInvoice)
			try {
				if (validateInvoice.status === 200) {
					const response = await axios.post(`${url}/games/purchase`, { ...meta, id_invoice, voucherCode });
					// console.log(id_invoice, voucherCode)
					// console.log(`response`, response)
					if (response.status == 200) {
						// console.log(meta)
						// console.log(response)
						setvoucherCode(response.data.data.voucherCode)
						localStorage.setItem('voucherCode', response.data.data.voucherCode); // Store in local storage
						// console.log("Booking: Success");
						if (response.data.data.emailStatus === 211) {
							toast.success(`A confirmation email has sent to you. If you do not see the email in a few minutes, check your Junk folder or Spam folder.`, {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 4000,
								borderRadius: '10px',
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
								style: {
									fontSize: '13px',
									width: '100%',
									display: 'flex',
								},
								zIndex: 9999
							})
						} else if (response.data.data.emailStatus === 411) {
							toast.error(`Failed to send confirmation email. Please check your booking in 'My Booking'.`, {
								position: toast.POSITION.TOP_CENTER, 
								autoClose: 4000, 
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
								style: { 
								fontSize: '13px',
								height: '85px',
								width: '100%',
								display: 'flex',
								},      
								zIndex: 9999 
							  });
						}
					} else if (response.status == 201) {
						setvoucherCode(response.data.data.voucherCode)
						localStorage.setItem('voucherCode', response.data.data.voucherCode); // Store in local storage
						console.log("Booking URGENT")
						if (response.data.data.emailStatus === 211) {
							toast.success(`A confirmation email has sent to you. If you do not see the email in a few minutes, check your Junk folder or Spam folder.`, {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 4000,
								borderRadius: '10px',
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
								style: {
									fontSize: '13px',
									width: '100%',
									display: 'flex',
								},
								zIndex: 9999
							})
						} else if (response.data.data.emailStatus === 411) {
							toast.error(`Failed to send confirmation email. Please check your booking in 'My Booking'.`, {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 4000,
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
								style: {
									fontSize: '13px',
									width: '100%',
									display: 'flex',
								},
								zIndex: 9999
							})
						}
					} else if (response.status == 406){
						setvoucherCode(response.data.data.voucherCode)
						localStorage.setItem('voucherCode', response.data.data.voucherCode); // Store in local storage
						console.log("Booking URGENT")
						if (response.data.data.emailStatus === 211) {
							toast.success(`A confirmation email has sent to you. If you do not see the email in a few minutes, check your Junk folder or Spam folder.`, {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 4000,
								borderRadius: '10px',
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
								style: {
									fontSize: '13px',
									width: '100%',
									display: 'flex',
								},
								zIndex: 9999
							})
						} else if (response.data.data.emailStatus === 411) {
							toast.error(`Failed to send confirmation email. Please check your booking in 'My Booking'.`, {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 4000,
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
								style: {
									fontSize: '13px',
									width: '100%',
									display: 'flex',
								},
								zIndex: 9999
							})
						}					}
				} else if (validateInvoice.status === 202) {
					console.log("double receipts")

				}
			} catch (error) {
				console.error("Booking: Failed to create booking");
				//return error message
				if (error.response && error.response.status === 502) {
					toast.error('502 Bad Gateway Error: Please try again later.', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 4000,
						boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
						style: {
							fontSize: '13px',
							width: '100%',
							display: 'flex',
						},
						zIndex: 9999
					})
		  		}
				
			}
		}
		catch (error) {
			console.error("Booking: Failed to create booking");
			//return error message
			if (error.response && error.response.status === 502) {
				toast.error('502 Bad Gateway Error: Please try again later.', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 4000,
					boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
					style: {
						fontSize: '13px',
						width: '100%',
						display: 'flex',
					},
					zIndex: 9999
				})
			}
			
			  throw error;
		}
	}
	//--------------------------------------------
	console.assert(session_id != null, "Payment: session_id cannot be null");
	useEffect(api_payment_status.bind(this, session_id), []);
	const [voucherCode, setvoucherCode] = useState(() => {
		// Retrieve voucherCode from local storage if it exists
		const storedVoucherCode = localStorage.getItem('voucherCode');
		return storedVoucherCode ? storedVoucherCode : '';
	});

	useEffect(() => {
		// Save voucherCode to local storage whenever it changes
		localStorage.setItem('voucherCode', voucherCode);
	}, [voucherCode]);
	//--------------------------------------------
	return (
		<section
			{...props}
			className={classNames(outerClasses, '')}>
			<div className='center-content'>
				<Typography sx={{ mt: 6, mb: 1, fontSize: 30 }}>
					Your booking is confirmed. Thank you!
				</Typography>
			</div>
			<br></br>
			<div className='container-xs'>
				<MDBCard className='checkoutCard'>
					<h4 className='center-content'>Invoice ID: {invoiceId}</h4>
					<Divider classes={{ root: classes.divider }} />
					<div className='checkout-content'>
						<div className='col-lg-12'>
							<span style={{ color: 'white' }}>Voucher Code: <b>{voucherCode}</b></span><br /><p></p>
							{gameurl !== undefined && gameurl !== "" ? (
								<span style={{ color: 'white' }}>Game URL: <b>{gameurl}</b><br /><p></p></span>
							) : null}
							<span style={{ color: 'white' }}>See you on <b>{bookingDate.toDateString()} {bookingDate.toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' })}</b></span><br /><br />
						</div>
					</div>
				</MDBCard>
				<ThemeProvider theme={theme}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                        <Button
                            sx={btnStyle}
                            type='submit'
                            style={{ marginBottom: '15px' }}
                            variant="contained"
                            color='success'
                            size='small'
                            Link component={Link} to={`/`}
                        >
                            Back to Home
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                    </Box>
                </ThemeProvider>
			</div>
		</section>
	);
}

export default Payment;