import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.min.css';
import Image from '../../components/elements/Image';
import { Box } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const url = process.env.REACT_APP_API_URL;

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, color: 'Black', height: "60px", width: "30px" }}
          onClick={onClick}
          onKeyDown={(e) => e.key === 'Enter' && onClick()}  // Add keyboard interaction
          role="button"  // Make it accessible as a button
          tabIndex={0}    // Make it focusable
      >
          <ArrowForwardIosIcon size="2x" />
      </div>
  );
}


function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, color: 'Black', height: "60px", width: "30px", justifyContent: 'flex-start' }}
          onClick={onClick}
          onKeyDown={(e) => e.key === 'Enter' && onClick()}  // Add keyboard interaction
          role="button"  // Make it accessible as a button
          tabIndex={0}    // Make it focusable
      >
          <ArrowBackIosIcon size="2x" />
      </div>
  );
}


export const Certifications = () => {
    const [certifications, setAllCertifications] = useState([]);
    
    const getAllCertifications = async () => {
        await axios.post(`${url}/allCertifications`).then((response) => {
            setAllCertifications(response.data.data);
        });
    };

    useEffect(() => {
        getAllCertifications();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: (dots) => (
            <ul style={{ margin: "0px" }}> {dots} </ul>  // Simplified dot rendering
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
            <section id="certifications">
                <div className="container-xl">
                    <div className="contentWrapper">
                        <div className="title">
                            <h3>Bizsafe Certified, Award Winning Company</h3>
                        </div>
                        <div id="certificationSlider">
                            <Slider {...settings}>
                                {certifications.map((certification, index) => (
                                    <Box key={index}>   
                                        <div className="borderLeft">
                                            <Image
                                                className="image"
                                                src={certification.images[0]}
                                            />
                                        </div>
                                    </Box>
                                ))} 
                            </Slider> 
                        </div>
                    </div>
                </div>       
            </section>
        </>
    );
}

export default Certifications;
