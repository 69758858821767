// Essentials
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom"
import classNames from 'classnames';
import axios from 'axios';
import moment from 'moment'; // format datetime
import { selectCurrentUser } from 'src/views/store/user/user.selector';
import { useSelector } from 'react-redux';



// Material UI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const url = process.env.REACT_APP_API_URL;

//timezone
// global.moment = require('moment-timezone');
// moment().locale("id");
// moment().tz("Asia/Jakarta").format(); // should use UTC default, if your apps plains to global location

const AllBookings = ({
  ...props
}) => {

  const history = useHistory();
  const userData = useSelector(selectCurrentUser)

  // Styles
  const outerClasses = classNames(
    'section'
  );

  // override default colour theme for material ui tables component
  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      success: {
        main: '#fff',
        contrastText: '#000',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000',
      },
      yellow: {
        main: '#f6c94e',
        contrastText: '#000',

      },
      red: {
        main: '#ad2518',
        contrastText: '#fff',
      },
    },

  });

  const styles = {
    viewBtn: {
      width: '60px',
      height: '27px',
      fontSize: '13px',
      marginLeft: '30px',
      '@media (max-width: 600px)': {
        width: '1px',
        height: '20px',
        fontSize: '10px',
        marginLeft: '20px'
      },
    },
    editBtn: {
      width: '60px',
      height: '27px',
      fontSize: '13px',
      marginTop: '15px',
      marginLeft: '30px',
      '@media (max-width: 600px)': {
        width: '1px',
        height: '20px',
        fontSize: '10px',
        marginLeft: '20px'
      },
    },

  };

  // Called Apis
  //   const voucherID = 1

  const [value, setValue] = React.useState('1');
  const [valuePast, setValuePast] = React.useState('1');

  //Straight through game
  const [bookings, setAllBookings] = useState([]);
  const [expiredbookings, setExpiredBookings] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  //Hosted games
  const [hostedGameExpiredbookings, setHostedGameExpiredbookings] = useState([]);
  const [hostedGameBookings, setHostedGameBookings] = useState([]);

  const handleMenuChange = (event) => {
    setSelectedMenuItem(event.target.value);
  };

  const getAllBookings = async () => {
    await axios.post(`${url}/user/viewBookings`, { userID: userData.userID }).then((response) => {
      //console.log(response)
      //console.log(JSON.stringify(response.data.data[0]))
      setAllBookings(response.data.data[0])
      setExpiredBookings(response.data.data[1])

      setHostedGameBookings(response.data.data[2])
      setHostedGameExpiredbookings(response.data.data[3])
    })
  };

  useEffect(() => {
    getAllBookings();
  }, []);


  const navigateSelectedBooking = (voucherID) => {
    sessionStorage.setItem('selectedBookView', JSON.stringify({ voucherID: voucherID }));

    history.push('/viewbooking')

  }

  const navigateSelectedBookingHost = (hostedGameClientID) => {
    sessionStorage.setItem('selectedBookViewHost', JSON.stringify({ hostedGameClientID: hostedGameClientID }));

    history.push('/viewbookinghosted')

  }


  const navigateUpdateBooking = (voucherID) => {
    sessionStorage.setItem('selectedBook', JSON.stringify({ voucherID: voucherID }));

    history.push('/updatebooking')

  }

  const navigateUpdateBookingHost = (hostedGameClientID) => {
    sessionStorage.setItem('selectedBookHost', JSON.stringify({ hostedGameClientID: hostedGameClientID }));

    history.push('/updatebookinghost')

  }

  // Pagination Current/ongoing straight through games
  const [pgCurrentST, setpgCurrentST] = React.useState(0);
  const [rpgCurrentST, setrpgCurrentST] = React.useState(3);
  function handleChangePageCurrentST(event, newpage) {
    setpgCurrentST(newpage);
  }

  function handleChangeRowsPerPageCurrentST(event) {
    setrpgCurrentST(parseInt(event.target.value, 10));
    setpgCurrentST(0);
  }

  // Pagination Current hosted games
  const [pgCurrentHG, setpgCurrentHG] = useState(0);
  const [rpgCurrentHG, setrpgCurrentHG] = useState(3);
  function handleChangePageCurrentHG(event, newpageCurrHG) {
    setpgCurrentHG(newpageCurrHG);
  }

  function handleChangeRowsPerPageCurrentHG(event) {
    setrpgCurrentHG(parseInt(event.target.value, 10));
    setpgCurrentHG(0);
  }

  // Pagination Past straight through games
  const [pgPastST, setpgPastST] = React.useState(0);
  const [rpgPastST, setrpgPastST] = React.useState(3);
  function handleChangePagePastST(event, newpage) {
    setpgPastST(newpage);
  }

  function handleChangeRowsPerPagePastST(event) {
    setrpgPastST(parseInt(event.target.value, 10));
    setpgPastST(0);
  }

  // Pagination Past hosted games
  const [pgPastHG, setpgPastHG] = useState(0);
  const [rpgPastHG, setrpgPastHG] = useState(3);
  function handleChangePagePastHG(event, newpagePastHG) {
    setpgPastHG(newpagePastHG);
  }

  function handleChangeRowsPerPagePastHG(event) {
    setrpgPastHG(parseInt(event.target.value, 10));
    setpgPastHG(0);
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabPast = (event, newValue) => {
    setValuePast(newValue);
  };

  return (
    <section
      {...props}
      className={classNames(outerClasses)}>

      <div className='container'>

        <div style={{ flex: '1', padding: '20px' }}>

          <h2 style={{ color: "white" }}>My Bookings</h2>
          <div style={{ width: '200px', padding: '20px', marginLeft: '-15px', marginTop: '-10px' }}>
            <select style={{ width: '100%', fontSize: '15px' }} value={selectedMenuItem} onChange={handleMenuChange}>
              <option value="menu-item-1">Upcoming</option>
              <option value="menu-item-2">Expired/Past</option>
            </select>
          </div>

          {selectedMenuItem === 'menu-item-1' || selectedMenuItem === '' ? (

            <ThemeProvider theme={darkTheme}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', borderColor: '#70140c' }}>
                    <TabList onChange={handleChangeTab} aria-label="tabs"
                      sx={{
                        // ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
                        ".MuiTab-textColorPrimary.Mui-selected, .MuiTab-textColorPrimary": {
                          color: 'white',
                        },
                        ".MuiTabs-indicator": {
                          backgroundColor: '#f6c94e',
                        },
                      }}>
                      <Tab label="Straight Through" value="1" />
                      <Tab label="Hosted" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{
                    borderTop: 1, borderColor: 'divider', borderColor: '#70140c',
                    // ".css-11xur9t-MuiPaper-root-MuiTableContainer-root": {
                      ".MuiTableContainer-root":{
                      backgroundColor: '#33363A',
                    }
                  }}>

                    <TableContainer component={Paper}>
                      <Table sx={{
                        minWidth: 990, backgroundColor: '#33363A',
                        // ".css-1ndpvdd-MuiTableCell-root, .css-1yhpg23-MuiTableCell-root": {
                          ".MuiTableCell-head, .MuiTableCell-root":{
                          color: '#9CA9B3',
                        },

                      }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="10%" ></TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Voucher Code</TableCell>
                            <TableCell align="center">Game</TableCell>
                            <TableCell align="center">Game Date & Time</TableCell>
                            <TableCell align="center">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rpgCurrentST > 0
                            ? bookings.slice(pgCurrentST * rpgCurrentST, pgCurrentST * rpgCurrentST + rpgCurrentST)
                            : bookings)
                            .map((booking, index) => (
                              <TableRow
                                key={booking.voucherID}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="center">
                                  <Button variant='contained' onClick={() => navigateSelectedBooking(booking.voucherID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
                                    View
                                  </Button>

                                  <br />
                                  <Button variant='contained' onClick={() => navigateUpdateBooking(booking.voucherID)} size='small' color='yellow' sx={styles.editBtn}>
                                    Edit
                                  </Button>
                                </TableCell>
                                <TableCell align="center">{booking.gameType}</TableCell>
                                <TableCell align="center">{booking.voucherCode}</TableCell>
                                <TableCell align="center">{booking.gameName}</TableCell>
                                <TableCell align="center">{moment.utc(booking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
                                <TableCell align="center">{booking.currency} {booking.netAmt}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[3, 10, 25]}
                      component="div"
                      count={bookings.length}
                      rowsPerPage={rpgCurrentST}
                      page={pgCurrentST}
                      onPageChange={handleChangePageCurrentST}
                      onRowsPerPageChange={handleChangeRowsPerPageCurrentST}
                      sx={{
                        backgroundColor: '#33363A',
                        ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
                          marginTop: '-21px',
                        },
                        color: '#9CA9B3',
                        ".MuiSvgIcon-root-MuiSelect-icon, .MuiTablePagination-selectIcon": {
                          color: '#9CA9B3',
                        }
                      }}
                    />
                  </TabPanel>
                  <TabPanel value="2" sx={{
                    borderTop: 1, borderColor: 'divider', borderColor: '#70140c',
                    ".MuiTableContainer-root":{
                      backgroundColor: '#33363A',
                    }
                  }}>
                    <TableContainer component={Paper}>
                      <Table sx={{
                        minWidth: 990, 
                        ".MuiTableCell-head, .MuiTableCell-root":{
                          color: '#9CA9B3',
                        },
                      }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="10%"></TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Game</TableCell>
                            <TableCell align="center">Game Date & Time</TableCell>
                            <TableCell align="center">No. of Players</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rpgCurrentHG > 0
                            ? hostedGameBookings.slice(pgCurrentHG * rpgCurrentHG, pgCurrentHG * rpgCurrentHG + rpgCurrentHG)
                            : hostedGameBookings)
                            .map((hostedGameBooking, index) => (
                              <TableRow
                                key={hostedGameBooking.hostedGameClientID}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="center">
                                  <Button variant='contained' onClick={() => navigateSelectedBookingHost(hostedGameBooking.hostedGameClientID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
                                    View
                                  </Button>
                                  <br />
                                  <Button variant='contained' onClick={() => navigateUpdateBookingHost(hostedGameBooking.hostedGameClientID)} size='small' color='yellow' sx={styles.editBtn}>
                                    Edit
                                  </Button>
                                </TableCell>

                                <TableCell align="center">{hostedGameBooking.gameType}</TableCell>
                                <TableCell align="center">{hostedGameBooking.gameName}</TableCell>
                                <TableCell align="center">{moment.utc(hostedGameBooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
                                <TableCell align="center">{hostedGameBooking.noOfPlayer}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[3, 10, 25]}
                      component="div"
                      count={hostedGameBookings.length}
                      rowsPerPage={rpgCurrentHG}
                      page={pgCurrentHG}
                      onPageChange={handleChangePageCurrentHG}
                      onRowsPerPageChange={handleChangeRowsPerPageCurrentHG}
                      sx={{
                        backgroundColor: '#33363A',
                        ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
                          marginTop: '-21px',
                        },
                        color: '#9CA9B3',
                        ".MuiSvgIcon-root-MuiSelect-icon, .MuiTablePagination-selectIcon": {
                          color: '#9CA9B3',
                        }
                      }}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </ThemeProvider>


            // <ThemeProvider theme={darkTheme}>
            //   <TableContainer component={Paper}>
            //     <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            //       <TableHead>
            //         <TableRow>
            //           <TableCell align="center" width="10%" ></TableCell>
            //           <TableCell align="center">Type</TableCell>
            //           <TableCell align="center">Voucher Code</TableCell>
            //           <TableCell align="center">Game</TableCell>
            //           <TableCell align="center">Game Date & Time</TableCell>
            //           <TableCell align="center">Total</TableCell>
            //         </TableRow>
            //       </TableHead>
            //       <TableBody> 
            //         {(rpgCurrentST > 0
            //           ? bookings.slice(pgCurrentST * rpgCurrentST, pgCurrentST * rpgCurrentST + rpgCurrentST)
            //           : bookings)
            //           .map((booking, index) => (
            //             <TableRow
            //               key={booking.voucherID}
            //               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //             >
            //               <TableCell align="center">
            //                 <Button variant='contained' onClick={() => navigateSelectedBooking(booking.voucherID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
            //                   View
            //                 </Button>

            //                 <br />
            //                 <Button variant='contained' onClick={() => navigateUpdateBooking(booking.voucherID)} size='small' color='yellow' sx={styles.editBtn}>
            //                   Edit
            //                 </Button>
            //               </TableCell>
            //               <TableCell align="center">{booking.gameType}</TableCell>
            //               <TableCell align="center">{booking.voucherCode}</TableCell>
            //               <TableCell align="center">{booking.gameName}</TableCell>
            //               <TableCell align="center">{moment.utc(booking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
            //               <TableCell align="center">{booking.currency} {booking.netAmt}</TableCell>
            //             </TableRow>
            //           ))}
            //       </TableBody>
            //     </Table>
            //   </TableContainer>
            //   <TablePagination
            //     rowsPerPageOptions={[3, 10, 25]}
            //     component="div"
            //     count={bookings.length}
            //     rowsPerPage={rpgCurrentST}
            //     page={pgCurrentST}
            //     onPageChange={handleChangePageCurrentST}
            //     onRowsPerPageChange={handleChangeRowsPerPageCurrentST}
            //     sx={{
            //       backgroundColor: 'white',
            //       ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
            //         marginTop: '-21px',
            //     },
            //     }}
            //   />
            //   <br />
            //   <TableContainer component={Paper}>
            //     <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            //       <TableHead>
            //         <TableRow>
            //           <TableCell align="center" width="10%"></TableCell>
            //           <TableCell align="center">Type</TableCell>
            //           <TableCell align="center">Game</TableCell>
            //           <TableCell align="center">Game Date & Time</TableCell>
            //           <TableCell align="center">No. of Players</TableCell>
            //         </TableRow>
            //       </TableHead>
            //       <TableBody>
            //         {(rpgCurrentHG > 0
            //           ? hostedGameBookings.slice(pgCurrentHG * rpgCurrentHG, pgCurrentHG * rpgCurrentHG + rpgCurrentHG)
            //           : hostedGameBookings)
            //         .map((hostedGameBooking, index) => (
            //           <TableRow
            //             key={hostedGameBooking.hostedGameClientID}
            //             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //           >
            //             <TableCell align="center">
            //               <Button variant='contained' onClick={() => navigateSelectedBookingHost(hostedGameBooking.hostedGameClientID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
            //                 View
            //               </Button>
            //               <br />
            //               <Button variant='contained' onClick={() => navigateUpdateBookingHost(hostedGameBooking.hostedGameClientID)} size='small' color='yellow' sx={styles.editBtn}>
            //                 Edit
            //               </Button>
            //             </TableCell>

            //             <TableCell align="center">{hostedGameBooking.gameType}</TableCell>
            //             <TableCell align="center">{hostedGameBooking.gameName}</TableCell>
            //             <TableCell align="center">{moment.utc(hostedGameBooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
            //             <TableCell align="center">{hostedGameBooking.noOfPlayer}</TableCell>
            //           </TableRow>
            //         ))}
            //       </TableBody>
            //     </Table>
            //   </TableContainer>
            //   <TablePagination
            //     rowsPerPageOptions={[3, 10, 25]}
            //     component="div"
            //     count={hostedGameBookings.length}
            //     rowsPerPage={rpgCurrentHG}
            //     page={pgCurrentHG}
            //     onPageChange={handleChangePageCurrentHG}
            //     onRowsPerPageChange={handleChangeRowsPerPageCurrentHG}
            //     sx={{
            //       backgroundColor: 'white',
            //       ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
            //         marginTop: '-21px',
            //     },
            //     }}
            //   />
            // </ThemeProvider>
          ) : (

            <ThemeProvider theme={darkTheme}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valuePast}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', borderColor: '#70140c' }}>
                    <TabList onChange={handleChangeTabPast} aria-label="tabs"
                      sx={{
                        // ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
                        ".MuiTab-textColorPrimary.Mui-selected, .MuiTab-textColorPrimary": {
                          color: 'white',
                        },
                        ".MuiTabs-indicator": {
                          backgroundColor: '#f6c94e',
                        },
                      }}>
                      <Tab label="Straight Through" value="1" />
                      <Tab label="Hosted" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{
                    borderTop: 1, borderColor: '#70140c',
                    ".MuiTableContainer-root":{
                      backgroundColor: '#33363A',
                    }
                    
                  }}>
                    <TableContainer component={Paper}>
                      <Table sx={{
                        minWidth: 990, backgroundColor: '#33363A',
                        ".MuiTableCell-head, .MuiTableCell-root":{
                          color: '#9CA9B3',
                        },
                      }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="10%"></TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Voucher Code</TableCell>
                            <TableCell align="center">Game</TableCell>
                            <TableCell align="center">Game Date</TableCell>
                            <TableCell align="center">Total</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            (rpgPastST > 0
                              ? expiredbookings.slice(pgPastST * rpgPastST, pgPastST * rpgPastST + rpgPastST)
                              : expiredbookings)
                              .map((expiredbooking, index) => (
                                <TableRow
                                  key={expiredbooking.voucherID}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell align="center">
                                    <Button variant='contained' onClick={() => navigateSelectedBooking(expiredbooking.voucherID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
                                      View
                                    </Button>
                                  </TableCell>

                                  <TableCell align="center">{expiredbooking.gameType}</TableCell>
                                  <TableCell align="center">{expiredbooking.voucherCode}</TableCell>
                                  <TableCell align="center">{expiredbooking.gameName}</TableCell>
                                  <TableCell align="center">{moment.utc(expiredbooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
                                  <TableCell align="center">{expiredbooking.currency} {expiredbooking.netAmt}</TableCell>

                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[3, 10, 25]}
                      component="div"
                      count={expiredbookings.length}
                      rowsPerPage={rpgPastST}
                      page={pgPastST}
                      onPageChange={handleChangePagePastST}
                      onRowsPerPageChange={handleChangeRowsPerPagePastST}
                      sx={{
                        backgroundColor: '#33363A',
                        ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
                          marginTop: '-21px',
                        },
                        color: '#9CA9B3',
                        ".MuiSvgIcon-root-MuiSelect-icon, .MuiTablePagination-selectIcon": {
                          color: '#9CA9B3',
                        }
                      }}
                    />

                  </TabPanel>
                  <TabPanel value="2" sx={{
                    borderTop: 1, borderColor: 'divider', borderColor: '#70140c',
                    ".MuiTableContainer-root":{
                      backgroundColor: '#33363A',
                    }
                  }}>
                    <TableContainer component={Paper}>
                      <Table sx={{
                        minWidth: 990, backgroundColor: '#33363A',
                        ".MuiTableCell-head, .MuiTableCell-root":{
                          color: '#9CA9B3',
                        },
                      }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="10%"></TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Game</TableCell>
                            <TableCell align="center">Game Date & Time</TableCell>
                            <TableCell align="center">No. of Players</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            (rpgPastHG > 0
                              ? hostedGameExpiredbookings.slice(pgPastHG * rpgPastHG, pgPastHG * rpgPastHG + rpgPastHG)
                              : hostedGameExpiredbookings)
                              .map((hostedGameExpiredbooking, index) => (
                                <TableRow
                                  key={hostedGameExpiredbooking.hostedGameClientID}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell align="center">
                                    <Button variant='contained' onClick={() => navigateSelectedBookingHost(hostedGameExpiredbooking.hostedGameClientID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
                                      View
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center">{hostedGameExpiredbooking.gameType}</TableCell>
                                  <TableCell align="center">{hostedGameExpiredbooking.gameName}</TableCell>
                                  <TableCell align="center">{moment.utc(hostedGameExpiredbooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
                                  <TableCell align="center">{hostedGameExpiredbooking.noOfPlayer}</TableCell>

                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[3, 10, 25]}
                      component="div"
                      count={hostedGameExpiredbookings.length}
                      rowsPerPage={rpgPastHG}
                      page={pgPastHG}
                      onPageChange={handleChangePagePastHG}
                      onRowsPerPageChange={handleChangeRowsPerPagePastHG}
                      sx={{
                        backgroundColor: '#33363A',
                        ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
                          marginTop: '-21px',
                        },
                        color: '#9CA9B3',
                        ".MuiSvgIcon-root-MuiSelect-icon, .MuiTablePagination-selectIcon": {
                          color: '#9CA9B3',
                        }
                      }}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </ThemeProvider>

            // <ThemeProvider theme={darkTheme}>
            //   <TableContainer component={Paper}>
            //     <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            //       <TableHead>
            //         <TableRow>
            //           <TableCell align="center" width="10%"></TableCell>
            //           <TableCell align="center">Type</TableCell>
            //           <TableCell align="center">Voucher Code</TableCell>
            //           <TableCell align="center">Game</TableCell>
            //           <TableCell align="center">Game Date</TableCell>
            //           <TableCell align="center">Total</TableCell>

            //         </TableRow>
            //       </TableHead>
            //       <TableBody>
            //         {
            //           (rpgPastST > 0
            //             ? expiredbookings.slice(pgPastST * rpgPastST, pgPastST * rpgPastST + rpgPastST)
            //             : expiredbookings)
            //             .map((expiredbooking, index) => (
            //               <TableRow
            //                 key={expiredbooking.voucherID}
            //                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //               >
            //                 <TableCell align="center">
            //                   <Button variant='contained' onClick={() => navigateSelectedBooking(expiredbooking.voucherID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
            //                     View
            //                   </Button>
            //                 </TableCell>

            //                 <TableCell align="center">{expiredbooking.gameType}</TableCell>
            //                 <TableCell align="center">{expiredbooking.voucherCode}</TableCell>
            //                 <TableCell align="center">{expiredbooking.gameName}</TableCell>
            //                 <TableCell align="center">{moment.utc(expiredbooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
            //                 <TableCell align="center">{expiredbooking.currency} {expiredbooking.netAmt}</TableCell>

            //               </TableRow>
            //             ))}
            //       </TableBody>
            //     </Table>
            //   </TableContainer>
            //   <TablePagination
            //     rowsPerPageOptions={[3, 10, 25]}
            //     component="div"
            //     count={expiredbookings.length}
            //     rowsPerPage={rpgPastST}
            //     page={pgPastST}
            //     onPageChange={handleChangePagePastST}
            //     onRowsPerPageChange={handleChangeRowsPerPagePastST}
            //     sx={{
            //       backgroundColor: 'white',
            //       ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
            //         marginTop: '-21px',
            //       },
            //     }}
            //   />
            //   <br />
            //   <TableContainer component={Paper}>
            //     <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            //       <TableHead>
            //         <TableRow>
            //           <TableCell align="center" width="10%"></TableCell>
            //           <TableCell align="center">Type</TableCell>
            //           <TableCell align="center">Game</TableCell>
            //           <TableCell align="center">Game Date & Time</TableCell>
            //           <TableCell align="center">No. of Players</TableCell>
            //         </TableRow>
            //       </TableHead>
            //       <TableBody>
            //         {
            //           (rpgPastHG > 0
            //             ? hostedGameExpiredbookings.slice(pgPastHG * rpgPastHG, pgPastHG * rpgPastHG + rpgPastHG)
            //             : hostedGameExpiredbookings)
            //             .map((hostedGameExpiredbooking, index) => (
            //               <TableRow
            //                 key={hostedGameExpiredbooking.hostedGameClientID}
            //                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //               >
            //                 <TableCell align="center">
            //                   <Button variant='contained' onClick={() => navigateSelectedBookingHost(hostedGameExpiredbooking.hostedGameClientID)} className="button button-primary" style={{ color: 'black', backgroundColor: 'white', border: '1px solid black' }} sx={styles.viewBtn}>
            //                     View
            //                   </Button>
            //                 </TableCell>
            //                 <TableCell align="center">{hostedGameExpiredbooking.gameType}</TableCell>
            //                 <TableCell align="center">{hostedGameExpiredbooking.gameName}</TableCell>
            //                 <TableCell align="center">{moment.utc(hostedGameExpiredbooking.gameDate).format('D MMMM YYYY h:mm A')}</TableCell>
            //                 <TableCell align="center">{hostedGameExpiredbooking.noOfPlayer}</TableCell>

            //               </TableRow>
            //             ))}
            //       </TableBody>
            //     </Table>
            //   </TableContainer>
            //   <TablePagination
            //     rowsPerPageOptions={[3, 10, 25]}
            //     component="div"
            //     count={hostedGameExpiredbookings.length}
            //     rowsPerPage={rpgPastHG}
            //     page={pgPastHG}
            //     onPageChange={handleChangePagePastHG}
            //     onRowsPerPageChange={handleChangeRowsPerPagePastHG}
            //     sx={{
            //       backgroundColor: 'white',
            //       ".MuiInputBase-colorPrimary, .css-16c50h-MuiInputBase-root-MuiTablePagination-select, .MuiTablePagination-actions": {
            //         marginTop: '-21px',
            //       },
            //     }}
            //   />
            // </ThemeProvider>
          )
          }

        </div >
      </div >
    </section >
  );
}

export default AllBookings;