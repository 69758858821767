import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import {  Card, CardContent, Typography } from '@mui/material'

// number icons
import Diversity3Icon from '@mui/icons-material/Diversity3';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import FeedIcon from '@mui/icons-material/Feed';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Groups2Icon from '@mui/icons-material/Groups2';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

const contentStyle = {
    marginBottom: '20px',
}

const cardStyle = {
  margin: '20px',
  height: '300px',
  backgroundColor: 'transparent',
  borderRadius: '20px',
  width: '300px',
};

const iconStyle = {
    fontSize: '60px',
    color: 'white',
    margin: '10px',
};

const titleStyle = {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    wordWrap: 'break-word',
    maxWidth:'320px',
};

const bodyStyle = {
    fontSize: 18,
    color: 'white',
    wordWrap: 'break-word',            
};

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Instruction = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-5',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const titleStyle = {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    wordWrap: 'break-word',
};

  const bodyStyle = {
    fontSize: 18,
    color: 'white',
    wordWrap: 'break-word',
};

  return (
    <section
      {...props}
      className={outerClasses}>
        <div className="container-xl">
            <div className={innerClasses}>
                <div className={tilesClasses}>
                  <div class="row " style={{alignItems:'baseline', marginBottom: '-30px'}}>                      
                      <h2 style={{ fontSize: '35px', fontWeight: 'bold' }}>How To Book</h2> 

                      <div class="box">
                      <Card sx={cardStyle}>
                        <CardContent sx={contentStyle} >
                        <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16">
                            <Diversity3Icon
                                  fontSize="large"
                                  sx={{ color: '#a72218' }}/>
                                  </div>
                                  </div>
                            <Typography sx={titleStyle}>Gather your Team</Typography>
                           
                            <Typography sx={bodyStyle} variant="body2">
                            Invite and build your group of escapers. Decide on a good day and timing that works.
                            </Typography>
                        </CardContent>
                        </Card>
                        <Card sx={cardStyle}>
                        <CardContent sx={contentStyle} >
                        <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16">
                            <MeetingRoomIcon 
                              fontSize="large"
                              sx={{ color: '#a72218'}}/>
                                  </div>
                                  </div>
                            <Typography sx={titleStyle}>Select Escape Room</Typography>
                           
                            <Typography sx={bodyStyle} variant="body2">
                            Pick a room that is most enticing to you from ranges of our exciting options.
                            </Typography>
                        </CardContent>
                        </Card>
                        <Card sx={cardStyle}>
                        <CardContent sx={contentStyle} >
                        <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16">
                            <FeedIcon 
                                fontSize="large"
                                sx={{ color: '#a72218'}}/>
                                  </div>
                                  </div>
                            <Typography sx={titleStyle}>Submit Information</Typography>
                           
                            <Typography sx={bodyStyle} variant="body2">
                            Send over the information and wait for an official quotation to be sent out shortly.
                            </Typography>
                        </CardContent>
                        </Card>
                        <Card sx={cardStyle}>
                        <CardContent sx={contentStyle} >
                        <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16">
                            <DirectionsRunIcon
                              fontSize="large"
                              sx={{ color: '#a72218'}}/>
                              </div>
                              </div>
                            <Typography sx={titleStyle}>Get Ready to Escape</Typography>
                           
                            <Typography sx={bodyStyle} variant="body2">
                            Instructions and details will be delivered, better prepare yourself for the best virtual game experience with your team.
                            </Typography>
                        </CardContent>
                        </Card>
                        </div>

                    </div>                   
                </div>
            </div>
        </div>
    </section>
  );
}

Instruction.propTypes = propTypes;
Instruction.defaultProps = defaultProps;

export default Instruction;