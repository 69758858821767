import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";  // Fix here
import moment from 'moment';

const url = process.env.REACT_APP_API_URL;

export const clearUserState = createAction('auth/clearUserState');

export const loginUser = createAsyncThunk(
    'auth/login',
    async (values, { rejectWithValue }) => {
        try {
            let body = {
                email: values.email,
                password: values.password,
                ct: values.csrf
            };
            let headers = {};

            var response3 = await axios.post(`${url}/auth/login`, body, { headers });
            if (response3 !== null && response3?.status !== null && response3?.data?.data !== null) {
                return [response3?.data?.data['user'], response3?.status, response3?.data?.data['token'], response3?.data?.data['apiKey']];
            } else {
                return rejectWithValue([null, response3?.status, `Unexpected error!`]);
            }
        } catch (ex) {
            return rejectWithValue([null, ex.response.status, ex.message]);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
            let res = await axios.get(`${url}/auth/logout`);
            return [res.data, res.status];
        } catch (err) {
            return err;
        }
    }
);

export const checkUByEmAndPass = createAsyncThunk(
    'user/checkUByEmAndPass',
    async (values) => {
        try {
            let res = await axios.post(`${url}/user/checkUByEmAndPass`, values);
            return [res.data, res.status];
        } catch (err) {
            return err;
        }
    }
);

export const GoogleloginUser = createAsyncThunk(
    "auth/Googlelogin",
    async (googleResponse, { rejectWithValue }) => {
        try {
            const tokenId = googleResponse;
            var user = jwtDecode(tokenId);  // Fix here
            let body = {
                email: user.email,
                password: "",
                userType: "CUST",
                userStatus: "A",
                username: user.name,
                createDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                googleId: user.sub,
                tokenId: tokenId,
            };
            let headers = {};
            const full_url = `${url}/auth/Googlelogin`;

            var response3 = await axios.post(full_url, body, { headers });
            if (response3 !== null && response3?.status !== null && response3?.data?.data !== null) {
                return [
                    response3?.data?.data["user"],
                    response3?.data?.data["token"],
                    response3?.data?.data["apiKey"],
                    response3?.status
                ];
            } else {
                return rejectWithValue([null, response3?.status, `Unexpected error!`]);
            }
        } catch (ex) {
            return rejectWithValue([null, ex.response.status, ex.message]);
        }
    }
);

export const getUser = createAsyncThunk(
    'user/getAllUser',
    async (_, { rejectWithValue }) => {
        try {
            let res = await axios.get(`${url}/user/getAllUser`, { withCredentials: true });
            if (res.data.message) {
                return rejectWithValue(res.data.message);
            } else {
                return [res.data, res.status];
            }
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);

export const deactivateUser = createAsyncThunk(
    'user/deactivateAccount',
    async (valuers, { rejectWithValue }) => {
        try {
            let res = await axios.patch(`${url}/user/deactivateAccount`, {
                userID: valuers.userID,
                userEmail: valuers.userEmail
            });
            return res;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
