import { useSelector, useDispatch} from 'react-redux'
import { Outlet, Redirect, Route  } from 'react-router-dom'
import { selectCurrentUser } from '../views/store/user/user.selector';
import React, { Component } from 'react';
import AppRoute from '../utils/AppRoute';
import { clearUserState } from 'src/views/store/user/user.action';


// Layouts
import LayoutDefault from '../layouts/LayoutDefault';

import Purchase from '../views/Client-Side/Purchase';

/*class ProtectedRoute extends Component {
    render() {
        //const navigate = useNavigate();
        const { user } = useSelector(selectCurrentUser)
        const { component: Component, ...props } = this.props

        return (
            <Route 
              {...props} 
              render={props => (
                !user ?
                  <Component {...props} /> :
                  <Redirect to='/login' />
              )} 
            />
          )

    
    }
}


export default ProtectedRoute;*/

const ProtectedRoute = ({ path, component }) => {
  const auth = useSelector(selectCurrentUser);
  // console.log('protected routes - ' + JSON.stringify(auth));
  // console.log('path - ' + path);
  // console.log('children - ' + component);

  const dispatch = useDispatch();

    // return auth ? (
    //   <AppRoute path={path} component={component} layout={LayoutDefault} />
    // ) : (
      
    //   <Redirect to="/login" />
    // );

    return auth ? (
      <AppRoute path={path} component={component} layout={LayoutDefault} />
    ) : (
      (() => {
        dispatch(clearUserState());
        return <Redirect to="/login" />;
      })()
    );
  };

  
  
  export default ProtectedRoute;

