import React, { useEffect, useState} from "react";
import Slider from "react-slick";
import "../../Testimonial.css";
import BasicCard from "./Card";

import axios from 'axios'; // connect to api

const url = process.env.REACT_APP_API_URL;

export default function Testimonial() {
  const slider = React.useRef(null);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    infinite: true,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplay: true,
          speed: 11000,
          autoplaySpeed: 0,
          cssEase: "linear",
          infinite: true,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          speed: 12000,
          autoplaySpeed: 0,
          cssEase: "linear",
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
          speed: 13000,
          autoplaySpeed: 0,
          cssEase: "linear",
          infinite: true,
        },
      },
    ],

  };

  //const gameID = 1;

  // states for user reviews called from getAllReviews api
  const [reviews, setAllReviews] = useState([]);

  const getAllReviews = async () => {
    await axios.post(`${url}/games/reviews`).then((response) => {
      setAllReviews(response.data.data[0])
    })
  };


  useEffect(() => {
    getAllReviews()
  }, []);


  return (
    <div class="container-xl">
      <div style={{ marginTop: '20px',textAlign: "center"}}>
        <h2 style={{ fontSize: '35px', fontWeight: 'bold' }}>What People Say About Us</h2>
        <p>See why our customers love our virtual escape rooms!</p>
      </div>
      
      <Slider ref={slider} {...settings}>
        
      {reviews.map((review, index) => {
                  
                 return <BasicCard item={review} />;
                  
                })}
        {/* {products?.map((item, index) => {
          return <BasicCard item={item} />;
        })} */}
      </Slider>
    </div>
  );
}


