import { createSlice } from '@reduxjs/toolkit';
//import { loginUser, logoutUser, getUser, updateUsersTable, clearUserState } from './user.action';
import { loginUser, logoutUser, getUser, clearUserState, GoogleloginUser } from './user.action';

// initial state
const initialState = {
    currentUser: null,
    statusCode: null,
    token: null,
    apikey: null
}


// ==============================|| SLICE - USER ||============================== //

const user = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        [loginUser.fulfilled]: (state, { payload }) => {
            //console.log(`Entered slice! - ${JSON.stringify(payload)}`);
            state.currentUser = payload[0]
            state.statusCode = payload[1]
            state.token = payload[2]
            state.apikey = payload[3]
            localStorage.token = state.token;
            localStorage.apiKey = state.apikey;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.statusCode = payload[1]
            state.token = null
            state.apikey = null
            localStorage.token = null;
            localStorage.apiKey = null;
        },
        [GoogleloginUser.fulfilled]: (state, { payload }) => {
            //console.log(`Entered slice! - ${JSON.stringify(payload)}`);
            state.currentUser = payload[0]
            state.statusCode = payload[3]
            state.token = payload[1]
            state.apikey = payload[2]
            localStorage.token = state.token;
            localStorage.apiKey = state.apikey;
        },
        [GoogleloginUser.rejected]: (state, { payload }) => {
            state.statusCode = payload[3]
            state.token = null
            state.apikey = null
            localStorage.token = null;
            localStorage.apiKey = null;
        },
        [logoutUser.fulfilled]: (state, { payload }) => {
            state.currentUser = null
            state.currentCompany = null
            state.statusCode = payload[1]
            state.token = null
            state.apikey = null
            localStorage.token = null;
            localStorage.apiKey = null;
        },
        [getUser.fulfilled]: (state, { payload }) => {
            state.currentUser = payload[0]
            state.statusCode = payload[1]
            state.token = payload[2]
            state.apikey = payload[3]
        },
        [getUser.rejected]: (state, action) => {
            state.currentUser = null
            state.statusCode = 404
            state.token = null
            state.apikey = null
            localStorage.token = null;
            localStorage.apiKey = null;
            action.error.message = action.payload
        },
        /*[updateUsersTable.fulfilled]: (state, { payload }) => {
            state.currentUser.CompanyUEN = payload[0]
        },*/
        // revert to initial state
        [clearUserState]: (state) => {
            state.currentUser = null
            state.statusCode = 200
            localStorage.token = null;
            localStorage.apiKey = null;
        },
    }
})

export default user.reducer;