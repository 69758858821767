// Essentials
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios'; // connect to api
import queryString from 'query-string'; // parse query string
// Form and validations
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import moment from 'moment'; // format datetime

// Display http message
import { toast } from 'react-toastify';
//import ReactifyToast from 'reactify-toast';
import 'react-toastify/dist/ReactToastify.min.css';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';
import { selectCurrentUser } from '../store/user/user.selector';

//UI path
import "../../Purchase.css"

const url = process.env.REACT_APP_API_URL;

const UpdateBooking = ({ ...properties }) => {
	const { staticContext, ...props } = properties;
	const root = this;
	// Styles
	const outerClasses = classNames('section');
	// override the light theme that the default steplabel was using
	const LightStepLabel = styled(StepLabel)(() => ({
		[`& .${stepLabelClasses.label}`]: {
			[`&.${stepLabelClasses.completed}`]: {
				color: 'rgba(255, 255, 255, 1)',
			},
			[`&.${stepLabelClasses.active}`]: {
				color: 'rgba(255, 255, 255, 1)',
			},
			color: 'rgba(255, 255, 255, 0.3)',
		},
	}));

	const classes = makeStyles(() => ({
		root: {
			"& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
			marginBottom: '70px'
		},
		divider: {
			background: 'white'
		},
	}))();

	const theme = createTheme({
		palette: {
			success: {
				main: '#fff',
				contrastText: '#000',
			},
			secondary: {
				main: '#fff',
				contrastText: '#000',
			},
			yellow: {
				main: '#f6c94e',
				contrastText: '#000',

			},
			red: {
				main: '#ad2518',
				contrastText: '#fff',
			},
		},
	});

	const btnStyle = {
		fontWeight: 'bold',
		fontSize: 14,
		borderRadius: "10px",
		width: '25%',
		height: '35px',
	};

	// yup validation for the form
	const validationSchema = [ // validation in an array, currentValidationSchema[0] refers to validation on the first page and so on
		// validation on the first page (step 0 in programming terms)
		// validation on the second page (step 1 in programming terms)
		Yup.object().shape({
			dateTime: Yup.date().required('Please select a date'),
		})
	];

	//loading screen
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const userData = useSelector(selectCurrentUser)

	const [voucher, setvoucher] = useState(JSON.parse(sessionStorage.getItem('selectedBook')));
	const [bookings, setThisBooking] = useState([]);

	const getThisBooking = async () => {
		await axios.post(`${url}/user/getThisBooking`, { voucherID: voucher.voucherID }).then((response) => {
			//   console.log(response)
			//   console.log(JSON.stringify(response.data.data[0][0]))
			setThisBooking(response.data.data[0][0])
		})
	};

	useEffect(() => {
		setvoucher(JSON.parse(sessionStorage.getItem('selectedBook')));
	}, []);

	useEffect(() => {
		getThisBooking();
	}, []);


	/**
	 * @returns {string} DateTime of today without seconds
	 */
	function fcompute_date_today() {
		return (new Date()).toISOString().slice(0, 16);
	}

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	};
	function form_step_submit(values, actions) {
		form_submit(values, actions);
	}

	async function form_submit(values, actions) {

		setIsLoading(true);

		// Perform your loading logic here, such as making API calls or loading data

		// Simulate loading time
		setTimeout(() => {
			setIsLoading(false);
		}, 4000); // 2000 milliseconds = 2 seconds

		await sleep(1000);

		const bookingData = {
			userID: userData.userID,
			quantity: bookings.noOfPlayer,
			dateTime: values.dateTime,
			netTotal: bookings.netAmt,
			promoCode: bookings.promoCode,
			gameID: bookings.gameID,
			email: userData.email,
			gameName: bookings.gameName,
		};


		try {

			const validationResult = await axios.post(`${url}/games/validateBooking`, bookingData)
			console.log(validationResult)
			if ([201, 200].includes(validationResult.status)) {
				// console.log(values.dateTime, voucher.voucherID)
				const response = await axios.patch(`${url}/user/updateBooking`, { gameDate: values.dateTime, voucherID: voucher.voucherID, userID: userData.userID })
				// console.log('date', response)

				if (response.status === 200) {
					toast.success(`Booking Date has been changed.`, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 4000,
						// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
						// style: {
						//     fontSize: '13px',
						//     height: '85px',
						//     width: '100%',
						//     display: 'flex',
						// },
						// zIndex: 9999
					});

					history.push('/allbooking');
				} else {
					toast.error(`Error: ${validationResult.data.data}`, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 4000,
						// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
						// style: {
						//     fontSize: '13px',
						//     whiteSpace: 'pre-wrap',
						//     wordWrap: 'break-word',
						//     width: '100%',
						//     display: 'flex',
						// },
						// zIndex: 9999
					});
					actions.setSubmitting(false);
				}

			} else {
				// handle error
				console.log(validationResult.data.data)
				toast.error(`Error: ${validationResult.data.data}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 4000,
					// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
					// style: {
					// 	fontSize: '13px',
					// 	whiteSpace: 'pre-wrap',
					// 	wordWrap: 'break-word',
					// 	width: '100%',
					// 	display: 'flex',
					// },
					// zIndex: 9999
				});
				actions.setSubmitting(false);
			}
		} catch (error) {
			// handle error
			console.log('error.response', error);
			toast.error(`Error: ${error.response.data.data}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 4000,
				// boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
				// style: {
				// 	fontSize: '13px',
				// 	whiteSpace: 'pre-wrap',
				// 	wordWrap: 'break-word',
				// 	width: '100%',
				// 	display: 'flex',
				// },
				// zIndex: 9999
			});

			actions.setSubmitting(false);
		}
	}

	return (
		<section
			{...props}
			className={classNames(outerClasses)}>

			<div className='container-sm'>
				<h1 className='pb-32 center-content'>Edit Booking</h1>
				<p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
					{bookings.gameName}

				</p> <br />
				{/* {game.discPrice == '-1.00' && game.discCondition == '-1' ? <></> :
					<p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '18px', marginBottom: '30px' }}>
						Ticket priced at {game.currency} {game.discPrice}/pax for booking of {game.discCondition} and above
					</p>} */}


				<Formik
					initialValues={{ quantity: '', newQuantity: '10', dateTime: '' }}
					validationSchema={validationSchema[0]}
					onSubmit={form_step_submit}
				>
					{({ isSubmitting, values, errors, touched, handleChange }) => (
						<Form>
							<>
								<div className='formContainer'>
									<label style={{ color: 'white', fontSize: '20px' }}>Select date and time<span style={{ color: "red", padding: '5px' }}>*</span></label>
									<input
										type='datetime-local'
										value={values.dateTime}
										name='dateTime'
										min={fcompute_date_today()}
										onChange={handleChange}
										className='form-control'
										// style={{ color: "grey", backgroundColor: '#151719' }}
										style={{ color: "gray", backgroundColor: '#151719', height: "50px", fontSize: '18px' }}
									/>
									{errors.dateTime && touched.dateTime ? (
										<div className='error-message'>{errors.dateTime}</div>
									) : null}

								</div>

								<div className='container-xs'>
									<MDBCard className='checkoutCard'>
										<h4 className='center-content'>Voucher Code: {bookings.voucherCode}</h4>
										<Divider classes={{ root: classes.divider }} />
										<div className='checkout-content'>
											<div className='col-lg-12'>
												<span style={{ color: 'white' }}>Invoice ID: {bookings.paymentID}</span><br /><br />
												<span style={{ color: 'white' }}>Type: {bookings.gameType}</span><br /><br />
												<span style={{ color: 'white' }}>Game Date & Time: {moment.utc(bookings.gameDate).format('D MMMM YYYY h:mm A')}</span>
												{/* {values.dateTime ?
													<span style={{ color: 'white' }}>Game Date & Time: {new Date(values.dateTime).toDateString()} {new Date(values.dateTime).toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' })}</span>
													:
													<span style={{ color: 'white' }}>Game Date & Time: {new Date(bookings.gameDate).toDateString()} {new Date(bookings.gameDate).toLocaleTimeString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit' })}</span>
												} */}
												<br /><br />

												<p style={{ fontSize: '20px', color: 'white' }}>Quantity: {bookings.noOfPlayer}</p>
												{bookings.promoID !== parseInt(0) ? (
													<p style={{ fontSize: '20px', color: 'white' }}>PromoCode: {bookings.promoCode}</p>
												) : null}

												{bookings.gameURL ?
													(<p style={{ fontSize: '20px', color: 'white' }}>Game Link: {bookings.gameURL}</p>)
													: null}

											</div>
										</div>
										<Divider classes={{ root: classes.divider }} />


										<div className='checkout-content pb-16'>
											<div className='col-lg-8'>
												<span className='total'>Total</span>
											</div>
											<div className='col-lg-4' align='right'>
												{/*<span className='price'>$ {discount == '' ? netTotal  : grossTotal}</span> */}
												<span style={{ color: 'white' }} className='price'>{bookings.currency} {bookings.netAmt}</span>
											</div>
										</div>
									</MDBCard>
								</div>



								<ThemeProvider theme={theme}>

									{isLoading && (
										<Box
											sx={{
												position: 'fixed',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												background: 'rgba(0, 0, 0, 0.5)',
												zIndex: 9999,
											}}
										>
											<CircularProgress color="inherit" />
										</Box>
									)}

									<Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
										<Button
											onClick={() => {
												history.push('/allbooking');
											}}
											sx={btnStyle}
											type='submit'

											style={{ marginBottom: '15px' }}
											variant="contained"
											color='yellow'
											size='small'>
											Back
										</Button>
										<Box sx={{ flex: '1 1 auto' }} />

										<Button
											sx={btnStyle}
											disabled={isSubmitting}
											type='submit'

											style={{ marginBottom: '15px' }}
											variant="contained"
											color='secondary'
											size='small'>
											Save
										</Button>
									</Box>
								</ThemeProvider>
							</>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	);
}
export default UpdateBooking;