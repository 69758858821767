import { Container, Row , Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: "#6163FF",
    border: '5px solid #000',
    boxShadow: 24,
    p: 4,
};

export const Banner = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <section className="banner" id="home">
            <div className="container-xl">
                <h1 id="bannerTitle">
                    <span id="firstLine">VOTED AS THE BEST</span>
                    <br />
                    <span id="secLine">INTERACTIVE VIRTUAL</span>
                    <br />
                    <span id="thirdLine">ESCAPE ROOM </span>
                    <span id="forthLine">in Singapore</span>
                </h1>
            </div>
        </section>
    );
}

export default Banner;
