import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

// material-ui
import { List, ListItemButton, ListItemText } from '@mui/material';
import "src/profile.css"

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    
    return (
        <List component="nav" style={{display: 'inline-flex' ,flexWrap: 'wrap', flexDirection: 'row', paddingRight: '10px', marginLeft: '-10px'}}  >
            <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)} to={'/account'} style={{ width: '100%', height: '100%'}}>               
                <ListItemText primary="Profile"/>
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)} to={'/allbooking'} style={{ width: '100%', height: '100%'}}>               
                <ListItemText primary="My Bookings"/>
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 1} onClick={handleLogout} to={'/'}>
                <ListItemText  primary="Logout"/> 
                {/* <ListItemText primaryTypographyProps={{ fontSize: 15 }} primary="Logout" sx={{ color: 'white'}} /> */}
            </ListItemButton>
         </List>
         

    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
