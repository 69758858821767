// Essentials
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios'; // connect to api
import moment from 'moment'; // format datetime

// Form and validations
import { Formik, Form } from "formik";
import * as Yup from 'yup';

// Display http message
import { toast } from 'react-toastify';
//import ReactifyToast from 'reactify-toast';
import 'react-toastify/dist/ReactToastify.min.css';

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';
import { selectCurrentUser } from '../store/user/user.selector';

//UI path
import "../../Purchase.css"

const url = process.env.REACT_APP_API_URL;

const UpdateBookingHost = ({ ...properties }) => {
    const { staticContext, ...props } = properties;
    // Styles
    const outerClasses = classNames('section');

    const classes = makeStyles(() => ({
        root: {
            "& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
            marginBottom: '70px'
        },
        divider: {
            background: 'white'
        },
    }))();

    const theme = createTheme({
        palette: {
            success: {
                main: '#fff',
                contrastText: '#000',
            },
            secondary: {
                main: '#fff',
                contrastText: '#000',
            },
            yellow: {
                main: '#f6c94e',
                contrastText: '#000',

            },
            red: {
                main: '#ad2518',
                contrastText: '#fff',
            },
        },
    });

    const btnStyle = {
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: "10px",
        width: '25%',
        height: '35px',
    };

    // yup validation for the form
    const validationSchema = [ // validation in an array, currentValidationSchema[0] refers to validation on the first page and so on
        // validation on the first page (step 0 in programming terms)
        // validation on the second page (step 1 in programming terms)
        Yup.object().shape({
            dateTime: Yup.date().required('Please select a date'),
        })
    ];

    //loading screen
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const userData = useSelector(selectCurrentUser)

    const [voucher, setvoucher] = useState(JSON.parse(sessionStorage.getItem('selectedBookHost')));
    const [bookings, setThisBooking] = useState([]);

    const getThisBooking = async () => {
        await axios.post(`${url}/user/getThisBookingHost`, { hostedGameClientID: voucher.hostedGameClientID }).then((response) => {
            //   console.log(response)
            //   console.log(JSON.stringify(response.data.data[0][0]))
            setThisBooking(response.data.data[0][0])
        })
    };

    useEffect(() => {
        setvoucher(JSON.parse(sessionStorage.getItem('selectedBookHost')));
    }, []);

    useEffect(() => {
        getThisBooking();
    }, []);

    /**
     * @returns {string} DateTime of today without seconds
     */
    function fcompute_date_today() {
        return (new Date()).toISOString().slice(0, 16);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    };
    function form_step_submit(values, actions) {
        form_submit(values, actions);
    }
    

    async function form_submit(values, actions) {

        setIsLoading(true);

        // Perform your loading logic here, such as making API calls or loading data

        // Simulate loading time
        setTimeout(() => {
            setIsLoading(false);
        }, 4000); // 2000 milliseconds = 2 seconds

        await sleep(1000);




        try {

            await axios.patch(`${url}/user/updateBookingHost`, { 
                gameDate: values.dateTime, 
                hostedGameClientID: voucher.hostedGameClientID ,
                email: userData.email,
                gameName: bookings.gameName,
                quantity: bookings.noOfPlayer,
                gameType: bookings.gameType,
                userID: userData.userID
            })
                .then((response) => {

                    if (response.status === 200) {
                        toast.success(`Booking Date has been changed.`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 4000,
                        });

                        history.push('/allbooking');
                    }


                }, (err) => {
                    toast.error(`Error: ${err.response.data.data}`, {
                        position: toast.POSITION.TOP_CENTER, // position of the toast message on the screen
                        autoClose: 4000, // time in milliseconds the toast message will be displayed
                    });
                    actions.setSubmitting(false);
                    // }
                })

        } catch (error) {
            // handle error
            console.log('error.response', error);
            toast.error(`Error: ${error.response.data.data}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
            });

            actions.setSubmitting(false);
        }
    }

    return (
        <section
            {...props}
            className={classNames(outerClasses)}>

            <div className='container-sm'>
                <h1 className='pb-32 center-content'>Edit Booking</h1>
                <p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
                    {bookings.gameName}
                </p><br />

                <Formik
                    initialValues={{ quantity: '', newQuantity: '10', dateTime: '' }}
                    validationSchema={validationSchema[0]}
                    onSubmit={form_step_submit}
                >

                    {({ isSubmitting, values, errors, touched, handleChange }) => (
                        <Form>
                            <>
                                <div className='formContainer'>
                                    <label style={{ color: 'white', fontSize: '20px' }}>Select date and time<span style={{ color: "red", padding: '5px' }}>*</span></label>
                                    <input
                                        type='datetime-local'
                                        value={values.dateTime}
                                        name='dateTime'
                                        min={fcompute_date_today()}
                                        onChange={handleChange}
                                        className='form-control'
                                        // style={{ color: "grey", backgroundColor: '#151719' }}
                                        style={{ color: "gray", backgroundColor: '#151719', height: "50px", fontSize: '18px' }}
                                    />
                                    {errors.dateTime && touched.dateTime ? (
                                        <div className='error-message'>{errors.dateTime}</div>
                                    ) : null}

                                </div>
                                <div className='container-xs'>
                                    <MDBCard className='checkoutCard'>
                                        <div className='checkout-content'>
                                            <div className='col-lg-12'>
                                                <span style={{ color: 'white' }}>Type: {bookings.gameType}</span><br /><br />

                                                {bookings.gameDate ?
                                                    <span style={{ color: 'white' }}>Game Date & Time: {moment.utc(bookings.gameDate).format('D MMMM YYYY h:mm A')}</span>
                                                    :
                                                    <span style={{ color: 'white' }}> </span>
                                                }

                                                <p style={{ fontSize: '20px', color: 'white' }}><br />Quantity: {bookings.noOfPlayer}</p>

                                                {bookings.gameURL ?
                                                    (<p style={{ fontSize: '20px', color: 'white' }}>Game Link: {bookings.gameURL}</p>)
                                                    : null}
                                            </div>
                                        </div>
                                        
                                    </MDBCard>
                                </div>



                                <ThemeProvider theme={theme}>

                                    {isLoading && (
                                        <Box
                                            sx={{
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'rgba(0, 0, 0, 0.5)',
                                                zIndex: 9999,
                                            }}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Box>
                                    )}

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                                        <Button
                                            onClick={() => {
                                                history.push('/allbooking');
                                            }}
                                            sx={btnStyle}
                                            type='submit'

                                            style={{ marginBottom: '15px' }}
                                            variant="contained"
                                            color='yellow'
                                            size='small'>
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />

                                        <Button
                                            sx={btnStyle}
                                            disabled={isSubmitting}
                                            type='submit'

                                            style={{ marginBottom: '15px' }}
                                            variant="contained"
                                            color='secondary'
                                            size='small'>
                                            Save
                                        </Button>
                                    </Box>
                                </ThemeProvider>
                            </>
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );

}

export default UpdateBookingHost;