import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import axios from 'axios';

// material-ui
import { Box, Button, FormHelperText, Grid, Stack, FormControl, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import
import { strengthColor, strengthIndicator } from '../utils/password-strength';
import classNames from 'classnames';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_API_URL;

const AuthChangeNewPassword = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  // to overide material ui button default colour themes 
  const theme = createTheme({
    palette: {
      success: {
        main: '#f6c94e',
        contrastText: '#000000',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000000',
      },
      subSecondary: {
        main: '#70140c',
        contrastText: '#fff',
      },
    },
  });

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const location = useLocation();
  const userEmail = location.state.state.email1;
  console.log(userEmail)
  //--------------------------------
  const OTP = location.state.state.OTP;
  const Indicator = location.state.state.threeindicator;
  console.log('final ' + userEmail + OTP + Indicator);
  const history = useHistory()
  const [level, setLevel] = useState();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = useCallback((value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  }, []);
  
  /* 
      const handleCheckpwd = () => {
          try{
              //pwd1 = values.password
              //pwd2 = values.confpassword
              if (values.password == values.confpassword){
                  console.log("Password reset successfully");
              }else{
                  toast.error(`Passwords entered different, please try again`);
              }
          }catch(err){
              toast.error(`Opps. An unexpected error have occurred. Please try again later or contact system administrator for more information - ${err}`);
          }
  
  
      }; */

  useEffect(() => {
    changePassword('');
  }, []);

  return (
    <>
      <section
        {...props}
        className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses} style={{ height: "125vh" }}>
            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                Forgot your password?
              </h1>
              <div className="auth-wrapper" style={{ marginTop: "-200px" }}>
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>

                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                  <div className="d-flex flex-column align-content-end">
                    <div className="auth-r mx-auto">
                      <p>Reset New Password</p>
                    </div>
                    <div className="auth-bodyr mx-auto">
                      <div className="auth-form-container text-start">
                        <Formik
                          initialValues={{
                            password: '',
                            confpassword: '',
                            submit: null
                          }}
                          validationSchema={Yup.object().shape({
                            password: Yup.string().max(255).required('Password is required').matches(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
                            confpassword: Yup.string().max(255).required('Password is required').matches(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                            )
                          })}
                          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                              setStatus({ success: false });
                              setSubmitting(false);

                              axios.patch(`${url}/user/ChangeNewPassword`, //changed2
                                {
                                  Password: values.password,
                                  UserEmail: userEmail,
                                  ConfPassword: values.confpassword,
                                  //----------------------------------
                                  OTP: OTP,
                                  Indicator: Indicator
                                }
                              ).then(response => {
                                console.log(`Response - ${response.status} - ${response.data}`)
                                console.log(values.password)

                                response.status === 204 ? console.log("Password successfully resetted!") : console.log("Password reset failed!")

                                if (response.status === 204) {
                                  toast.success(`Passwords has been resetted successfully`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                    style: {
                                      fontSize: '13px',
                                      height: '85px',
                                      width: '100%',
                                      display: 'flex',
                                    },
                                    zIndex: 9999
                                  });

                                  history.push('/login');
                                }
                              }).catch((error) => {
                                if (error.response) {
                                  console.log(error.response.data);
                                  console.log(error.response.status);
                                  console.log(error.response.headers);
                                  if (error.response.status === 401 || error.response.status === 501) {
                                    toast.error(`Invalid parameters! Please check the values and try again.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                    console.log("Invalid parameters!");
                                  }
                                  else {
                                    toast.error(`Passwords entered differently. Please try again.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });
                                    console.log("Random error occured");
                                  }
                                } else if (error.request) {
                                  toast.error(`Opps! The server appears to be busy at the moment. Please try again later.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                    style: {
                                      fontSize: '13px',
                                      height: '85px',
                                      width: '100%',
                                      display: 'flex',
                                    },
                                    zIndex: 9999
                                  });
                                  console.log(error.request);
                                } else {
                                  // Something happened in setting up the request that triggered an Error
                                  toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    // style: { 
                                    // fontSize: '13px',
                                    // height: '85px',
                                    // width: '100%',
                                    // display: 'flex',
                                    // },      
                                    // zIndex: 9999 
                                  });
                                  console.log('Error', error.message);
                                }
                              });
                            } catch (err) {
                              console.error(err);
                              setStatus({ success: false });
                              setErrors({ submit: err.message });
                              setSubmitting(false);
                            }
                          }}
                        >
                          {({ values, handleChange, touched, errors, handleSubmit, isSubmitting, handleBlur, resetForm }) => (
                            <form noValidate onSubmit={handleSubmit}>
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Stack spacing={1}>
                                    <div className="input-group">
                                      <label for="password" style={{ display: 'block' }}>
                                        Password
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          if (e.target.value.length > 5) { // Call changePassword only when the value is more than 5 characters
                                            changePassword(e.target.value);
                                          }
                                        }}
                                        className='form-control'
                                        placeholder="******"
                                      />
                                      {touched.password && errors.password && (
                                        <FormHelperText error id="helper-text-password-signup">
                                          {errors.password}
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </Stack>
                                  <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Grid container spacing={2} alignItems="center">
                                      <Grid item>
                                        <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                          {level?.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Stack spacing={1}>
                                    <div className="input-group">
                                      <label for="password" style={{ display: 'block' }}>
                                        Confirm Password
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        error={Boolean(touched.password && errors.password)}
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.confpassword}
                                        name="confpassword"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          changePassword(e.target.value);
                                        }}
                                        className='form-control'
                                        style={{ width: '400px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                        placeholder="******"
                                        inputProps={{}}
                                      />
                                      {touched.password && errors.password && (
                                        <FormHelperText error id="helper-text-password-signup">
                                          {errors.password}
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </Stack>
                                </Grid>


                                {errors.submit && (
                                  <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                  </Grid>
                                )}
                                <ThemeProvider theme={theme}>
                                  <Grid item xs={12}>
                                    <Button
                                      disableElevation
                                      disabled={isSubmitting}
                                      fullWidth
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                      color="secondary"
                                      // style={{ marginLeft: '50px' }}
                                      >
                                      Change Password
                                    </Button>
                                    {/* </AnimateButton> */}
                                  </Grid>
                                </ThemeProvider>

                              </Grid>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/><br/>
      </section>
    </>
  );
};

export default AuthChangeNewPassword;