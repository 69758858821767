import React from 'react';
import { SectionTilesProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const SellingPoint = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  return (    
    <section id="sellingpoint" class="sec-padding">
            <div class="container-xl">
                <h2 class="sellingLine" >Why Choose JNR Entertainment?</h2>

                <div class="box">
                  <div class="row">
                    <div class="col-md-6">
                      <h3><i class="icon-calendar fa-lg" style={{ marginRight: "20px"}}></i>Experience & Expertise</h3>                                   
                        <p className="m-0 text-sm" class="text" >
                        With over 10 years of experience and 5,000+ 
                        events organized, we understand how to deliver 
                        seamless, enjoyable experiences tailored to 
                        your needs.                        
                        </p>
                    </div>

                    <div class="col-md-6">
                      <h3><i class="icon-trophy fa-lg" style={{ marginRight: "20px"}}></i>Award Winning</h3>                                   
                        <p className="m-0 text-sm" class="text">
                        Recognized by Spirit of Enterprise and 
                        Singapore Young Entrepreneurs Awards, 
                        we are proud of our commitment to excellence.                        
                        </p>
                    </div>
                  </div>    
                  <div class="row">
                    <div class="col-md-6">
                        <h3><i class="icon-time fa-lg" style={{ marginRight: "20px"}}></i>Unparalleled Service</h3> 
                        <p className="m-0 text-sm" class="text">
                        Our dedicated team responds to inquiries 
                        within 24 hours. Need urgent assistance? 
                        Reach out to us!                        
                        </p>               
                    </div>
                    <div class="col-md-6">
                      <h3><i class="icon-thumbs-up fa-lg" style={{ marginRight: "20px"}}></i>Reliable Services</h3>         
                      <p className="m-0 text-sm" class="text">
                      As a BizSafe-certified company, 
                      we provide high-quality, dependable 
                      services for all your event needs.
                      </p>                          
                    </div>
                  </div>    
                </div> 
            </div>
        </section>
  );
}

SellingPoint.propTypes = propTypes;
SellingPoint.defaultProps = defaultProps;

export default SellingPoint;