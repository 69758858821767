import React from 'react';

//import sections
import Header from '../../components/layout/Header';

// import js
import Banner from './Banner';
import Counter from './Counter';
import Certifications from './Certifications';
import SellingPoint from './SellingPoint';
import Rooms from './Rooms';
import Testimonial from './Testimonial'
import FAQ from './FAQ';
import LearningPoint from './LearningPoint';
import Instruction from './Instruction';
import Clients from './Clients';

// import css
import "../../Banner.css"
import "../../Counter.css"
import "../../SellingPoint.css"
import "../../Certifications.css"
import "../../LearningPoint.css"
import "../../Clients.css"
import "../../Testimonial.css";

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const LandingPage = () => {
    return (
        <div className="LandingPage">
            <Header/>
            <Banner/>
            <Counter/>
            <SellingPoint/>
            <Certifications/>
            <Rooms/>
            <Testimonial/>            
            <LearningPoint/>
            <Instruction/>
            <Clients/>
            <FAQ/>    
        </div>
    );
}

export default LandingPage;