import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import axios from 'axios';
import { useHistory } from "react-router-dom"

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

//UI path
import "../../register.css"

const moment = require('moment');


YupPassword(Yup); // extend yup

const Register = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const history = useHistory()

  const url = process.env.REACT_APP_API_URL;

  // function handleSubmit(values) {
  //   axios.post(`${url}/auth/register`, {
  //     email: values.email,
  //     password: values.password,
  //     userType: "CUST",
  //     userStatus: "A",
  //     username: values.username
  //   }).then((response) => {
  //     if (response.statusText == 'OK') {
  //       toast.success(response.data, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       history.push('/')
  //     }
  //   }, (err) => {
  //     toast.error(err.response.data, {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   })
  // };

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const toggleCPassword = (e) => {
    if (showCPassword) {
      setShowCPassword(false);
    } else {
      setShowCPassword(true);
    }
  };

  const [isActive, setIsActive] = useState(true);

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address!')
      .required('Email is required.'),
    password: Yup.string()
      .password()
      .required("Password is required.")
      .min(8, "Password must be more than 8 characters.")
      .max(20, "Password must be less than 20 characters.")
      .minLowercase(1, "Password must contain at least 1 lower case letter.")
      .minUppercase(1, "Password must contain at least 1 upper case letter.")
      .minNumbers(1, "Password must contain at least 1 number.")
      .minSymbols(1, "Password must contain at least 1 special character."),
    cpassword: Yup.string()
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match!"
        )
      })
  })

  return (
    <>
      <section
        {...props}
        className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses} style={{ height: "125vh" }}>
            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
                Welcome. Experience the world of an escapist.
              </h1>
              <div className="auth-wrapper" style={{ marginTop: "-200px" }}>
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>

                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                  <div className="d-flex flex-column align-content-end">
                    <div className="auth-r mx-auto">
                      <p>Register your Account</p>
                    </div>
                    <div className="auth-bodyr mx-auto">
                      <div className="auth-form-container text-start">
                        <Formik
                          initialValues={{
                            username: '',
                            email: '',
                            password: '',
                            cpassword: '',
                          }}
                          validationSchema={formValidationSchema}
                          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                              // console.log(`Submit detected! - ${values.email}`);
                              // console.log(`Submit detected! - ${values.firstname}`);
                              // console.log(`Submit detected! - ${values.lastname}`);
                              // console.log(`Submit detected! - ${values.password}`);
                              setStatus({ success: false });
                              setSubmitting(false);

                              axios.post(`${url}/auth/register`,
                                {
                                  userEmail: values.email.toLowerCase(),
                                  userPassword: values.password,
                                  userType: "CUST",
                                  userStatus: "A",
                                  username: values.username,
                                  createDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                                }
                              ).then(response => {
                                console.log(`Response - ${response.status} - ${response.data}`)

                                response.status === 201 ? console.log("Account created successfully!") : console.log("Account creation failed!")

                                if (response.status === 201) {
                                  toast.success(`Success! You may now login using your email ${values.email}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                    style: {
                                      fontSize: '13px',
                                      height: '85px',
                                      width: '100%',
                                      display: 'flex',
                                    },
                                    zIndex: 9999
                                  });

                                  history.push('/login');
                                }

                              }).catch((error) => {
                                if (error.response) {
                                  // The request was made and the server responded with a status code
                                  // that falls out of the range of 2xx
                                  console.log(error.response.data);
                                  console.log(error.response.status);
                                  console.log(error.response.headers);
                                  if (error.response.status === 409) {
                                    toast.error(`The email address is already in use. Please use another email.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });

                                    // toast.error(`The email address is already in use. Please use another email.`,{
                                    //   position: toast.POSITION.TOP_CENTER, 
                                    //   autoClose: 4000, 
                                    //   backgroundColor: '#f44336',
                                    //   color: '#fff', 
                                    //   borderRadius: '10px', 
                                    //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    //   style: { 
                                    //     fontSize: '13px',
                                    //     width: '800px',
                                    //     height: '85px',
                                    //     display: 'flex',
                                    //     right: '70%',
                                    //   },      
                                    //   zIndex: 9999 
                                    // });
                                    // console.log("Email already in use!");
                                  }
                                  else if (error.response.status === 401 || error.response.status === 501) {
                                    toast.error(`Invalid parameters! Please check the values and try again.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });

                                    // toast.error(`Invalid parameters! Please check the values and try again.`,{
                                    //   position: toast.POSITION.TOP_CENTER, 
                                    //   autoClose: 4000, 
                                    //   backgroundColor: '#f44336', 
                                    //   color: '#fff', 
                                    //   borderRadius: '10px', 
                                    //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    //   style: { 
                                    //     fontSize: '13px',
                                    //     width: '390px',
                                    //     height: '85px',
                                    //     display: 'flex',
                                    //     right:'10%'
                                    //   },      
                                    //   zIndex: 9999
                                    // });
                                    // console.log("Invalid parameters!");
                                  }
                                  else {
                                    toast.error(`Opps! An error occurred while registering the account! Please try again later or contact the administrator.`, {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 4000,
                                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                      style: {
                                        fontSize: '13px',
                                        height: '85px',
                                        width: '100%',
                                        display: 'flex',
                                      },
                                      zIndex: 9999
                                    });

                                    // toast.error(`Opps! An error occurred while registering the account! Please try again later or contact the administrator.`,{
                                    //   position: toast.POSITION.TOP_CENTER, 
                                    //   autoClose: 4000, 
                                    //   backgroundColor: '#f44336', 
                                    //   color: '#fff',
                                    //   borderRadius: '10px', 
                                    //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    //   style: { 
                                    //     fontSize: '13px',
                                    //     width: '800px',
                                    //     height: '85px',
                                    //     display: 'flex',
                                    //     right: '70%',
                                    //   },      
                                    //   zIndex: 9999 
                                    // });
                                    // console.log("Random error occured");
                                  }
                                } else if (error.request) {
                                  // The request was made but no response was received
                                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                  // http.ClientRequest in node.js
                                  toast.error(`Opps! The server appears to be busy at the moment. Please try again later or contact the administrator.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                                    style: {
                                      fontSize: '13px',
                                      height: '85px',
                                      width: '100%',
                                      display: 'flex',
                                    },
                                    zIndex: 9999
                                  });

                                  // toast.error(`Opps! The server appears to be busy at the moment. Please try again later or contact the administrator.`,{
                                  //   position: toast.POSITION.TOP_CENTER, 
                                  //   autoClose: 4000, 
                                  //   backgroundColor: '#f44336', 
                                  //   color: '#fff',
                                  //   borderRadius: '10px', 
                                  //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                  //   style: { 
                                  //     fontSize: '13px',
                                  //     width: '800px',
                                  //     height: '85px',
                                  //     display: 'flex',
                                  //     right: '70%',
                                  //   },      
                                  //   zIndex: 9999 
                                  // });
                                  // console.log(error.request);
                                } else {
                                  // Something happened in setting up the request that triggered an Error
                                  toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later or contact the administrator.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                    // style: { 
                                    // fontSize: '13px',
                                    // height: '85px',
                                    // width: '100%',
                                    // display: 'flex',
                                    // },      
                                    // zIndex: 9999 
                                  });

                                  // toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later or contact the administrator.`,{
                                  //   position: toast.POSITION.TOP_CENTER, 
                                  //   autoClose: 4000, 
                                  //   backgroundColor: '#f44336', 
                                  //   color: '#fff',
                                  //   borderRadius: '10px', 
                                  //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                  //   style: { 
                                  //     fontSize: '13px',
                                  //     width: '800px',
                                  //     height: '85px',
                                  //     display: 'flex',
                                  //     right: '70%',
                                  //   },      
                                  //   zIndex: 9999 
                                  // });
                                  // console.log('Error', error.message);
                                }

                              });



                            } catch (err) {
                              console.error(err);
                              setStatus({ success: false });
                              setErrors({ submit: err.message });
                              setSubmitting(false);
                            }
                          }}
                        >

                          {/* //onSubmit={handleSubmit}> */}
                          {({ isSubmitting, values, errors, handleChange, handleBlur }) => (
                            <Form>
                              <div className="input-group">
                                <label for="username" style={{ display: 'block' }}>
                                  User Name
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  id="username"
                                  placeholder="demo"
                                  type="text"
                                  autoComplete='off'
                                  value={values.username}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className='form-control'
                                  style={{ width: '100%', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                />
                                {errors.username && <div className="input-feedback">{errors.username}</div>}
                              </div>

                              <div className="input-group">
                                <label for="email" style={{ display: 'block', marginTop: '20px' }}>
                                  Email
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  id="email"
                                  placeholder="demo@gmail.com"
                                  type="text"
                                  autoComplete='off'
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className='form-control'
                                  style={{ width: '100%', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                />
                                {errors.email && <div className="input-feedback">{errors.email}</div>}
                              </div>

                              <div className="input-group">
                                <label for="password" style={{ display: 'block', marginTop: '20px' }}>
                                  Password
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  id="password"
                                  placeholder="Password"
                                  type={showPassword ? "text" : "password"}
                                  autoComplete={false}
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className='form-control'
                                  style={{ width: '100%', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                />
                                {/* <button
                                  type="button"
                                  className="btn1 btn1-outline-primary btn1-sm"
                                  onClick={(e) => togglePassword(e)}
                                  style={{ height: '44px' }}
                                >
                                  <i
                                    className={
                                      showPassword ? "far fa-eye" : "far fa-eye-slash"
                                    }
                                  ></i>{" "}
                                </button> */}
                                {errors.password && <div className="input-feedback">{errors.password}</div>}
                              </div>
                              <div className="input-group">
                                <label for="cpassword" style={{ display: 'block', marginTop: '20px' }}>
                                  Confirm Password
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  id="cpassword"
                                  placeholder="Confirm password"
                                  type={showCPassword ? "text" : "password"}
                                  autoComplete={false}
                                  value={values.cpassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className='form-control'
                                  style={{ width: '100%', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                />
                                {/* <button
                                  type="button"
                                  className="btn1 btn1-outline-primary btn1-sm"
                                  onClick={(e) => toggleCPassword(e)}
                                  style={{ height: '44px' }}
                                >
                                  <i
                                    className={
                                      showCPassword ? "far fa-eye" : "far fa-eye-slash"
                                    }
                                  ></i>{" "}
                                </button> */}
                                {errors.cpassword && <div className="input-feedback">{errors.cpassword}</div>}
                              </div>

                              <br></br>

                              <div className="text-p" style={{ marginTop: '3px' }}>
                                <p>By Registering, you agree to our &nbsp;
                                  <br></br>
                                  <Link className='text-link' style={{ color: "#fff" }} to='/terms-of-service'>
                                    Terms of Service
                                  </Link>
                                  &nbsp; and &nbsp;
                                  <Link className='text-link' style={{ color: "#fff" }} to='/privacy-policy'>
                                    Privacy Policy
                                  </Link>
                                  .</p>
                              </div>

                              <button className="regBtn" type="submit" style={{ marginLeft: '-3px', width: '100%', height: '50px', marginTop: '-5px', textAlign: 'center', fontSize: '0.875rem', backgroundColor: '#fff', color: 'black' }} >
                                Register
                              </button>
                            </Form>
                          )}
                        </Formik>

                        <div className="auth-option text-center pt-2" style={{ marginTop: '10px' }}>
                          Already have an account?{" "}
                          <Link className="text-link" to="/login">
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /><br /><br />
      </section>
    </>
  );
};

export default Register;