import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const About = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

    return (
      <section
      {...props}
      className = {outerClasses}>

      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-left" data-reveal-delay="200">
              About Us
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-right text-color-low" data-reveal-delay="400">
                This is the About us page.
                </p>
            </div>
          </div>
        </div>
      </div>
      </section>
    )
}
  
export default About;