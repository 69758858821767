import React, { useState, useEffect } from 'react'; 
import { toast } from 'react-toastify';
import { GoogleloginUser } from '../../views/store/user/user.action';
import { handleAuthRes } from '../../helper/handleAuthRes';
import { jwtDecode } from "jwt-decode";  // Fix here
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { useHistory } from "react-router-dom";

function Google() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setuser] = useState({});
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleCallbackResponse = async (response) => {
    var GoogleToken = response.credential;
    var userObject = jwtDecode(response.credential);  // Fix here
    setuser(userObject);

    try {
      setIsLoading(true);
      setTimeout(async () => {
        const { payload } = await dispatch(GoogleloginUser(GoogleToken));
        handleAuthRes(payload[3], payload[0]);
        if (payload[3] === 200) {
          history.push('/');
          setIsAuthenticated(true);
        } else {
          if (payload[3] === 401 || payload[3] === 501) {
            toast.error('Invalid parameters! Please check the values and try again.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
              style: { fontSize: '13px', height: '85px', width: '100%', display: 'flex' },
              zIndex: 9999
            });
          } else if (payload[3] === 409) {
            toast.error('The email address is already in use. Please use another email.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
              style: { fontSize: '13px', height: '85px', width: '100%', display: 'flex' },
              zIndex: 9999
            });
          } else {
            toast.error('Oops. An unexpected error occurred. Please try again later or contact system administrator.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
              style: { fontSize: '13px', height: '85px', width: '100%', display: 'flex' },
              zIndex: 9999
            });
          }
        }
        setIsLoading(false);
      }, 2000);
      return true;
    } catch (err) {
      setIsLoading(false);
      toast.error(`Oops. An unexpected error occurred. Please try again later or contact system administrator - ${err}`);
      return false;
    }
  }

  const client_id = process.env.REACT_APP_clientId;

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: client_id,
      callback: handleCallbackResponse
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {
        theme: "outline",
        size: "large",
        position: "absolute",
      }
    );
  }, [client_id]);

  return (
    <div>
      <span style={{ color: 'white' }}>Or login with</span>
      <div id="signInDiv" style={{
        backgroundColor: 'white',
        width: '100%',
        height: '50px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px',
      }}></div>
    </div>
  );
}

export default Google;
