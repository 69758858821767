import React, { useState, Component } from 'react';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SocialIcon } from 'react-social-icons';
import { Redirect, useHistory } from "react-router-dom";

export default class Facebook extends Component {
    state = {
        isLoggedIn: false,
        userID: '',
        name: '',
        email: '',
        picture: ''
    }

    responseFacebook = response => {
        console.log(response);
    }

    componentClicked = () => console.log("clicked");

    render() {

        let fbContent;

        if(this.state.isLoggedIn) {
            fbContent = null
        } else {
            /*fbContent = (
                <FacebookLogin
                    appId="638025574355505" // Include your own appId
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.componentClicked}
                    callback={this.responseFacebook} 
                    render={renderProps => (
                        <button className="button button-fb" 
                        //style={{width:"400px", justifyContent:"center", alignItems:"center"}} 
                        style={{width:"100%", justifyContent:"center", alignItems:"center"}} 
                        onClick={renderProps.onClick}> 
                        <SocialIcon network="facebook" bgColor="#ffffff" 
                        //style={{height: 25, width: 25, left:"-90px"}}
                        style={{height: 25, width: 25, left:"-50px"}} /> Login with Facebook</button>
                      )} />
            );*/
            }

        return <div>{fbContent}</div>
    }
}