import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';

// project import
import ProtectedRoute from './routes/ProtectedRoute';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LandingLayout from './layouts/LandingLayout';
import AdminLayout from './layouts/AdminLayout';

//css slick
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Views 
import Home from './views/Client-Side/Home';
import About from './views/Client-Side/About';
import Login from './views/Client-Side/Login';
import Register from './views/Client-Side/Register';
import TermsOfService from './views/Client-Side/TermsOfService';
import PrivacyPolicy from './views/Client-Side/PrivacyPolicy';
import Forgot from './views/Client-Side/Forgot';
import Reset from './views/Client-Side/Reset';
import Games from './views/Client-Side/Games';
import Games2 from './views/Client-Side/Games2.0';
import Reviews from './views/Client-Side/Review';
import Support from './views/Client-Side/Support';

// import Landing from './views/Landing';
import LandingPage from './views/Client-Side/LandingPage';
import Purchase from './views/Client-Side/Purchase';
import PurchaseHost from './views/Client-Side/PurchaseHostGame';
import PurchaseHostFinish from './views/Client-Side/PurchaseHostFinish';
import PageNotFound from './views/PageNotFound';
import MainPage from './views/Main/MainPage';

import AllPromo from './views/Admin-Side/allPromo';
import CreatePromo from './views/Admin-Side/createPromo';
import EditPromo from './views/Admin-Side/editPromo'
import CreateRoom from './views/Admin-Side/createRooms';
import EditRoom from './views/Admin-Side/editRoom'

import AllRooms from './views/Admin-Side/allRooms';


import AllClients from './views/Admin-Side/allClients';
import CreateClient from './views/Admin-Side/createClient';

import AllCertifications from './views/Admin-Side/allCertifications';
import CreateCertification from './views/Admin-Side/createCertification';
import EditCertification from './views/Admin-Side/editCertification';
import EditClient from './views/Admin-Side/editClient';
import Testing from './views/Client-Side/Test';


import TestPromo from './views/Admin-Side/testPromo'

// project import
//import Routes from 'routes';

import { Navigate, useRoutes } from 'react-router-dom';
import AuthForgotPassword from './helper/AuthForgotPassword';
import AuthChangeNewPassword from './helper/AuthChangeNewPassword';
import AuthOTPVerification from './helper/AuthOTPVerification';
import Payment from './views/Client-Side/Payment';
import AccountSettings from 'src/views/Client-Side/Account';
import AllBookings from 'src/views/Client-Side/AllBookings';
import ViewBooking from './views/Client-Side/ViewBooking';
import UpdateBooking from './views/Client-Side/UpdateBooking';
import ViewBookingHosted from './views/Client-Side/ViewBookingHosted';
import UpdateBookingHost from './views/Client-Side/UpdateBookingHost';
// layouts


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);


const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.send({ hitType: "pageview", page: page });
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <ToastContainer />
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            {/*}<Route path='/' element={<LandingPage />} />*/}
            {/* <AppRoute exact path="/" component={LandingPage} layout={LandingLayout} /> */}
            <AppRoute exact path="/" component={LandingPage} layout={LayoutDefault} />
            <AppRoute path="/login" component={Login} layout={LayoutDefault} />
            <AppRoute path="/about" component={About} layout={LayoutDefault} />
            {/*<AppRoute path="/individualGame/:id" component={Games} layout={LayoutDefault} />*/}
            <AppRoute path="/individualGame" component={Games} layout={LayoutDefault} />
            {/* <AppRoute path="/games2.0" component={Games2} layout={LayoutDefault} /> */}
            <AppRoute path="/support" component={Support} layout={LayoutDefault} />
            <AppRoute path="/register" component={Register} layout={LayoutDefault} />
            <AppRoute path="/terms-of-service" component={TermsOfService} layout={LayoutDefault} />
            <AppRoute path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault} />
            <AppRoute path="/reset-password" component={Reset} layout={LayoutDefault} />
            <AppRoute path="/home" component={Home} layout={LayoutDefault} />
            <AppRoute path="/forgot-password" component={AuthForgotPassword} layout={LayoutDefault} />
            <AppRoute path="/otpverification" component={AuthOTPVerification} layout={LayoutDefault} />
            <AppRoute path="/changenewpassword" component={AuthChangeNewPassword} layout={LayoutDefault} />
            <AppRoute path='/payment/finish'   component={Payment} layout={LayoutDefault} />
            
        

            

            {/*Protected routes*/}
            <ProtectedRoute path='/purchaseRoom' component={Purchase} />
            <ProtectedRoute path='/purchase' component={Purchase} />
            <ProtectedRoute path="/account" component={AccountSettings} />
            <ProtectedRoute path='/allbooking' component={AllBookings} />
            <ProtectedRoute path='/viewbooking' component={ViewBooking} />
            <ProtectedRoute path='/updatebooking' component={UpdateBooking} />
            <ProtectedRoute path='/purchaseHost' component={PurchaseHost} />
            <ProtectedRoute path='/payment/finish'   component={Payment} />
            <ProtectedRoute path='/finish'   component={PurchaseHostFinish} />
            <ProtectedRoute path='/viewbookinghosted' component={ViewBookingHosted} />
            <ProtectedRoute path='/updatebookinghost' component={UpdateBookingHost} />

            {/* 404 page must be the last path*/}
            <AppRoute path="*" component={PageNotFound} layout={LayoutDefault} />

            {/*<AppRoute exact path="/" component={LandingPage} layout={LandingLayout} />
          <AppRoute path="/about" component={About} layout={LayoutDefault}/>         
          <AppRoute path="/games" component={Games} layout={LayoutDefault}/>
          <AppRoute path="/individualGame/:id" component={Games} layout={LayoutDefault}/>
          <AppRoute path="/games2.0" component={Games2} layout={LayoutDefault}/>
          <AppRoute path="/support" component={Support} layout={LayoutDefault}/>
          <AppRoute path="/login" component={Login} layout={LayoutDefault}/>
          <AppRoute path="/register" component={Register} layout={LayoutDefault}/>
          <AppRoute path="/terms-of-service" component={TermsOfService} layout={LayoutDefault}/>
          <AppRoute path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault}/>
          <AppRoute path="/forgot-password" component={Forgot} layout={LayoutDefault}/>
          <AppRoute path="/reset-password" component={Reset} layout={LayoutDefault}/>
          <AppRoute path="/game-review" component={Reviews} layout={LayoutDefault}/>
          <AppRoute path="/home" component={Home} layout={LayoutDefault} />
          <AppRoute path="/purchase" component={Purchase} layout={LayoutDefault} />
          <AppRoute path="/purchaseRoom" component={Purchase} layout={LayoutDefault} />
          <AppRoute path="/mainPage" component={MainPage} layout={LayoutDefault}/>
          
          <AppRoute path="/test" component={Testing} layout={LayoutDefault}/>

          
          <AppRoute path="/testPromo" component={TestPromo} layout={LayoutDefault}/>





          <AppRoute path="/allPromo" component={AllPromo} layout={AdminLayout} />
          <AppRoute path="/createPromo" component={CreatePromo} layout={AdminLayout} />
          <AppRoute path="/editPromo/:id" component={EditPromo} layout={AdminLayout} />

          <AppRoute path="/allRooms" component={AllRooms} layout={AdminLayout} />
          <AppRoute path="/createRoom" component={CreateRoom} layout={AdminLayout} />
          <AppRoute path="/editRoom/:id" component={EditRoom} layout={AdminLayout} />

          <AppRoute path="/allClients" component={AllClients} layout={AdminLayout} />
          <AppRoute path="/createClient" component={CreateClient} layout={AdminLayout} />
          <AppRoute path="/editClient/:id" component={EditClient} layout={AdminLayout} />

          <AppRoute path="/allCertifications" component={AllCertifications} layout={AdminLayout} />
          <AppRoute path="/createCertification" component={CreateCertification} layout={AdminLayout} />      
          <AppRoute path="/editCertification/:id" component={EditCertification} layout={AdminLayout} />
          {/* 404 page must be the last path 
          <AppRoute path="*" component={PageNotFound} layout={LayoutDefault}/>*/}
          </Switch>
        )} />
    </>
  )
};
export default App;