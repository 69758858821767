import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    TextField,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
    FormHelperText,
    OutlinedInput,
    Select,
    MenuItem
} from '@mui/material';

//import TextFieldWrapper from 'components/TextFieldWrapper';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

//css
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { makeStyles } from "@material-ui/core/styles";

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser } from 'src/views/store/user/user.selector';
import { checkUByEmAndPass, deactivateUser, loginUser, logoutUser } from 'src/views/store/user/user.action';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { strengthColor, strengthIndicator } from 'src/utils/password-strength';
import axios from "axios";
//import { useFormik } from 'formik';

import googleLogo from '../../assets/images/google.svg';

// const validationSchema = Yup.object({
//     email: Yup.string().email().required(),
//     password: Yup.string().required(),
// });

//UI link //UI path
import "../../profile.css"



const AccountSettings = ({ ...properties }) => {

    const { staticContext, ...props } = properties;
    const root = this;
    // Styles
    const outerClasses = classNames('section');

    const classes = makeStyles(() => ({
        root: {
            "& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
            marginBottom: '70px'
        },
        divider: {
            background: 'white'
        },
    }))();

    // to-do: CRUD for user account - adara
    // ux: secondary button is not grey?
    const userData = useSelector(selectCurrentUser);

    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [passwordopen, setPasswordOpen] = useState(false);
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
    });
    const [openDeactivate, setOpenDeactivate] = useState(false);
    const [level, setLevel] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [aftercheckpass, setaftercheckpass] = useState('');

    const [otpOpen, setOtpOpen] = useState(false);
    const [otpValues, setOtpValues] = useState({
        otp: "",
    });
    const [gmailOpen, setGmailOpen] = useState(false);
    const [gmailValues, setGmailValues] = useState({
        gmail: "",
    });

    const url = process.env.REACT_APP_API_URL;

    const handleClickOpen = (value) => {
        setaftercheckpass(value);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        setOpen(false);
    };
    const handlePasswordClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        setPasswordOpen(false);
    };

    const handlePasswordOpen = (event, reason) => {
        if (reason && reason == "backdropClick") return;
        setPasswordOpen(true);
        setOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // dialog open after current password check
    const routeaftercheck = (functionname) => {
        switch (functionname) {
            case 'resetpassword':
                handlePasswordOpen()
                break;
            case '...':
                break
            default:
                break;
        }
    }

    // const setUsername = (newUsername) => {
    //     setUserData(prevUserData => {
    //       return {...prevUserData, username: newUsername};
    //     });
    //   };

    async function GmailVerif(gmail, email) {
        // console.log("email @ gmail", email)
        // console.log("gmail @ gmail", gmail)
        try {
            // console.log("email b4 axios patch", email)
            // console.log("gmail b4 axios patch", gmail)
            let result = await axios.patch(`${url}/settings/account/gmailVerification`, {
                gmail: gmail,
                email: email
            });

            // console.log('result after axios patch"', result)

            setGmailOpen(false);
            // await navigate(0);
            toast.success("Verification Email sent successfully");
            setOtpOpen(true);
        } catch (ex) {
            toast.error(`Gmail is invalid or already in use.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000
            });
            // console.log(`Gmail is invalid or already in use - ${ex}`, {
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 4000
            // });
        }
    }

    async function OTPVerif(otp) {
        try {
            // console.log(`OTPVerif token - ${otp}`);
            const response = await axios.patch(`${url}/settings/account/OTPVerification`, {
                otp: otp,
            });
            // console.log('response otp verif', response)

            // Check if the response contains the expected success message
            // if (response.data && response.data.message === 'Account verified successfully.') {
            if (response.status === 200 ) {
                // console.log("otp", otp);
                setOtpOpen(false);
                // await navigate(0);
                toast.success("Gmail has been verified", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
                window.location.reload();
            } else {
                // Display an error message if the success message is not present
                toast.error("Failed to verify OTP. Please try again.", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
            }
        } catch (ex) {
            // Handle specific error cases
            if (ex.response) {
                if (ex.response.status === 400) {
                    toast.error("Invalid OTP or OTP has expired. Please try again.", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 4000
                    });
                } else {
                    toast.error(`Server responded with status - ${ex.response.status}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 4000
                    });
                }
            } else if (ex.request) {
                toast.error("No response received. Request made but no response.", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
                console.log('error', ex);
            } else {
                toast.error(`Error - ${ex.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
            }
        }
    }

    const [updatedUserData, setUserUpdatedData] = useState(null);

    const fetchUserDataFromDatabase = async (email, password, userType, userStatus, username, createDate, googleId, tokenId, GmailAddress, GoogleEnabled, TokenUse) => {
        // console.log('start')
         console.log('email @ fetchUserDataFromDatabase', email)
        // console.log('userStatus @ fetchUserDataFromDatabase', userData.userStatus)
         console.log('googleId @ fetchUserDataFromDatabase', userData.googleId)
        try {
            let body = {
                email: userData.email,
                password: userData.password,
                userType: userData.userType,
                userStatus: userData.userStatus,
                username: userData.username,
                createDate: userData.createDate,
                googleId: userData.googleId,
                tokenId: userData.tokenId,
                GmailAddress: userData.GmailAddress,
                GoogleEnabled: userData.GoogleEnabled,
                TokenUse: userData.TokenUse
            }
            let headers = {
                // TODO: Include the X-Api-Key
                // TODO: Add the JWT
            };
            try {
                console.log('userData.googleId',userData.googleId)
                const response = await axios.post(`${url}/settings/account/fetchUserDataFromDatabase`, body, { headers });
                 console.log('response.data', response.data.data[0][0])
                 console.log('resp', response.status)
                // console.log('response.data[0]', response.data[0])
                if (response.status === 200) {
                    const updatedUserData = response.data.data[0][0];
                     //console.log('updatedUserData', updatedUserData)
                    //  setUserUpdatedData(response.data.data[0][0]);
                    return updatedUserData;
                } else {
                    throw new Error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    };

    useEffect(() => {
         //console.log('userdatat', userData)
        const fetchData = async () => {
            try {
                // console.log('start fetchData')
                const dataFromDatabase = await fetchUserDataFromDatabase(userData.email || userData.GmailAddress || "");
                // console.log('dataFromDatabase', dataFromDatabase);
                setUserUpdatedData(dataFromDatabase);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData])

    const GoogleConnect = () => {
        console.log('gmailadd', updatedUserData?.GmailAddress);
        console.log('gmail enable', updatedUserData?.GoogleEnabled);
        if (updatedUserData?.GmailAddress && updatedUserData?.GoogleEnabled == 1) {
            return (
                <Button
                    color="error"
                    size="medium"
                    variant="outlined"
                    float="right"
                    // onClick={() => setGmailOpen(true)}
                    // onClick={() => setOtpOpen(true)} // temp for testing OTP Verif, remove with disconnect button after
                    onClick={() => {
                        // console.log("Clicked disconnect button with Gmail:", updatedUserData.GmailAddress);
                        // console.log("userData 123:", updatedUserData);
                        disconnectGmail(updatedUserData.GmailAddress);
                    }}
                    style={{ display: 'inline-grid', borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px', borderColor: '#f6c94e', marginBottom: '20px', marginTop: '10px' }}
                >
                    Disconnect
                </Button>
            );
        } else {
            if (
                updatedUserData?.tokenId &&
                updatedUserData?.GoogleEnabled !== 1 &&
                updatedUserData?.TokenUse == 0
            ) {
                return (
                    <Button
                        color="primary"
                        size="medium"
                        variant="outlined"
                        float="right"
                        onClick={() => setOtpOpen(true)}
                    >
                        Verify
                    </Button>
                );
            } else {
                return (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        float="right"
                        onClick={() => setGmailOpen(true)}
                        style={{ display: 'inline-grid', borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px', borderColor: '#f6c94e', marginBottom: '20px', marginTop: '10px' }}
                    >
                        Connect
                    </Button>
                );
            }
        }
    };

    async function disconnectGmail(gmail) {
        try {
            const response = await axios.patch(`${url}/settings/account/disconnectGmail`, {
                gmail: gmail,
            });
            //console.log('response', response)
            if (response.status === 200) {
                toast.success("Gmail has been disconnected", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
                window.location.reload();
            } else if (response.status === 404) {
                toast.error("User not found", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
            } else {
                toast.error("Error disconnecting Gmail", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000
                });
            }
        } catch (ex) {
            toast.error("Error disconnecting Gmail", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000
            });
        }
    };

    const handleCheckPassword = async (values, functionname) => {
        try {
            let valuer = {
                email: userData.email,
                password: values.password
            }
            //console.log(valuer)
            let { payload } = await dispatch(checkUByEmAndPass(valuer));
            //console.log(payload);
            payload[0] != null ? routeaftercheck(functionname) : toast.error('Incorrect Password', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                borderRadius: '10px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                style: {
                    fontSize: '13px',
                    width: '100%',
                    display: 'flex',
                },
                zIndex: 9999
            })
        } catch (err) {
            toast.error(`Opps. An unexpected error have occurred. Please try again later or contact system administrator for more information - ${err}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
                style: {
                    fontSize: '13px',
                    height: '85px',
                    width: '100%',
                    display: 'flex',
                },
                zIndex: 9999
            });
        }
    }

    const deactivateAccount = async () => {
        try {
            let valuers = {
                userID: userData.userID,
                userEmail: userData.email
            }
            //console.log(valuers)
            let { payloads } = await dispatch(deactivateUser(valuers));
            //console.log(payloads)
            dispatch(logoutUser());
            history.push('/login');
            toast.success(`Your account has been successfully terminated`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                // style: { 
                // fontSize: '13px',
                // height: '85px',
                // width: '100%',
                // display: 'flex',
                // },      
                // zIndex: 9999 
            });

            // toast.success(`Your account has been successfully Terminated`, {
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 4000,
            //     backgroundColor: '#f44336',
            //     color: '#fff',
            //     borderRadius: '10px',
            //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
            //     style: {
            //         fontSize: '13px',
            //         width: '390px',
            //         height: '85px',
            //         display: 'flex',
            //         right: '10%'
            //     },
            //     zIndex: 9999
            // });
        } catch (err) {
            toast.error(`Opps. An unexpected error have occurred. Please try again later or contact system administrator for more information - ${err}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                // style: { 
                // fontSize: '13px',
                // height: '85px',
                // width: '100%',
                // display: 'flex',
                // },      
                // zIndex: 9999 
            });
        }
    }

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setLevel(strengthColor(temp));
    };

    const [authorize, setAuthorize] = useState(false);

    const authorization = async () => {
        await axios.post(`${url}/authorization`,
            {
                from: 'client'
            }).then((response) => {
                // console.log(response.status)
                if (response.status === 200) {
                    setAuthorize(true)
                } else {
                    setAuthorize(false);
                }
            })
    };

    useEffect(() => {
        //console.log('authorization test account')
        authorization();
    }, []);

    const GmailField = () => {
        const handleChange = (prop) => (event) => {
            setGmailValues({ ...gmailValues, [prop]: event.target.value });
        };

        return (
            <FormControl sx={{ mt: 3, width: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-gmail">
                    Gmail Address
                </InputLabel>
                <Input
                    type="text"
                    value={gmailValues.gmail}
                    onChange={handleChange("gmail")}
                    //autoFocus={true}
                />
            </FormControl>
        );
    };

    const OtpField = () => {
        const handleChange = (prop) => (event) => {
            setOtpValues({ ...otpValues, [prop]: event.target.value });
        };

        return (
            <FormControl sx={{ mt: 3, width: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-gmail">OTP</InputLabel>
                <Input
                    type="text"
                    value={otpValues.otp}
                    onChange={handleChange("otp")}
                    //autoFocus={true}
                />
            </FormControl>
        );
    };

    const PasswordField = () => {


        const handleChange = (prop) => (event) => {
            setValues({ ...values, [prop]: event.target.value });

        };

        // const handleClickShowPassword = () => {
        //     setValues({
        //         ...values,
        //         showPassword: !values.showPassword,
        //     });
        // };

        // const handleMouseDownPassword = (event) => {
        //     event.preventDefault();
        // };

        return (
            <FormControl sx={{ mt: 3, width: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    //autoFocus={true}
                />
            </FormControl>
        )
    }

    if (authorize === true) {
        return (
            <section
                {...props}
                className={classNames(outerClasses, '')}>
                <br></br>
                <div className='container-xs'>
                    <MDBCard className='checkoutCard'>
                        <h4 className='center-content'>Profile Information</h4>
                        <Divider classes={{ root: classes.divider }} />
                        <div className='checkout-content'>

                            <Grid container mt={2} spacing={2} justify="center" alignItems="center">
                                <Grid item xs={20} fontSize={25} className='center-content' >
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            username: userData.username
                                        }}
                                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                            try {
                                                setStatus({ success: false });
                                                setSubmitting(false);

                                                axios.patch(`${url}/user/resetDetails`,
                                                    {
                                                        username: values.username,
                                                        UserId: userData.userID
                                                    }
                                                ).then(response => {
                                                    //console.log(`Response - ${response.status} - ${response.data}`)

                                                    response.status === 204 ? console.log("Username successfully resetted!") : console.log("Username reset failed!")

                                                    if (response.status === 204) {
                                                        // dispatch(updateUsername({ username: values.username}))
                                                        // console.log(userData)

                                                        toast.success(`Your name has been changed successfully. Please log in again.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });

                                                        dispatch(logoutUser());
                                                        history.push('/login');
                                                    }
                                                }).catch((error) => {
                                                    if (error.response) {
                                                        //console.log(error.response.data);
                                                        //console.log(error.response.status);
                                                        //console.log(error.response.headers);
                                                        if (error.response.status === 401 || error.response.status === 501) {
                                                            toast.error(`Invalid parameters! Please check the values and try again.`, {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 4000,
                                                                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                                // style: { 
                                                                // fontSize: '13px',
                                                                // height: '85px',
                                                                // width: '100%',
                                                                // display: 'flex',
                                                                // },      
                                                                // zIndex: 9999 
                                                            });
                                                           // console.log("Invalid parameters!");
                                                        }
                                                        else {
                                                            toast.error(`Opps! An error occurred while resetting the password! Please try again later.`, {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 4000,
                                                                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                                // style: { 
                                                                // fontSize: '13px',
                                                                // height: '85px',
                                                                // width: '100%',
                                                                // display: 'flex',
                                                                // },      
                                                                // zIndex: 9999 
                                                            });
                                                            //console.log("Random error occured");
                                                        }
                                                    } else if (error.request) {
                                                        toast.error(`Opps! The server appears to be busy at the moment. Please try again later.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });
                                                        //console.log(error.request);
                                                    } else {
                                                        // Something happened in setting up the request that triggered an Error
                                                        toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });
                                                        //console.log('Error', error.message);
                                                    }
                                                });
                                            } catch (err) {
                                                console.error(err);
                                                setStatus({ success: false });
                                                setErrors({ submit: err.message });
                                                setSubmitting(false);
                                            }
                                        }}
                                    >
                                        {({ values, handleChange, touched, errors, handleSubmit, isSubmitting, resetForm }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Grid container spacing={3}>
                                                    <br />
                                                    <div className='leftAlign' style={{ marginLeft: '25px' }}>
                                                        {<Grid item xs={12} style={{ marginBottom: '1rem' }}>
                                                            <label style={{ paddingRight: '5px' }}>
                                                                <Typography variant='body2' fontWeight='bolder' fontSize={20} >Name :</Typography>
                                                            </label>
                                                            <Input
                                                                error={Boolean(touched.password && errors.password)}
                                                                id="password"
                                                                value={values.username}
                                                                name="username"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                }}
                                                                inputProps={{}}
                                                            />
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting}
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                // color="primary"
                                                                sx={{ ":hover": { bgcolor: 'white' }, mt: 1, ml: 1.5, borderRadius: '5px', color: 'black', backgroundColor: "white", border: '1px solid black' }}

                                                            >
                                                                Change Name
                                                            </Button>

                                                        </Grid>
                                                        }
                                                    </div>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </Grid>

                                <Grid container spacing={0.5} className='center-content'>
                                    <div className='leftAlign' style={{ marginLeft: '28px' }}>
                                        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                                            <label style={{ paddingRight: '5px', marginLeft: '-7px' }}>
                                                <Typography variant='body2' fontWeight='bolder' fontSize={20} >Email :</Typography>
                                                {/* <Typography variant='body2' fontWeight='bolder' style={{ display: 'inline-grid', width: '50%', fontSize: '20px' }}>Email :</Typography> */}
                                            </label>
                                            <Typography variant='body2' gutterBottom style={{ display: 'inline-grid' }}>{userData.email}</Typography>
                                        </Grid>
                                    </div>
                                </Grid>
                                

                                <Grid container spacing={0.5} className='center-content'>
                                    <div className='leftAlign' style={{ marginLeft: '28px' }}>

                                        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                                            {/* <div className="ChgPassSec" style={{ marginLeft: '43.5%' }}> */}
                                            <label style={{ paddingRight: '5px', marginLeft: '-7px' }}>
                                                <Typography variant='body2' fontWeight='700' fontSize={20}>Password :</Typography>
                                            </label>
                                            <Button size="small" variant="outlined" onClick={() => { handleClickOpen('resetpassword') }} style={{ display: 'inline-grid', borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px solid black' }}>
                                                Change Password
                                            </Button>

                                        </Grid>
                                    </div>
                                </Grid>

                                {updatedUserData?.GoogleEnabled !== 1 && ! updatedUserData?.email.includes("gmail.com")?
                                <Grid container spacing={0.5}> {console.log(updatedUserData?.GoogleEnabled)}
                                    <br/>
                                    <div className='leftAlign' style={{ marginLeft: '28px' }}>
                                        <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                                            <label style={{ paddingRight: '5px', marginLeft: '-7px' }}>
                                                <Typography variant='body2' fontWeight='bolder' fontSize={20}>
                                                    Linked Accounts
                                                </Typography>
                                                <Typography variant="body2">
                                                    We use this to let you sign in and populate your profile
                                                    information. Email sent from our site will be sent to your non-Gmail account. 
                                                </Typography>
                                           
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    mt={0.5}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <img src={googleLogo} width={25} alt="Google Logo" loading='lazy'/>
                                                        <Typography variant="body1" color="disabled">
                                                            Sign in with Google
                                                        </Typography>
                                                    </Stack>
                                                    <GoogleConnect />
                                                </Grid>
                                            </label>
                                        </Grid>
                                    </div>
                                </Grid> : null}
                                {/* <Grid container py={3} > */}
                                <Grid container mt={2} spacing={2}>

                                    <div className='leftAlign' style={{ marginLeft: '25px' }}>
                                        <Divider classes={{ root: classes.divider }} style={{ marginTop: '10px' }} />


                                        <Grid item xs={12} mt={4}>
                                            <div className='leftAlign' style={{ marginLeft: '10px' }}>
                                                {/* <div className='sec03'> */}
                                                <Typography variant='body2' fontWeight='700'>Terminate Account</Typography>
                                                <Typography variant='body2' >By Terminating your account, you will no longer be able to use our site's services.</Typography>
                                                {/* </div> */}
                                                <br />

                                                {/* <Grid className="termBtn" item container xs={4}  > */}
                                                <Button color="error" size="small" variant="outlined" onClick={() => { setOpenDeactivate(true); }} style={{ color: 'black', backgroundColor: '#f6c94e', borderColor: '#f6c94e ', marginBottom: '20px' }}>Terminate</Button>
                                                {/* </Grid> */}
                                            </div>
                                        </Grid>
                                    </div>

                                </Grid>
                            </Grid>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogContent style={{ backgroundColor: '#fff' }}>
                                    <Typography variant='h4' mb={2} sx={{ color: 'black' }}>Confirm Your Password</Typography>
                                    <Typography variant='body1' sx={{ maxWidth: '50ch' }}>Please enter your current password before making changes to this account field.</Typography>
                                    <PasswordField />
                                </DialogContent>
                                <DialogActions style={{ backgroundColor: '#fff' }}>
                                    <Button onClick={handleClose} style={{ borderRadius: '5px', color: 'black', backgroundColor: '#f6c94e' }}>Cancel</Button>
                                    <Button color='success' onClick={() => handleCheckPassword(values, aftercheckpass)} style={{ borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px solid black' }}>Confirm</Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={gmailOpen} onClose={() => setGmailOpen(false)}>
                                <DialogContent style={{ backgroundColor: '#fff' }}>
                                    <Typography variant="h4" mb={2} sx={{ color: 'black' }}>
                                        Verify your Gmail Address
                                    </Typography>
                                    <Typography variant="body1" sx={{ maxWidth: "50ch" }}>
                                        Please enter your Gmail Address here:
                                    </Typography>
                                    <GmailField />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setGmailOpen(false)} style={{ borderRadius: '5px', color: 'black', backgroundColor: '#f6c94e' }}>Cancel</Button>
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            // console.log("Button clicked! Logging some information...", gmailValues.gmail, userData.email);
                                            GmailVerif(gmailValues.gmail, userData.email);
                                        }}
                                        //autoFocus
                                        style={{ borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px solid black' }}
                                    >
                                        Verify Email
                                    </Button>

                                </DialogActions>
                            </Dialog>

                            <Dialog open={otpOpen} onClose={() => setOtpOpen(false)}>
                                <DialogContent style={{ backgroundColor: '#fff' }}>
                                    <Typography variant="h4" mb={2} sx={{ color: 'black' }}>
                                        Gmail OTP Verification
                                    </Typography>
                                    <Typography variant="body1" sx={{ maxWidth: "50ch" }}>
                                        Please enter the OTP value here:
                                    </Typography>
                                    <OtpField />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOtpOpen(false)} style={{ borderRadius: '5px', color: 'black', backgroundColor: '#f6c94e' }}>Cancel</Button>
                                    <Button
                                        color="success"
                                        onClick={() => OTPVerif(otpValues.otp)}
                                        //autoFocus
                                        style={{ borderRadius: '5px', color: 'black', backgroundColor: 'white', border: '1px solid black' }}
                                    >
                                        Verify Gmail
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={passwordopen}
                                aria-labelledby="resetPassword-dialog-title"
                                aria-describedby="resetPassword-dialog-description"
                                onClose={handlePasswordClose}
                            >
                                <DialogTitle id="resetPassword-dialog-title">
                                    <Typography id="resetPassword-modal-title" variant="h4" component="div" sx={{ color: 'black' }}>
                                        Change Password
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={(e) => {
                                            handlePasswordClose(e);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent style={{ backgroundColor: "white" }}>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            password: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            password: Yup.string().max(255).required('Password is required').matches(
                                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                            )
                                        })}
                                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                            try {
                                                setStatus({ success: false });
                                                setSubmitting(false);

                                                axios.patch(`${url}/user/resetPassword`,
                                                    {
                                                        Password: values.password,
                                                        UserId: userData.userID
                                                    }
                                                ).then(response => {
                                                    //console.log(`Response - ${response.status} - ${response.data}`)

                                                    response.status === 204 ? console.log("Password successfully resetted!") : console.log("Password reset failed!")

                                                    if (response.status === 204) {
                                                        toast.success(`Passwords has been resetted successfully. Please log in again.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });
                                                        handlePasswordClose();
                                                        dispatch(logoutUser());
                                                        history.push('/login');
                                                    }
                                                }).catch((error) => {
                                                    if (error.response) {
                                                       // console.log(error.response.data);
                                                       // console.log(error.response.status);
                                                       // console.log(error.response.headers);
                                                        if (error.response.status === 401 || error.response.status === 501) {
                                                            toast.error(`Invalid parameters! Please check the values and try again.`, {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 4000,
                                                                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                                // style: { 
                                                                // fontSize: '13px',
                                                                // height: '85px',
                                                                // width: '100%',
                                                                // display: 'flex',
                                                                // },      
                                                                // zIndex: 9999 
                                                            });
                                                            // console.log("Invalid parameters!");
                                                        }
                                                        else {
                                                            toast.error(`Opps! An error occurred while resetting the password! Please try again later.`, {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 4000,
                                                                // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                                // style: { 
                                                                // fontSize: '13px',
                                                                // height: '85px',
                                                                // width: '100%',
                                                                // display: 'flex',
                                                                // },      
                                                                // zIndex: 9999 
                                                            });
                                                            // console.log("Random error occured");
                                                        }
                                                    } else if (error.request) {
                                                        toast.error(`Opps! The server appears to be busy at the moment. Please try again later.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });

                                                        // console.log(error.request);
                                                    } else {
                                                        // Something happened in setting up the request that triggered an Error
                                                        toast.error(`Opps! There appears to be something wrong with the server at the moment. Please try again later.`, {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            autoClose: 4000,
                                                            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                                                            // style: { 
                                                            // fontSize: '13px',
                                                            // height: '85px',
                                                            // width: '100%',
                                                            // display: 'flex',
                                                            // },      
                                                            // zIndex: 9999 
                                                        });
                                                    }
                                                });
                                            } catch (err) {
                                                console.error(err);
                                                setStatus({ success: false });
                                                setErrors({ submit: err.message });
                                                setSubmitting(false);
                                            }
                                        }}
                                    >
                                        {({ values, handleChange, touched, errors, handleSubmit, isSubmitting, resetForm }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Grid container spacing={3}>
                                                    <br />
                                                    {<Grid item xs={12}>
                                                        <InputLabel htmlFor="password" sx={{ color: 'black' }}>Password</InputLabel>
                                                        <Input
                                                            fullWidth
                                                            error={Boolean(touched.password && errors.password)}
                                                            id="password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            value={values.password}
                                                            name="password"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                            inputProps={{}}

                                                        />
                                                        {touched.password && errors.password && (
                                                            <FormHelperText error id="helper-text-password-signup">
                                                                {errors.password}
                                                            </FormHelperText>
                                                        )}
                                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item>
                                                                    <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: '7px' }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                                                        {level?.label}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </FormControl>
                                                    </Grid>

                                                    }
                                                    {errors.submit && (
                                                        <Grid item xs={5}>
                                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                                        </Grid>
                                                    )}

                                                    <div>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting}
                                                                size="small"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                // sx={{":hover": {bgcolor: 'white'}, ml:'50%', borderRadius: '5px', color: 'black', backgroundColor: 'white', border:'1px solid black' }}
                                                                sx={{
                                                                    ml: '375px',
                                                                    borderRadius: '5px',
                                                                    color: 'black',
                                                                    backgroundColor: 'white',
                                                                    border: '1px solid black',
                                                                    [theme.breakpoints.down('sm')]: {
                                                                        width: '100%',
                                                                        marginLeft: '40px'
                                                                    },
                                                                    ":hover": {
                                                                        bgcolor: 'white'
                                                                    }
                                                                }}                                            >
                                                                Change Password
                                                            </Button>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={openDeactivate}
                                aria-labelledby="deactivate-dialog-title"
                                fullWidth
                                maxWidth="xs"
                            >
                                <DialogTitle id="deactivate-dialog-title">
                                    <Typography id="modal-modal-title" variant="h4" component="h4" style={{ color: 'black' }}>
                                        Confirm Termination
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { setOpenDeactivate(false) }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='body1' gutterBottom>Are you sure you want to terminate your account?</Typography>
                                        </Grid>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button color='inherit' size='medium' variant='contained' style={{ color: 'Black', backgroundColor: '#f6c94e' }}
                                        disabled={false}
                                        onClick={() => { setOpenDeactivate(false) }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button color='error' size='medium' variant='contained' style={{ color: 'black', backgroundColor: 'white' }}
                                        disabled={false}
                                        onClick={deactivateAccount}
                                    >
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* // </Box > */}

                        </div>
                    </MDBCard>
                </div >
            </section >

        );
    }
    else {
        return (<></>);
    }
}

export default AccountSettings;