// Essentials
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios'; // connect to api
import moment from 'moment'; // format datetime

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { MDBCard } from 'mdb-react-ui-kit';
import { selectCurrentUser } from '../store/user/user.selector';

//UI path
import "../../Purchase.css"

const url = process.env.REACT_APP_API_URL;

const ViewBookingHosted = ({ ...properties }) => {
    const { staticContext, ...props } = properties;
    // Styles
    const outerClasses = classNames('section');
    const classes = makeStyles(() => ({
        root: {
            "& .Mui-disabled .MuiStepIcon-root": { color: 'rgba(255, 255, 255, 0.3)' },
            marginBottom: '70px'
        },
        divider: {
            background: 'white'
        },
    }))();

    const theme = createTheme({
        palette: {
            success: {
                main: '#fff',
                contrastText: '#000',
            },
            secondary: {
                main: '#fff',
                contrastText: '#000',
            },
            yellow: {
                main: '#f6c94e',
                contrastText: '#000',

            },
            red: {
                main: '#ad2518',
                contrastText: '#fff',
            },
        },
    });

    const btnStyle = {
        fontWeight: 'bold',
        fontSize: 14,
        borderRadius: "10px",
        width: '25%',
        height: '35px',
    };

    const history = useHistory();

    const [voucher, setvoucher] = useState(JSON.parse(sessionStorage.getItem('selectedBookViewHost')));
    const [bookings, setThisBooking] = useState([]);

    const getThisBooking = async () => {
        await axios.post(`${url}/user/getThisBookingHost`, { hostedGameClientID: voucher.hostedGameClientID }).then((response) => {
            //   console.log(response)
            //   console.log(JSON.stringify(response.data.data[0][0]))
            setThisBooking(response.data.data[0][0])
        })
    };

    useEffect(() => {
        setvoucher(JSON.parse(sessionStorage.getItem('selectedBookViewHost')));
    }, []);

    useEffect(() => {
        getThisBooking();
    }, []);

    return (
        <section
            {...props}
            className={classNames(outerClasses)}>
            <div className='container-sm'>
                <h1 className='pb-32 center-content'>Booking Details</h1>

                <p className='center-content' style={{ marginTop: '-40px', fontWeight: 'bold', fontSize: '24px', marginBottom: '30px' }}>
                    {bookings.gameName}

                </p> <br />

                <div className='container-xs'>
                    <MDBCard className='checkoutCard'>
                        <div className='checkout-content'>
                            <div className='col-lg-12'>
                                <span style={{ color: 'white' }}>Type: {bookings.gameType}</span><br /><br />

                                {bookings.gameDate ?
                                    <span style={{ color: 'white' }}>Game Date & Time: {moment.utc(bookings.gameDate).format('D MMMM YYYY h:mm A')}</span>
                                    :
                                    <span style={{ color: 'white' }}> </span>
                                }

                                <p style={{ fontSize: '20px', color: 'white' }}><br />Quantity: {bookings.noOfPlayer}</p>

                                {bookings.gameURL ?
                                    (<p style={{ fontSize: '20px', color: 'white' }}>Game Link: {bookings.gameURL}</p>)
                                    : null}
                            </div>
                        </div>
                    </MDBCard>
                </div>
                <ThemeProvider theme={theme}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>

                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button
                            onClick={() => {
                                history.push('/allbooking');
                            }}
                            sx={btnStyle}
                            type='submit'

                            style={{ marginBottom: '15px' }}
                            variant="contained"
                            color='yellow'
                            size='small'>
                            Back
                        </Button>


                    </Box>

                </ThemeProvider>
            </div>
        </section>
    );


}

export default ViewBookingHosted;