import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.min.css';
import Image from '../../components/elements/Image';
import { Box } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const url = process.env.REACT_APP_API_URL;

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, color: 'Black', height: "60px", width: "30px" }}
            onClick={onClick}
            onKeyDown={(e) => e.key === 'Enter' && onClick()}  // Add keyboard interaction
            role="button"  // Make it accessible as a button
            tabIndex={0}    // Make it focusable
        >
            <ArrowForwardIosIcon size="2x" />
        </div>
    );
}


function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, color: 'Black', height: "60px", width: "30px", justifyContent: 'flex-start' }}
            onClick={onClick}
            onKeyDown={(e) => e.key === 'Enter' && onClick()}  // Add keyboard interaction
            role="button"  // Make it accessible as a button
            tabIndex={0}    // Make it focusable
        >
            <ArrowBackIosIcon size="2x" />
        </div>
    );
}


const imgStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const Clients = () => {
    const [clients, setAllClients] = useState([]);
    
    const getAllClients = async () => {
        await axios.post(`${url}/allClients`).then((response) => {
            setAllClients(response.data.data);
        });
    };

    useEffect(() => {
        getAllClients();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        rows: 3,
        slidesPerRow: 1,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        appendDots: (dots) => (
            <ul style={{ margin: "0px" }}> {dots} </ul>  // Simplified dot rendering
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
            <section id="client">
                <div className="container-xl">
                    <div className="contentWrapper">
                        <div className="title">
                            <h3>Enjoyed by customers from various industries!</h3>
                        </div>
                        <div>
                        <Slider {...settings}>
                            {clients.map((client) => (
                                <Box
                                    sx={imgStyle}
                                    key={client.id}
                                    onClick={() => console.log(client.id)}  // Example click handler
                                    onKeyDown={(e) => e.key === 'Enter' && console.log(client.id)}  // Keyboard interaction
                                    role="button"  // Makes it accessible as a button
                                    tabIndex={0}    // Makes it focusable
                                >
                                    <Image src={client.images[0]} />
                                </Box>
                            ))}
                        </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Clients;
