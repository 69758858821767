import React from 'react';
import classNames from 'classnames';

const PageNotFound = () => {

    const outerClasses = classNames(
        ' section center-content ',
      );

    return (
        <section className={outerClasses}>
            <div className='section-inner'>
                <h1>Page Not Found</h1>
                <p>Return back to
                <span className='home-link'>
                    <a href="/"> homepage</a>
                  </span>
                </p>
            </div>
        </section>
    )
}

export default PageNotFound;
