// Essentials
import React from 'react';
import classNames from 'classnames';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//MDB 5
import 'mdb-react-ui-kit/dist/css/mdb.min.css'; // https://mdbootstrap.com/docs/react/

const FAQ = ({ imageFill, ...props }) => {
  // Styles
  const innerClasses = classNames('section');
  const outerClasses = classNames('section center-content');

  return (
    <section {...props} className={classNames(outerClasses)}>
      <div className={classNames(innerClasses, 'container-full')}>
        <div className="container-xl">
          <h2 style={{ fontSize: '35px', fontWeight: 'bold' }}>Frequently Asked Questions</h2>
          <div className="container">
            {/* FAQ Items */}
            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                className="accordionContent"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Do I need an account to make a booking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                  Yes, you will need an account to make a booking for our virtual escape room to receive the game code and necessary details.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Is there a minimum purchase quantity per booking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                  Yes, the minimum quantity required for each booking is 2!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Can I reschedule my booking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                Rescheduling can be done under 'Bookings.' If you face any issues, please contact the admin for assistance.                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel4a-content"
                id="panel4a-header"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  How do I receive my game instructions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                  Once your booking is confirmed, we’ll send the game instructions and login details to your registered email address.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel5a-content"
                id="panel5a-header"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Can participants join from different locations?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                  Absolutely! Our virtual escape rooms are designed to be accessible from anywhere with an internet connection.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion-content" disableGutters={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel6a-content"
                id="panel6a-header"
                style={{
                  backgroundColor: '#151719',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  What happens if I face technical issues during the game?
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#151719', color: 'white' }}>
                <Typography className="accordion-details">
                  Our support team is on standby to assist you with any technical difficulties. You can reach out to us during the game for real-time help.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
