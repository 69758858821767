import React, { useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import classNames from 'classnames';
import axios from 'axios';
import CryptoJS from 'crypto-js';
// npm i crypto-js

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup); // extend yup

const Reset = ({
  topDivider,
  bottomDivider,
  ...props
}) => {

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const outerClasses = classNames(
    'hero section center-content',
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  const location = useLocation()

  function handleSubmit(values) {
    // Retrieve encryptedID from URL parameter
    var params = window.location.href;
    var params_string = params.split('=')
    var encryptedID = params_string[1];
    // Decrypt userID
    var bytes = CryptoJS.AES.decrypt(encryptedID, 'coding is secretive 777');
    var userID = bytes.toString(CryptoJS.enc.Utf8);
    axios.post("/auth/reset", {
      password: values.password,
      userID: userID
    })
      .then((response) => {
        if (response.statusText == 'OK') {
          setSuccess(true);
        }
      })
  };

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const toggleCPassword = (e) => {
    if (showCPassword) {
      setShowCPassword(false);
    } else {
      setShowCPassword(true);
    }
  };

  const [isActive, setIsActive] = useState(true);

  const formValidationSchema = Yup.object().shape({
    password: Yup.string()
      .password()
      .required("Password is required.")
      .min(8, "Password must be more than 8 characters.")
      .max(20, "Password must be less than 20 characters.")
      .minLowercase(1, "Password must contain at least 1 lower case letter.")
      .minUppercase(1, "Password must contain at least 1 upper case letter.")
      .minNumbers(1, "Password must contain at least 1 number.")
      .minSymbols(1, "Password must contain at least 1 special character."),
    cpassword: Yup.string()
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match!"
        )
      })
  })

  return (
    <section
      {...props}
      className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses} style={{ height: "100vh" }}>
          <div className="hero-content">
            <h1 className="mt-5 mb-16 reveal-from-left" data-reveal-delay="200">
              Reset Password
            </h1>
            <div className="auth-wrapper" style={{ marginTop: "-150px", paddingTop: "40px" }}>
              <div className="auth-background-holder"></div>
              <div className="auth-background-mask"></div>

              <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                <div className="d-flex flex-column align-content-end">

                  {success ? (
                    <>
                      <div className="center-content" style={{ marginTop: '125px' }}>
                        <p>Your password has been updated successfully!</p>
                      </div>
                      <div className="text-center">
                        <button
                          className="button button-primary w-100 theme-btn mx-auto"
                          type="submit"
                        >
                          <Link to='/login'>Back to Login</Link>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="auth-rp mx-auto">
                        <p>Reset your Password.</p>
                      </div>
                      <div className="auth-bodyrp mx-auto">
                        <div className="auth-form-container text-start">
                          <Formik
                            initialValues={{
                              password: '',
                              cpassword: '',
                            }}
                            validationSchema={formValidationSchema}
                            onSubmit={handleSubmit}>
                            {({ isSubmitting, values, errors, handleChange, handleBlur }) => (
                              <Form>
                                <div className="input-group">
                                  <label for="password" style={{ display: 'block', marginTop: '10px' }}>
                                    New Password
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    id="password"
                                    placeholder="Password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete={false}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='form-control'
                                    style={{ width: '359px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                  />
                                  <button
                                    type="button"
                                    className="btn1 btn1-outline-primary btn1-sm"
                                    onClick={(e) => togglePassword(e)}
                                    style={{ height: '44px' }}
                                  >
                                    <i
                                      className={
                                        showPassword ? "far fa-eye" : "far fa-eye-slash"
                                      }
                                    ></i>{" "}
                                  </button>
                                  {errors.password && <div className="input-feedback">{errors.password}</div>}
                                </div>
                                <div className="input-group">
                                  <label for="cpassword" style={{ display: 'block', marginTop: '20px' }}>
                                    Confirm New Password
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    id="cpassword"
                                    placeholder="Confirm password"
                                    type={showCPassword ? "text" : "password"}
                                    autoComplete={false}
                                    value={values.cpassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='form-control'
                                    style={{ width: '359px', height: '40px', color: "gray", backgroundColor: isActive ? '#151719' : '#151719', borderColor: 'white' }}
                                  />
                                  <button
                                    type="button"
                                    className="btn1 btn1-outline-primary btn1-sm"
                                    onClick={(e) => toggleCPassword(e)}
                                    style={{ height: '44px' }}
                                  >
                                    <i
                                      className={
                                        showCPassword ? "far fa-eye" : "far fa-eye-slash"
                                      }
                                    ></i>{" "}
                                  </button>
                                  {errors.cpassword && <div className="input-feedback">{errors.cpassword}</div>}
                                </div>

                                <button type="submit"
                                  style={{ marginLeft: '-3px', width: '404px', height: '50px', marginTop: '33px', textAlign: 'center', fontSize: '0.875rem', backgroundColor: '#6163ff', color: 'white' }}
                                  onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
                                  Submit
                                </button>
                              </Form>
                            )}
                          </Formik>

                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reset;