import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

import App from './App';

import './assets/scss/style.scss';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
// project import
import { store, persistor } from './views/store';
import { PersistGate } from "redux-persist/integration/react";

const API_URL = process.env.REACT_APP_API_URL;

// intercepting requests
axios.interceptors.request.use(
  function (config) {
    //console.log('interceptors.request');
    let url = config?.url;

    if (url && url.includes(API_URL)) {
      // For all calls to Escape Room API
      if (localStorage.token) {
        config.headers = {
          Authorization: localStorage.token,
          "X-Api-Key": localStorage.apiKey,
        };
      }
    }
    return config;
  },
  function (error) {
    //console.log("axios.interceptors.request error");
    //console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    //console.log(`Hello World!!!!!- ${JSON.stringify(res)}`);
    return Promise.resolve(res);
  },
  (error) => {
    if (error && error.response?.status == 401) {
      // Check if the request is to the JNR API
      if (error.response?.config?.url && error.response?.config?.url.includes(API_URL)) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
  ,
  document.getElementById('root')
);
