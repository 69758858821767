import { toast } from "react-toastify";

export const handleAuthRes = (statusCode, user) => {

    switch (statusCode) {
        case 200:
            //console.log("Login Submitted");
            if (user) {
                //successful login
                
            } else {
                toast.error(`Invalid credentials.`, {
                  position: toast.POSITION.TOP_CENTER, 
                  autoClose: 4000, 
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
                  style: { 
                  fontSize: '13px',
                  height: '85px',
                  width: '100%',
                  display: 'flex',
                  },      
                  zIndex: 9999 
                });
            }
            break;
        case 403:
            toast.error(`Login unsuccessful - Invalid username/password.`, {
              position: toast.POSITION.TOP_CENTER, 
              autoClose: 4000, 
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
              style: { 
              fontSize: '13px',
              height: '85px',
              width: '100%',
              display: 'flex',
              },      
              zIndex: 9999 
            });
            break;
        case 501:
            toast.error(`Opps! The server appears to be busy at the moment.Please try again later or contact the administrator.`, {
              position: toast.POSITION.TOP_CENTER, 
              autoClose: 4000, 
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
              style: { 
              fontSize: '13px',
              height: '85px',
              width: '100%',
              display: 'flex',
              },      
              zIndex: 9999 
            });
            break;
        case 409:
            toast.error(`Login unsuccessful - Invalid username/password.`, {
              position: toast.POSITION.TOP_CENTER, 
              autoClose: 4000, 
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
              style: { 
              fontSize: '13px',
              height: '85px',
              width: '100%',
              display: 'flex',
              },      
              zIndex: 9999 
            });
            break;
        default:
            //console.log(`Login submit failed - (statusCode: ${statusCode}, user: ${JSON.stringify(user)}, messsage: ${message})`);
            toast.error(`Opps! There appears to be something wrong with the server at the moment.Please try again later or contact the administrator.`, {
              position: toast.POSITION.TOP_CENTER, 
              autoClose: 4000, 
              // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', 
              // style: { 
              // fontSize: '13px',
              // height: '85px',
              // width: '100%',
              // display: 'flex',
              // },      
              // zIndex: 9999 
            });
    }
}